import {useState} from 'react'
import {useIncrementCounterTaskOfStudent} from '../../../../admin/hooks/useTaskAndStudents'
import {truncateText} from '../../../../helpers/Helpers'
import {RightTaskProps} from './_model'

const RightTask: React.FC<RightTaskProps> = ({task, index}) => {
  const [taskStudentId, setTaskStudentId] = useState<number>()
  const {mutate} = useIncrementCounterTaskOfStudent(taskStudentId || 0)
  if (task === undefined) {
    console.log('Undefined right task')
    return <></>
  }
  const handleUserAction = (taskId: number) => {
    setTaskStudentId(taskId)
    const data = {
      id: taskId,
      isCompleted: false,
      counter: 0,
    } //we can't send it empty
    mutate(data) // parameters  required
  }

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 1324 1192'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Frame 10' clip-path='url(#clip0_413_2)'>
        <g id='right-task' filter='url(#filter0_d_413_2)'>
          {index !== undefined && index !== null && index === 1 ? (
            ''
          ) : (
            <g id='rod'>
              <path
                id='Rectangle_7-3'
                d='M643.84 628.556C653.644 637.765 654.036 653.047 644.918 662.844L-1907.04 2162.09C-1916.26 2171.89 -1931.55 2172.28 -1941.35 2163.17C-1951.16 2153.96 -1951.55 2138.68 -1942.43 2128.88L609.526 629.634C618.742 619.935 634.036 619.445 643.84 628.556Z'
                fill='#F6F9FD'
              />
              <g id='Group'>
                <g id='Group_2'>
                  <g id='Group_3'>
                    <path
                      id='Rectangle_7-4_00000073682545896355933430000007296589064854039739_'
                      d='M643.873 628.516C653.677 637.627 654.167 652.909 645.05 662.706L-1901.62 2162.83C-1910.74 2172.63 -1926.03 2173.12 -1935.84 2164.01C-1945.64 2154.9 -1946.13 2139.62 -1937.01 2129.82L609.755 629.692C618.775 619.895 634.069 619.405 643.873 628.516Z'
                      fill='white'
                    />
                  </g>
                </g>
                <g id='Group_4'>
                  <g id='Group_5'>
                    <path
                      id='Rectangle_7-4_00000104690783767583358180000004783354482472393151_'
                      d='M643.873 628.516C653.677 637.627 654.167 652.909 645.05 662.706L-1901.62 2162.83C-1910.74 2172.63 -1926.03 2173.12 -1935.84 2164.01C-1945.64 2154.9 -1946.13 2139.62 -1937.01 2129.82L609.755 629.692C618.775 619.895 634.069 619.405 643.873 628.516Z'
                      fill='#0686EF'
                    />
                  </g>
                </g>
              </g>
              <g id='Group_6'>
                <path
                  id='Rectangle_7-4_00000003794566903202574860000014514811246399556538_'
                  d='M643.873 628.516C653.677 637.627 654.167 652.909 645.05 662.706L-1901.62 2162.83C-1910.74 2172.63 -1926.03 2173.12 -1935.84 2164.01C-1945.64 2154.9 -1946.13 2139.62 -1937.01 2129.82L609.755 629.692C618.775 619.895 634.069 619.405 643.873 628.516Z'
                  stroke='#39A2EA'
                  stroke-width='18'
                  stroke-miterlimit='10'
                />
              </g>
            </g>
          )}
          <g id='corona'>
            {task[6] !== undefined && task[6] !== null && (
              <g id='task-7'>
                <g id='rod-7'>
                  <g id='Rectangle_6-5_00000149372312751957124730000002660774183888176543_'>
                    <g id='Group_7'>
                      <path
                        id='Vector'
                        d='M700.91 683.493C704.21 683.395 706.91 685.942 707.01 689.077L719.01 1058.01C719.11 1061.24 716.51 1063.89 713.31 1063.99C710.01 1064.08 707.31 1061.54 707.21 1058.4L695.21 689.469C695.11 686.236 697.61 683.591 700.91 683.493Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_8'>
                      <path
                        id='Vector_2'
                        d='M700.91 683.493C704.21 683.395 706.91 685.942 707.01 689.077L719.01 1058.01C719.11 1061.24 716.51 1063.89 713.31 1063.99C710.01 1064.08 707.31 1061.54 707.21 1058.4L695.21 689.469C695.11 686.236 697.61 683.591 700.91 683.493Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_9'>
                    <path
                      id='Rectangle_6-6_00000089560956397663813420000007167930862736332962_'
                      d='M700.9 683.454C704.2 683.356 706.9 685.903 707 689.038L719 1057.97C719.1 1061.2 716.5 1063.85 713.3 1063.95C710 1064.05 707.3 1061.5 707.2 1058.36L695.2 689.43C695.1 686.197 697.6 683.552 700.9 683.454Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-7'>
                  <g id='rods'>
                    <g id='Group_10'>
                      <path
                        id='Rectangle_8-13_00000144307166623822569650000013891130592512016041_'
                        d='M724.91 1083.77C727.11 1081.42 730.91 1081.32 733.21 1083.48L782.21 1128.45C784.61 1130.6 784.71 1134.32 782.51 1136.58C780.31 1138.93 776.51 1139.03 774.21 1136.87L725.21 1091.9C722.81 1089.75 722.71 1086.12 724.91 1083.77Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_11'>
                        <path
                          id='Rectangle_8-14_00000020391946674166775150000007413177183998254497_'
                          d='M724.901 1083.73C727.101 1081.38 730.9 1081.29 733.2 1083.44L782.2 1128.41C784.6 1130.56 784.7 1134.28 782.5 1136.54C780.3 1138.89 776.5 1138.99 774.2 1136.83L725.2 1091.87C722.8 1089.71 722.701 1086.09 724.901 1083.73Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_12'>
                      <path
                        id='Rectangle_9-13_00000160869868301438145550000013747331160511946140_'
                        d='M706.11 1084.85C708.51 1087.01 708.71 1090.63 706.51 1092.98L661.41 1141.77C659.21 1144.12 655.51 1144.32 653.11 1142.16C650.71 1140 650.51 1136.38 652.71 1134.03L697.81 1085.24C700.01 1082.89 703.71 1082.7 706.11 1084.85Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_13'>
                        <path
                          id='Rectangle_9-14_00000018206133353661910490000009979063966083586734_'
                          d='M706.101 1084.81C708.501 1086.97 708.7 1090.59 706.5 1092.94L661.4 1141.73C659.2 1144.08 655.501 1144.28 653.101 1142.12C650.701 1139.97 650.5 1136.34 652.7 1133.99L697.8 1085.2C700 1082.85 703.701 1082.66 706.101 1084.81Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1'>
                    <g id='Group_14'>
                      <path
                        id='Ellipse_16-13_00000052069077818238660010000015207926173815326607_'
                        d='M714.71 1093.57C724.872 1093.57 733.11 1085.5 733.11 1075.54C733.11 1065.59 724.872 1057.52 714.71 1057.52C704.548 1057.52 696.311 1065.59 696.311 1075.54C696.311 1085.5 704.548 1093.57 714.71 1093.57Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_15'>
                        <path
                          id='Ellipse_16-14_00000183965145854260422710000003438429863992028327_'
                          d='M714.7 1093.63C724.862 1093.63 733.101 1085.56 733.101 1075.6C733.101 1065.65 724.862 1057.58 714.7 1057.58C704.538 1057.58 696.3 1065.65 696.3 1075.6C696.3 1085.56 704.538 1093.63 714.7 1093.63Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000085211569058746648540000016191372336163674266_'
                      d='M721.311 1060.56L705.911 1061.34C703.511 1061.44 701.411 1059.67 701.311 1057.23C701.211 1054.87 703.011 1052.82 705.511 1052.72L720.911 1051.94C723.311 1051.84 725.411 1053.6 725.511 1056.05C725.511 1058.5 723.711 1060.46 721.311 1060.56Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task'
                      transform='matrix(-0.998849 0.0483379 -0.0503627 -0.998749 720.631 1060.13)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_16' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000059989943646046466180000014903876315733543557_'
                        d='M714.7 1088.05C721.714 1088.05 727.4 1082.47 727.4 1075.6C727.4 1068.73 721.714 1063.16 714.7 1063.16C707.686 1063.16 702 1068.73 702 1075.6C702 1082.47 707.686 1088.05 714.7 1088.05Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2'>
                    <g id='Group_17'>
                      <path
                        id='Ellipse_21-13_00000054226931550923308430000013650793151617574831_'
                        d='M790.91 1161.26C801.072 1161.26 809.31 1153.19 809.31 1143.24C809.31 1133.28 801.072 1125.21 790.91 1125.21C780.748 1125.21 772.51 1133.28 772.51 1143.24C772.51 1153.19 780.748 1161.26 790.91 1161.26Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_18'>
                        <path
                          id='Ellipse_21-14_00000132766508392682360340000004997817973508754840_'
                          d='M790.9 1161.32C801.062 1161.32 809.3 1153.25 809.3 1143.3C809.3 1133.34 801.062 1125.27 790.9 1125.27C780.738 1125.27 772.5 1133.34 772.5 1143.3C772.5 1153.25 780.738 1161.32 790.9 1161.32Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000103945552740490465890000005212439150443820713_'
                      d='M797.811 1130.21L782.411 1130.99C780.011 1131.09 777.911 1129.33 777.811 1126.88C777.711 1124.53 779.511 1122.47 782.011 1122.37L797.411 1121.59C799.811 1121.49 801.911 1123.25 802.011 1125.7C802.111 1128.05 800.211 1130.11 797.811 1130.21Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_2'
                      transform='matrix(-0.998849 0.0483379 -0.0503627 -0.998749 798.48 1130.66)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_19' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000091731548370473449760000000937840386372861065_'
                        d='M790.9 1155.74C797.914 1155.74 803.601 1150.17 803.601 1143.3C803.601 1136.43 797.914 1130.86 790.9 1130.86C783.886 1130.86 778.2 1136.43 778.2 1143.3C778.2 1150.17 783.886 1155.74 790.9 1155.74Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3'>
                    <g id='Group_20'>
                      <path
                        id='Ellipse_18-13_00000000943769053239860300000002447998075345347003_'
                        d='M644.91 1169C655.072 1169 663.31 1160.93 663.31 1150.98C663.31 1141.02 655.072 1132.95 644.91 1132.95C634.748 1132.95 626.51 1141.02 626.51 1150.98C626.51 1160.93 634.748 1169 644.91 1169Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_21'>
                        <path
                          id='Ellipse_18-14_00000038371910625813051500000014571141224857051800_'
                          d='M644.9 1169.06C655.062 1169.06 663.3 1160.99 663.3 1151.04C663.3 1141.08 655.062 1133.01 644.9 1133.01C634.738 1133.01 626.5 1141.08 626.5 1151.04C626.5 1160.99 634.738 1169.06 644.9 1169.06Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000134245437178974713620000000108720207516145087_'
                      d='M652.41 1137.26L637.01 1138.05C634.61 1138.14 632.51 1136.38 632.41 1133.93C632.31 1131.58 634.11 1129.52 636.61 1129.42L652.01 1128.64C654.41 1128.54 656.51 1130.31 656.61 1132.76C656.71 1135.11 654.81 1137.16 652.41 1137.26Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_3'
                      transform='matrix(-0.998849 0.0483379 -0.0503627 -0.998749 653.076 1137.7)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_22' opacity={task[6].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000154400624166338123150000002029686195807132820_'
                        d='M644.9 1163.48C651.914 1163.48 657.601 1157.91 657.601 1151.04C657.601 1144.16 651.914 1138.59 644.9 1138.59C637.886 1138.59 632.2 1144.16 632.2 1151.04C632.2 1157.91 637.886 1163.48 644.9 1163.48Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[6].TasksAndStudents?.id || 0)}>
                  <g id='box-task-7'>
                    <g id='Group_23'>
                      <path
                        id='Vector_3'
                        opacity='0.0196078'
                        d='M683.51 880.89C677.41 881.184 672.71 886.278 673.01 892.254L674.809 928.207C675.109 934.183 680.31 938.787 686.41 938.493L723.109 936.73C729.209 936.436 733.909 931.342 733.609 925.366L731.809 889.413C731.509 883.437 726.309 878.833 720.209 879.127L683.51 880.89Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_4'
                        opacity='0.0392157'
                        d='M683.609 880.89C677.509 881.184 672.809 886.278 673.109 892.254L674.91 928.207C675.21 934.183 680.41 938.787 686.51 938.493L723.209 936.73C729.309 936.436 734.009 931.342 733.709 925.366L731.91 889.413C731.61 883.437 726.409 878.833 720.309 879.127L683.609 880.89Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_5'
                        opacity='0.0588235'
                        d='M683.71 880.988C677.61 881.281 672.91 886.376 673.21 892.351L675.01 928.304C675.31 934.28 680.51 938.884 686.61 938.591L723.31 936.827C729.41 936.533 734.11 931.439 733.81 925.463L732.01 889.511C731.71 883.535 726.51 878.93 720.41 879.224L683.71 880.988Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_6'
                        opacity='0.0784314'
                        d='M683.81 881.085C677.71 881.379 673.01 886.473 673.31 892.449L675.11 928.402C675.41 934.378 680.61 938.982 686.71 938.688L723.411 936.925C729.511 936.631 734.211 931.537 733.911 925.561L732.11 889.608C731.81 883.632 726.611 879.028 720.511 879.322L683.81 881.085Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_7'
                        opacity='0.0980392'
                        d='M683.91 881.185C677.81 881.479 673.11 886.573 673.41 892.549L675.21 928.502C675.51 934.477 680.71 939.082 686.81 938.788L723.51 937.025C729.61 936.731 734.31 931.637 734.01 925.661L732.21 889.708C731.91 883.732 726.71 879.128 720.61 879.422L683.91 881.185Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_8'
                        opacity='0.1176'
                        d='M684.01 881.283C677.91 881.576 673.21 886.671 673.51 892.646L675.309 928.599C675.609 934.575 680.81 939.18 686.91 938.886L723.609 937.122C729.709 936.828 734.409 931.734 734.109 925.758L732.309 889.805C732.009 883.83 726.809 879.225 720.709 879.519L684.01 881.283Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_9'
                        opacity='0.1373'
                        d='M684.109 881.38C678.009 881.674 673.309 886.768 673.609 892.744L675.41 928.697C675.71 934.673 680.91 939.277 687.01 938.983L723.709 937.22C729.809 936.926 734.509 931.832 734.209 925.856L732.41 889.903C732.11 883.927 726.909 879.323 720.809 879.617L684.109 881.38Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_10'
                        opacity='0.1569'
                        d='M684.21 881.478C678.11 881.772 673.41 886.866 673.71 892.842L675.51 928.795C675.81 934.77 681.01 939.375 687.11 939.081L723.81 937.317C729.91 937.024 734.61 931.929 734.31 925.954L732.51 890.001C732.21 884.025 727.01 879.421 720.91 879.714L684.21 881.478Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_11'
                        opacity='0.1765'
                        d='M684.31 881.576C678.21 881.869 673.51 886.964 673.81 892.939L675.61 928.892C675.91 934.868 681.11 939.472 687.21 939.178L723.911 937.415C730.011 937.121 734.711 932.027 734.411 926.051L732.61 890.098C732.31 884.123 727.111 879.518 721.011 879.812L684.31 881.576Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_12'
                        opacity='0.1961'
                        d='M684.41 881.576C678.31 881.869 673.61 886.964 673.91 892.939L675.71 928.892C676.01 934.868 681.21 939.472 687.31 939.178L724.01 937.415C730.11 937.121 734.81 932.027 734.51 926.051L732.71 890.098C732.41 884.123 727.21 879.518 721.11 879.812L684.41 881.576Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_13'
                        opacity='0.2157'
                        d='M684.51 881.673C678.41 881.967 673.71 887.061 674.01 893.037L675.809 928.99C676.109 934.966 681.31 939.57 687.41 939.276L724.109 937.513C730.209 937.219 734.909 932.125 734.609 926.149L732.809 890.196C732.509 884.22 727.309 879.616 721.209 879.91L684.51 881.673Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_14'
                        opacity='0.2353'
                        d='M684.609 881.773C678.509 882.067 673.809 887.161 674.109 893.137L675.91 929.09C676.21 935.065 681.41 939.67 687.51 939.376L724.209 937.612C730.309 937.319 735.009 932.224 734.709 926.249L732.91 890.296C732.61 884.32 727.409 879.716 721.309 880.009L684.609 881.773Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_15'
                        opacity='0.2549'
                        d='M684.71 881.87C678.61 882.164 673.91 887.258 674.21 893.234L676.01 929.187C676.31 935.163 681.51 939.767 687.61 939.473L724.31 937.71C730.41 937.416 735.11 932.322 734.81 926.346L733.01 890.393C732.71 884.418 727.51 879.813 721.41 880.107L684.71 881.87Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_16'
                        opacity='0.2745'
                        d='M684.81 881.968C678.71 882.262 674.01 887.356 674.31 893.332L676.11 929.285C676.41 935.261 681.61 939.865 687.71 939.571L724.411 937.808C730.511 937.514 735.211 932.42 734.911 926.444L733.11 890.491C732.81 884.515 727.611 879.911 721.511 880.205L684.81 881.968Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_17'
                        opacity='0.2941'
                        d='M684.91 882.066C678.81 882.36 674.11 887.454 674.41 893.43L676.21 929.382C676.51 935.358 681.71 939.963 687.81 939.669L724.51 937.905C730.61 937.612 735.31 932.517 735.01 926.541L733.21 890.589C732.91 884.613 727.71 880.008 721.61 880.302L684.91 882.066Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_18'
                        opacity='0.3137'
                        d='M685.01 882.163C678.91 882.457 674.21 887.551 674.51 893.527L676.309 929.48C676.609 935.456 681.81 940.06 687.91 939.766L724.609 938.003C730.709 937.709 735.409 932.615 735.109 926.639L733.309 890.686C733.009 884.71 727.809 880.106 721.709 880.4L685.01 882.163Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_19'
                        opacity='0.3333'
                        d='M685.109 882.261C679.009 882.555 674.309 887.649 674.609 893.625L676.41 929.578C676.71 935.554 681.91 940.158 688.01 939.864L724.709 938.101C730.809 937.807 735.509 932.713 735.209 926.737L733.41 890.784C733.11 884.808 727.909 880.204 721.809 880.498L685.109 882.261Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_20'
                        opacity='0.3529'
                        d='M685.21 882.261C679.11 882.555 674.41 887.649 674.71 893.625L676.51 929.578C676.81 935.554 682.01 940.158 688.11 939.864L724.81 938.101C730.91 937.807 735.61 932.713 735.31 926.737L733.51 890.784C733.21 884.808 728.01 880.204 721.91 880.498L685.21 882.261Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_21'
                        opacity='0.3725'
                        d='M685.31 882.359C679.21 882.653 674.51 887.747 674.81 893.722L676.61 929.675C676.91 935.651 682.11 940.256 688.21 939.962L724.911 938.198C731.011 937.904 735.711 932.81 735.411 926.834L733.61 890.882C733.31 884.906 728.111 880.301 722.011 880.595L685.31 882.359Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_22'
                        opacity='0.3922'
                        d='M685.41 882.458C679.31 882.752 674.61 887.846 674.91 893.822L676.71 929.775C677.01 935.751 682.21 940.355 688.31 940.061L725.01 938.298C731.11 938.004 735.81 932.91 735.51 926.934L733.71 890.981C733.41 885.005 728.21 880.401 722.11 880.695L685.41 882.458Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_23'
                        opacity='0.4118'
                        d='M685.51 882.556C679.41 882.85 674.71 887.944 675.01 893.92L676.809 929.873C677.109 935.849 682.31 940.453 688.41 940.159L725.109 938.396C731.209 938.102 735.909 933.008 735.609 927.032L733.809 891.079C733.509 885.103 728.309 880.499 722.209 880.793L685.51 882.556Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_24'
                        opacity='0.4314'
                        d='M685.51 882.654C679.41 882.948 674.71 888.042 675.01 894.018L676.809 929.97C677.109 935.946 682.31 940.55 688.41 940.257L725.109 938.493C731.209 938.199 735.909 933.105 735.609 927.129L733.809 891.177C733.509 885.201 728.309 880.596 722.209 880.89L685.51 882.654Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_25'
                        opacity='0.451'
                        d='M685.609 882.751C679.509 883.045 674.809 888.139 675.109 894.115L676.91 930.068C677.21 936.044 682.41 940.648 688.51 940.354L725.209 938.591C731.309 938.297 736.009 933.203 735.709 927.227L733.91 891.274C733.61 885.298 728.409 880.694 722.309 880.988L685.609 882.751Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_26'
                        opacity='0.4706'
                        d='M685.71 882.849C679.61 883.143 674.91 888.237 675.21 894.213L677.01 930.166C677.31 936.141 682.51 940.746 688.61 940.452L725.31 938.689C731.41 938.395 736.11 933.301 735.81 927.325L734.01 891.372C733.71 885.396 728.51 880.792 722.41 881.086L685.71 882.849Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_27'
                        opacity='0.4902'
                        d='M685.81 882.947C679.71 883.24 675.01 888.335 675.31 894.31L677.11 930.263C677.41 936.239 682.61 940.843 688.71 940.55L725.411 938.786C731.511 938.492 736.211 933.398 735.911 927.422L734.11 891.469C733.81 885.494 728.611 880.889 722.511 881.183L685.81 882.947Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_28'
                        opacity='0.5098'
                        d='M685.91 882.947C679.81 883.24 675.11 888.335 675.41 894.31L677.21 930.263C677.51 936.239 682.71 940.843 688.81 940.55L725.51 938.786C731.61 938.492 736.31 933.398 736.01 927.422L734.21 891.469C733.91 885.494 728.71 880.889 722.61 881.183L685.91 882.947Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_29'
                        opacity='0.5294'
                        d='M686.01 883.046C679.91 883.34 675.21 888.434 675.51 894.41L677.309 930.363C677.609 936.339 682.81 940.943 688.91 940.649L725.609 938.886C731.709 938.592 736.409 933.498 736.109 927.522L734.309 891.569C734.009 885.593 728.809 880.989 722.709 881.283L686.01 883.046Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_30'
                        opacity='0.549'
                        d='M686.109 883.144C680.009 883.438 675.309 888.532 675.609 894.508L677.41 930.461C677.71 936.436 682.91 941.041 689.01 940.747L725.709 938.984C731.809 938.69 736.509 933.596 736.209 927.62L734.41 891.667C734.11 885.691 728.909 881.087 722.809 881.381L686.109 883.144Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_31'
                        opacity='0.5686'
                        d='M686.21 883.242C680.11 883.535 675.41 888.63 675.71 894.605L677.51 930.558C677.81 936.534 683.01 941.139 689.11 940.845L725.81 939.081C731.91 938.787 736.61 933.693 736.31 927.717L734.51 891.764C734.21 885.789 729.01 881.184 722.91 881.478L686.21 883.242Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_32'
                        opacity='0.5882'
                        d='M686.31 883.339C680.21 883.633 675.51 888.727 675.81 894.703L677.61 930.656C677.91 936.632 683.11 941.236 689.21 940.942L725.911 939.179C732.011 938.885 736.711 933.791 736.411 927.815L734.61 891.862C734.31 885.886 729.111 881.282 723.011 881.576L686.31 883.339Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_33'
                        opacity='0.6078'
                        d='M686.41 883.437C680.31 883.731 675.61 888.825 675.91 894.801L677.71 930.754C678.01 936.729 683.21 941.334 689.31 941.04L726.01 939.276C732.11 938.983 736.81 933.888 736.51 927.913L734.71 891.96C734.41 885.984 729.21 881.38 723.11 881.673L686.41 883.437Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_34'
                        opacity='0.6275'
                        d='M686.51 883.535C680.41 883.828 675.71 888.923 676.01 894.898L677.809 930.851C678.109 936.827 683.31 941.431 689.41 941.137L726.109 939.374C732.209 939.08 736.909 933.986 736.609 928.01L734.809 892.057C734.509 886.082 729.309 881.477 723.209 881.771L686.51 883.535Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_35'
                        opacity='0.6471'
                        d='M686.609 883.632C680.509 883.926 675.809 889.02 676.109 894.996L677.91 930.949C678.21 936.925 683.41 941.529 689.51 941.235L726.209 939.472C732.309 939.178 737.009 934.084 736.709 928.108L734.91 892.155C734.61 886.179 729.409 881.575 723.309 881.869L686.609 883.632Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_36'
                        opacity='0.6667'
                        d='M686.71 883.732C680.61 884.026 675.91 889.12 676.21 895.096L678.01 931.049C678.31 937.024 683.51 941.629 689.61 941.335L726.31 939.571C732.41 939.278 737.11 934.183 736.81 928.208L735.01 892.255C734.71 886.279 729.51 881.675 723.41 881.968L686.71 883.732Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_37'
                        opacity='0.6863'
                        d='M686.81 883.732C680.71 884.026 676.01 889.12 676.31 895.096L678.11 931.049C678.41 937.024 683.61 941.629 689.71 941.335L726.411 939.571C732.511 939.278 737.211 934.183 736.911 928.208L735.11 892.255C734.81 886.279 729.611 881.675 723.511 881.968L686.81 883.732Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_38'
                        opacity='0.7059'
                        d='M686.91 883.829C680.81 884.123 676.11 889.217 676.41 895.193L678.21 931.146C678.51 937.122 683.71 941.726 689.81 941.432L726.51 939.669C732.61 939.375 737.31 934.281 737.01 928.305L735.21 892.352C734.91 886.376 729.71 881.772 723.61 882.066L686.91 883.829Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_39'
                        opacity='0.7255'
                        d='M687.01 883.927C680.91 884.221 676.21 889.315 676.51 895.291L678.309 931.244C678.609 937.22 683.81 941.824 689.91 941.53L726.609 939.767C732.709 939.473 737.409 934.379 737.109 928.403L735.309 892.45C735.009 886.474 729.809 881.87 723.709 882.164L687.01 883.927Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_40'
                        opacity='0.7451'
                        d='M687.109 884.025C681.009 884.319 676.309 889.413 676.609 895.389L678.41 931.341C678.71 937.317 683.91 941.922 690.01 941.628L726.709 939.864C732.809 939.571 737.509 934.476 737.209 928.5L735.41 892.548C735.11 886.572 729.909 881.967 723.809 882.261L687.109 884.025Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_41'
                        opacity='0.7647'
                        d='M687.21 884.122C681.11 884.416 676.41 889.51 676.71 895.486L678.51 931.439C678.81 937.415 684.01 942.019 690.11 941.725L726.81 939.962C732.91 939.668 737.61 934.574 737.31 928.598L735.51 892.645C735.21 886.669 730.01 882.065 723.91 882.359L687.21 884.122Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_42'
                        opacity='0.7843'
                        d='M687.31 884.22C681.21 884.514 676.51 889.608 676.81 895.584L678.61 931.537C678.91 937.513 684.11 942.117 690.21 941.823L726.911 940.06C733.011 939.766 737.711 934.672 737.411 928.696L735.61 892.743C735.31 886.767 730.111 882.163 724.011 882.457L687.31 884.22Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_43'
                        opacity='0.8039'
                        d='M687.41 884.318C681.31 884.612 676.61 889.706 676.91 895.681L678.71 931.634C679.01 937.61 684.21 942.215 690.31 941.921L727.01 940.157C733.11 939.863 737.81 934.769 737.51 928.793L735.71 892.841C735.41 886.865 730.21 882.26 724.11 882.554L687.41 884.318Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_44'
                        opacity='0.8235'
                        d='M687.51 884.417C681.41 884.711 676.71 889.805 677.01 895.781L678.809 931.734C679.109 937.71 684.31 942.314 690.41 942.02L727.109 940.257C733.209 939.963 737.909 934.869 737.609 928.893L735.809 892.94C735.509 886.964 730.309 882.36 724.209 882.654L687.51 884.417Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_45'
                        opacity='0.8431'
                        d='M687.609 884.417C681.509 884.711 676.809 889.805 677.109 895.781L678.91 931.734C679.21 937.71 684.41 942.314 690.51 942.02L727.209 940.257C733.309 939.963 738.009 934.869 737.709 928.893L735.91 892.94C735.61 886.964 730.409 882.36 724.309 882.654L687.609 884.417Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_46'
                        opacity='0.8627'
                        d='M687.71 884.515C681.61 884.809 676.91 889.903 677.21 895.879L679.01 931.832C679.31 937.808 684.51 942.412 690.61 942.118L727.31 940.355C733.41 940.061 738.11 934.967 737.81 928.991L736.01 893.038C735.71 887.062 730.51 882.458 724.41 882.752L687.71 884.515Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_47'
                        opacity='0.8824'
                        d='M687.81 884.613C681.71 884.907 677.01 890.001 677.31 895.976L679.11 931.929C679.41 937.905 684.61 942.509 690.71 942.216L727.411 940.452C733.511 940.158 738.211 935.064 737.911 929.088L736.11 893.136C735.81 887.16 730.611 882.555 724.511 882.849L687.81 884.613Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_48'
                        opacity='0.902'
                        d='M687.91 884.71C681.81 885.004 677.11 890.098 677.41 896.074L679.21 932.027C679.51 938.003 684.71 942.607 690.81 942.313L727.51 940.55C733.61 940.256 738.31 935.162 738.01 929.186L736.21 893.233C735.91 887.257 730.71 882.653 724.61 882.947L687.91 884.71Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_49'
                        opacity='0.9216'
                        d='M688.01 884.81C681.91 885.104 677.21 890.198 677.51 896.174L679.309 932.127C679.609 938.102 684.81 942.707 690.91 942.413L727.609 940.649C733.709 940.356 738.409 935.261 738.109 929.286L736.309 893.333C736.009 887.357 730.809 882.753 724.709 883.047L688.01 884.81Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_50'
                        opacity='0.9412'
                        d='M688.109 884.906C682.009 885.199 677.309 890.294 677.609 896.269L679.41 932.222C679.71 938.198 684.91 942.802 691.01 942.509L727.709 940.745C733.809 940.451 738.509 935.357 738.209 929.381L736.41 893.428C736.11 887.453 730.909 882.848 724.809 883.142L688.109 884.906Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_51'
                        opacity='0.9608'
                        d='M688.21 885.005C682.11 885.299 677.41 890.393 677.71 896.369L679.51 932.322C679.81 938.298 685.01 942.902 691.11 942.608L727.81 940.845C733.91 940.551 738.61 935.457 738.31 929.481L736.51 893.528C736.21 887.552 731.01 882.948 724.91 883.242L688.21 885.005Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_52'
                        opacity='0.9804'
                        d='M688.31 885.103C682.21 885.397 677.51 890.491 677.81 896.467L679.61 932.42C679.91 938.395 685.11 943 691.21 942.706L727.911 940.943C734.011 940.649 738.711 935.555 738.411 929.579L736.61 893.626C736.31 887.65 731.111 883.046 725.011 883.339L688.31 885.103Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_53'
                        d='M688.41 885.103C682.31 885.397 677.61 890.491 677.91 896.467L679.71 932.42C680.01 938.395 685.21 943 691.31 942.706L728.01 940.943C734.11 940.649 738.81 935.555 738.51 929.579L736.71 893.626C736.41 887.65 731.21 883.046 725.11 883.339L688.41 885.103Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_54'
                      d='M689.31 886.179C683.31 886.473 678.71 891.469 679.01 897.347L680.81 932.614C681.11 938.492 686.21 942.999 692.21 942.705L728.21 940.941C734.21 940.648 738.81 935.651 738.51 929.773L736.71 894.506C736.41 888.628 731.31 884.122 725.31 884.416L689.31 886.179Z'
                      fill='url(#paint0_linear_413_2)'
                    />
                    <path
                      id='Vector_55'
                      d='M689.311 886.67C683.611 886.964 679.111 891.764 679.411 897.348L681.21 932.615C681.51 938.199 686.41 942.608 692.11 942.314L728.11 940.55C733.81 940.256 738.311 935.456 738.011 929.872L736.21 894.605C735.91 889.021 731.011 884.613 725.311 884.906L689.311 886.67Z'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <path
                      id='Vector_56'
                      d='M724.11 928.795L698.41 930.069C694.81 930.264 691.711 927.521 691.511 923.995L691.41 921.937C691.21 918.411 694.01 915.374 697.61 915.178L723.31 913.904C726.91 913.708 730.01 916.451 730.21 919.978L730.31 922.035C730.51 925.66 727.71 928.697 724.11 928.795Z'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='Task 7'
                      transform='matrix(0.998849 -0.0483379 0.0503627 0.998749 687.123 893.203)'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3614'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[6].name && task[6].name.length > 3
                          ? truncateText(task[6].name, 4)
                          : task[6].name}
                      </tspan>
                    </text>
                    <text
                      id='3/7'
                      transform='matrix(0.998849 -0.0483379 0.0503627 0.998749 701.08 915.529)'
                      fill={task[6].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3287'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[6].counter + '/3'} */}
                        {(task[6]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[6]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>{' '}
                </a>
              </g>
            )}
            {task[5] !== undefined && task[5] !== null && (
              <g id='task-6'>
                <g id='rod-6'>
                  <g id='Rectangle_6-5_00000005975045613923951030000009515652367574322110_'>
                    <g id='Group_24'>
                      <path
                        id='Vector_57'
                        d='M764.91 654.199C762.71 656.55 762.91 660.273 765.41 662.33L1046.31 908.318C1048.71 910.474 1052.51 910.278 1054.61 907.829C1056.81 905.477 1056.61 901.755 1054.11 899.698L773.21 653.709C770.81 651.652 767.11 651.848 764.91 654.199Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_25'>
                      <path
                        id='Vector_58'
                        d='M764.91 654.199C762.71 656.55 762.91 660.273 765.41 662.33L1046.31 908.318C1048.71 910.474 1052.51 910.278 1054.61 907.829C1056.81 905.477 1056.61 901.755 1054.11 899.698L773.21 653.709C770.81 651.652 767.11 651.848 764.91 654.199Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_26'>
                    <path
                      id='Rectangle_6-6_00000059991298030256040690000003850583296877419925_'
                      d='M764.9 654.258C762.7 656.61 762.9 660.332 765.4 662.39L1046.3 908.378C1048.7 910.533 1052.5 910.337 1054.6 907.888C1056.8 905.537 1056.6 901.814 1054.1 899.757L773.2 653.769C770.8 651.613 767.1 651.809 764.9 654.258Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-6'>
                  <g id='rods_2'>
                    <g id='Group_27'>
                      <path
                        id='Rectangle_8-13_00000033345502686560418160000006030510286501576636_'
                        d='M1062.71 929.38C1059.41 929.576 1057.01 932.319 1057.11 935.454L1060.91 1001.09C1061.11 1004.32 1063.91 1006.67 1067.11 1006.58C1070.41 1006.38 1072.81 1003.64 1072.71 1000.5L1068.91 934.866C1068.71 931.633 1065.91 929.184 1062.71 929.38Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_28'>
                        <path
                          id='Rectangle_8-14_00000048491457800328751530000002899783553403471760_'
                          d='M1062.7 929.341C1059.4 929.537 1057 932.28 1057.1 935.415L1060.9 1001.05C1061.1 1004.28 1063.9 1006.63 1067.1 1006.54C1070.4 1006.34 1072.8 1003.6 1072.7 1000.46L1068.9 934.827C1068.7 931.594 1065.9 929.145 1062.7 929.341Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_29'>
                      <path
                        id='Rectangle_9-13_00000012469396796324147870000001038675403141015957_'
                        d='M1075.51 915.959C1075.61 919.192 1078.41 921.641 1081.61 921.543L1148.71 918.996C1152.01 918.898 1154.51 916.155 1154.41 913.02C1154.31 909.788 1151.51 907.338 1148.31 907.436L1081.21 909.984C1078.01 910.082 1075.41 912.727 1075.51 915.959Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_30'>
                        <path
                          id='Rectangle_9-14_00000147199969278158028670000009202047161890944652_'
                          d='M1075.6 915.92C1075.7 919.153 1078.5 921.602 1081.7 921.504L1148.8 918.957C1152.1 918.859 1154.6 916.116 1154.5 912.981C1154.4 909.749 1151.6 907.299 1148.4 907.397L1081.3 909.944C1078 910.042 1075.4 912.785 1075.6 915.92Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_2'>
                    <g id='Group_31'>
                      <path
                        id='Ellipse_16-13_00000130627074617833897200000015729483793596877716_'
                        d='M1062.81 934.477C1072.97 934.477 1081.21 926.406 1081.21 916.451C1081.21 906.496 1072.97 898.426 1062.81 898.426C1052.65 898.426 1044.41 906.496 1044.41 916.451C1044.41 926.406 1052.65 934.477 1062.81 934.477Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_32'>
                        <path
                          id='Ellipse_16-14_00000112621020987835659620000009928892741436087741_'
                          d='M1062.8 934.438C1072.96 934.438 1081.2 926.367 1081.2 916.412C1081.2 906.457 1072.96 898.387 1062.8 898.387C1052.64 898.387 1044.4 906.457 1044.4 916.412C1044.4 926.367 1052.64 934.438 1062.8 934.438Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000149340791547735598460000004942700630941112245_'
                      d='M1046.91 911.749L1057.41 900.679C1059.01 898.916 1058.91 896.27 1057.21 894.605C1055.41 893.038 1052.71 893.136 1051.01 894.801L1040.51 905.871C1038.91 907.634 1039.01 910.279 1040.71 911.945C1042.41 913.61 1045.21 913.512 1046.91 911.749Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_4'
                      transform='matrix(-0.69651 0.717581 -0.731735 -0.681625 1057.03 900.449)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_33' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000155859744778610012460000017021296186265981343_'
                        d='M1062.9 928.854C1069.91 928.854 1075.6 923.283 1075.6 916.412C1075.6 909.541 1069.91 903.971 1062.9 903.971C1055.89 903.971 1050.2 909.541 1050.2 916.412C1050.2 923.283 1055.89 928.854 1062.9 928.854Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_2'>
                    <g id='Group_34'>
                      <path
                        id='Ellipse_18-13_00000161606657803875369570000010343706483161929106_'
                        d='M1166.61 930.557C1176.77 930.557 1185.01 922.486 1185.01 912.531C1185.01 902.576 1176.77 894.506 1166.61 894.506C1156.45 894.506 1148.21 902.576 1148.21 912.531C1148.21 922.486 1156.45 930.557 1166.61 930.557Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_35'>
                        <path
                          id='Ellipse_18-14_00000009581165052127369690000002753411697671592072_'
                          d='M1166.6 930.518C1176.76 930.518 1185 922.447 1185 912.492C1185 902.537 1176.76 894.467 1166.6 894.467C1156.44 894.467 1148.2 902.537 1148.2 912.492C1148.2 922.447 1156.44 930.518 1166.6 930.518Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000175284653996104263900000015078134669170008233_'
                      d='M1151.11 909.3L1161.61 898.23C1163.21 896.466 1163.11 893.821 1161.41 892.156C1159.61 890.588 1156.91 890.686 1155.21 892.352L1144.71 903.422C1143.11 905.185 1143.21 907.83 1144.91 909.496C1146.71 911.161 1149.41 910.965 1151.11 909.3Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_5'
                      transform='matrix(-0.69651 0.717581 -0.731735 -0.681625 1162.03 898.436)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3487'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07205'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_36' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000106128873462820997260000000304067414830838665_'
                        d='M1166.6 924.934C1173.61 924.934 1179.3 919.364 1179.3 912.492C1179.3 905.621 1173.61 900.051 1166.6 900.051C1159.59 900.051 1153.9 905.621 1153.9 912.492C1153.9 919.364 1159.59 924.934 1166.6 924.934Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_2'>
                    <g id='Group_37'>
                      <path
                        id='Ellipse_21-13_00000110441018914618568230000010324936605532568726_'
                        d='M1066.81 1035.18C1076.97 1035.18 1085.21 1027.11 1085.21 1017.16C1085.21 1007.2 1076.97 999.131 1066.81 999.131C1056.65 999.131 1048.41 1007.2 1048.41 1017.16C1048.41 1027.11 1056.65 1035.18 1066.81 1035.18Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_38'>
                        <path
                          id='Ellipse_21-14_00000157308267148562814150000010381788602148644267_'
                          d='M1066.8 1035.14C1076.96 1035.14 1085.2 1027.07 1085.2 1017.12C1085.2 1007.16 1076.96 999.092 1066.8 999.092C1056.64 999.092 1048.4 1007.16 1048.4 1017.12C1048.4 1027.07 1056.64 1035.14 1066.8 1035.14Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000026128472793834568560000002334025554247056555_'
                      d='M1052.21 1013.92L1062.71 1002.85C1064.31 1001.09 1064.21 998.446 1062.51 996.781C1060.71 995.214 1058.01 995.311 1056.31 996.977L1045.81 1008.05C1044.21 1009.81 1044.31 1012.46 1046.01 1014.12C1047.81 1015.79 1050.51 1015.69 1052.21 1013.92Z'
                      fill='#E1E1E1'
                    />
                    <g id='Task_6' filter='url(#filter1_d_413_2)'>
                      <text
                        transform='matrix(-0.69651 0.717581 -0.731735 -0.681625 1062.98 1002.96)'
                        fill='#272727'
                        xmlSpace='preserve'
                        style={{whiteSpace: 'pre'}}
                        font-family='Poppins'
                        font-size='7.3487'
                        letter-spacing='0em'
                      >
                        <tspan x='0' y='8.07205'>
                          Task
                        </tspan>
                      </text>
                    </g>
                    <g id='Group_39' opacity={task[5].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000029743617507956174940000017595045993257601668_'
                        d='M1066.8 1029.56C1073.81 1029.56 1079.5 1023.99 1079.5 1017.12C1079.5 1010.25 1073.81 1004.68 1066.8 1004.68C1059.79 1004.68 1054.1 1010.25 1054.1 1017.12C1054.1 1023.99 1059.79 1029.56 1066.8 1029.56Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[5].TasksAndStudents?.id || 0)}>
                  <g id='box-task-6'>
                    <g id='Group_40'>
                      <path
                        id='Vector_59'
                        opacity='0.0196078'
                        d='M913.924 779.804C907.824 780.098 903.124 785.192 903.424 791.168L905.224 827.121C905.524 833.097 910.724 837.701 916.824 837.407L953.523 835.644C959.623 835.35 964.323 830.256 964.023 824.28L962.224 788.327C961.924 782.351 956.723 777.747 950.623 778.041L913.924 779.804Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_60'
                        opacity='0.0392157'
                        d='M914.023 779.804C907.923 780.098 903.223 785.192 903.523 791.168L905.324 827.121C905.624 833.097 910.824 837.701 916.924 837.407L953.623 835.644C959.723 835.35 964.423 830.256 964.123 824.28L962.324 788.327C962.024 782.351 956.824 777.747 950.724 778.041L914.023 779.804Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_61'
                        opacity='0.0588235'
                        d='M914.124 779.902C908.024 780.196 903.324 785.29 903.624 791.266L905.424 827.218C905.724 833.194 910.924 837.799 917.024 837.505L953.724 835.741C959.824 835.447 964.524 830.353 964.224 824.377L962.424 788.425C962.124 782.449 956.924 777.844 950.824 778.138L914.124 779.902Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_62'
                        opacity='0.0784314'
                        d='M914.225 779.999C908.125 780.293 903.425 785.387 903.725 791.363L905.524 827.316C905.824 833.292 911.024 837.896 917.124 837.602L953.825 835.839C959.925 835.545 964.625 830.451 964.325 824.475L962.524 788.522C962.224 782.546 957.025 777.942 950.925 778.236L914.225 779.999Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_63'
                        opacity='0.0980392'
                        d='M914.324 780.099C908.224 780.393 903.524 785.487 903.824 791.463L905.624 827.416C905.924 833.391 911.124 837.996 917.224 837.702L953.924 835.939C960.024 835.645 964.724 830.551 964.424 824.575L962.624 788.622C962.324 782.646 957.124 778.042 951.024 778.336L914.324 780.099Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_64'
                        opacity='0.1176'
                        d='M914.424 780.197C908.324 780.491 903.624 785.585 903.924 791.56L905.724 827.513C906.024 833.489 911.224 838.094 917.324 837.8L954.023 836.036C960.123 835.742 964.823 830.648 964.523 824.672L962.724 788.719C962.424 782.744 957.223 778.139 951.123 778.433L914.424 780.197Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_65'
                        opacity='0.1373'
                        d='M914.523 780.294C908.423 780.588 903.723 785.682 904.023 791.658L905.824 827.611C906.124 833.587 911.324 838.191 917.424 837.897L954.123 836.134C960.223 835.84 964.923 830.746 964.623 824.77L962.824 788.817C962.524 782.841 957.324 778.237 951.224 778.531L914.523 780.294Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_66'
                        opacity='0.1569'
                        d='M914.624 780.392C908.524 780.686 903.824 785.78 904.124 791.756L905.924 827.709C906.224 833.684 911.424 838.289 917.524 837.995L954.224 836.231C960.324 835.938 965.024 830.843 964.724 824.868L962.924 788.915C962.624 782.939 957.424 778.335 951.324 778.629L914.624 780.392Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_67'
                        opacity='0.1765'
                        d='M914.725 780.49C908.625 780.784 903.925 785.878 904.225 791.853L906.024 827.806C906.324 833.782 911.524 838.386 917.624 838.093L954.325 836.329C960.425 836.035 965.125 830.941 964.825 824.965L963.024 789.012C962.724 783.037 957.525 778.432 951.425 778.726L914.725 780.49Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_68'
                        opacity='0.1961'
                        d='M914.824 780.49C908.724 780.784 904.024 785.878 904.324 791.853L906.124 827.806C906.424 833.782 911.624 838.386 917.724 838.093L954.424 836.329C960.524 836.035 965.224 830.941 964.924 824.965L963.124 789.012C962.824 783.037 957.624 778.432 951.524 778.726L914.824 780.49Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_69'
                        opacity='0.2157'
                        d='M914.924 780.587C908.824 780.881 904.124 785.975 904.424 791.951L906.224 827.904C906.524 833.88 911.724 838.484 917.824 838.19L954.523 836.427C960.623 836.133 965.323 831.039 965.023 825.063L963.224 789.11C962.924 783.134 957.723 778.53 951.623 778.824L914.924 780.587Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_70'
                        opacity='0.2353'
                        d='M915.023 780.687C908.923 780.981 904.223 786.075 904.523 792.051L906.324 828.004C906.624 833.98 911.824 838.584 917.924 838.29L954.623 836.527C960.723 836.233 965.423 831.139 965.123 825.163L963.324 789.21C963.024 783.234 957.824 778.63 951.724 778.923L915.023 780.687Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_71'
                        opacity='0.2549'
                        d='M915.124 780.784C909.024 781.078 904.324 786.172 904.624 792.148L906.424 828.101C906.724 834.077 911.924 838.681 918.024 838.387L954.724 836.624C960.824 836.33 965.524 831.236 965.224 825.26L963.424 789.307C963.124 783.332 957.924 778.727 951.824 779.021L915.124 780.784Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_72'
                        opacity='0.2745'
                        d='M915.225 780.882C909.125 781.176 904.425 786.27 904.725 792.246L906.524 828.199C906.824 834.175 912.024 838.779 918.124 838.485L954.825 836.722C960.925 836.428 965.625 831.334 965.325 825.358L963.524 789.405C963.224 783.429 958.025 778.825 951.925 779.119L915.225 780.882Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_73'
                        opacity='0.2941'
                        d='M915.324 780.98C909.224 781.274 904.524 786.368 904.824 792.344L906.624 828.297C906.924 834.272 912.124 838.877 918.224 838.583L954.924 836.819C961.024 836.526 965.724 831.431 965.424 825.456L963.624 789.503C963.324 783.527 958.124 778.923 952.024 779.216L915.324 780.98Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_74'
                        opacity='0.3137'
                        d='M915.424 781.078C909.324 781.371 904.624 786.466 904.924 792.441L906.724 828.394C907.024 834.37 912.224 838.974 918.324 838.68L955.023 836.917C961.123 836.623 965.823 831.529 965.523 825.553L963.724 789.6C963.424 783.625 958.223 779.02 952.123 779.314L915.424 781.078Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_75'
                        opacity='0.3333'
                        d='M915.523 781.175C909.423 781.469 904.723 786.563 905.023 792.539L906.824 828.492C907.124 834.468 912.324 839.072 918.424 838.778L955.123 837.015C961.223 836.721 965.923 831.627 965.623 825.651L963.824 789.698C963.524 783.722 958.324 779.118 952.224 779.412L915.523 781.175Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_76'
                        opacity='0.3529'
                        d='M915.624 781.175C909.524 781.469 904.824 786.563 905.124 792.539L906.924 828.492C907.224 834.468 912.424 839.072 918.524 838.778L955.224 837.015C961.324 836.721 966.024 831.627 965.724 825.651L963.924 789.698C963.624 783.722 958.424 779.118 952.324 779.412L915.624 781.175Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_77'
                        opacity='0.3725'
                        d='M915.725 781.273C909.625 781.567 904.925 786.661 905.225 792.637L907.024 828.589C907.324 834.565 912.524 839.17 918.624 838.876L955.325 837.112C961.425 836.818 966.125 831.724 965.825 825.749L964.024 789.796C963.724 783.82 958.525 779.216 952.425 779.509L915.725 781.273Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_78'
                        opacity='0.3922'
                        d='M915.824 781.372C909.724 781.666 905.024 786.76 905.324 792.736L907.124 828.689C907.424 834.665 912.624 839.269 918.724 838.975L955.424 837.212C961.524 836.918 966.224 831.824 965.924 825.848L964.124 789.895C963.824 783.919 958.624 779.315 952.524 779.609L915.824 781.372Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_79'
                        opacity='0.4118'
                        d='M915.924 781.47C909.824 781.764 905.124 786.858 905.424 792.834L907.224 828.787C907.524 834.763 912.724 839.367 918.824 839.073L955.523 837.31C961.623 837.016 966.323 831.922 966.023 825.946L964.224 789.993C963.924 784.017 958.723 779.413 952.623 779.707L915.924 781.47Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_80'
                        opacity='0.4314'
                        d='M915.924 781.568C909.824 781.862 905.124 786.956 905.424 792.932L907.224 828.884C907.524 834.86 912.724 839.465 918.824 839.171L955.523 837.407C961.623 837.113 966.323 832.019 966.023 826.044L964.224 790.091C963.924 784.115 958.723 779.51 952.623 779.804L915.924 781.568Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_81'
                        opacity='0.451'
                        d='M916.023 781.665C909.923 781.959 905.223 787.053 905.523 793.029L907.324 828.982C907.624 834.958 912.824 839.562 918.924 839.268L955.623 837.505C961.723 837.211 966.423 832.117 966.123 826.141L964.324 790.188C964.024 784.212 958.824 779.608 952.724 779.902L916.023 781.665Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_82'
                        opacity='0.4706'
                        d='M916.124 781.763C910.024 782.057 905.324 787.151 905.624 793.127L907.424 829.08C907.724 835.056 912.924 839.66 919.024 839.366L955.724 837.603C961.824 837.309 966.524 832.215 966.224 826.239L964.424 790.286C964.124 784.31 958.924 779.706 952.824 780L916.124 781.763Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_83'
                        opacity='0.4902'
                        d='M916.225 781.861C910.125 782.154 905.425 787.249 905.725 793.225L907.524 829.177C907.824 835.153 913.024 839.758 919.124 839.464L955.825 837.7C961.925 837.406 966.625 832.312 966.325 826.336L964.524 790.384C964.224 784.408 959.025 779.803 952.925 780.097L916.225 781.861Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_84'
                        opacity='0.5098'
                        d='M916.324 781.861C910.224 782.154 905.524 787.249 905.824 793.225L907.624 829.177C907.924 835.153 913.124 839.758 919.224 839.464L955.924 837.7C962.024 837.406 966.724 832.312 966.424 826.336L964.624 790.384C964.324 784.408 959.124 779.803 953.024 780.097L916.324 781.861Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_85'
                        opacity='0.5294'
                        d='M916.424 781.96C910.324 782.254 905.624 787.348 905.924 793.324L907.724 829.277C908.024 835.253 913.224 839.857 919.324 839.563L956.023 837.8C962.123 837.506 966.823 832.412 966.523 826.436L964.724 790.483C964.424 784.507 959.223 779.903 953.123 780.197L916.424 781.96Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_86'
                        opacity='0.549'
                        d='M916.523 782.058C910.423 782.352 905.723 787.446 906.023 793.422L907.824 829.375C908.124 835.35 913.324 839.955 919.424 839.661L956.123 837.898C962.223 837.604 966.923 832.51 966.623 826.534L964.824 790.581C964.524 784.605 959.324 780.001 953.224 780.295L916.523 782.058Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_87'
                        opacity='0.5686'
                        d='M916.624 782.156C910.524 782.45 905.824 787.544 906.124 793.519L907.924 829.472C908.224 835.448 913.424 840.053 919.524 839.759L956.224 837.995C962.324 837.701 967.024 832.607 966.724 826.631L964.924 790.678C964.624 784.703 959.424 780.098 953.324 780.392L916.624 782.156Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_88'
                        opacity='0.5882'
                        d='M916.725 782.253C910.625 782.547 905.925 787.641 906.225 793.617L908.024 829.57C908.324 835.546 913.524 840.15 919.624 839.856L956.325 838.093C962.425 837.799 967.125 832.705 966.825 826.729L965.024 790.776C964.724 784.8 959.525 780.196 953.425 780.49L916.725 782.253Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_89'
                        opacity='0.6078'
                        d='M916.824 782.351C910.724 782.645 906.024 787.739 906.324 793.715L908.124 829.668C908.424 835.643 913.624 840.248 919.724 839.954L956.424 838.19C962.524 837.897 967.224 832.802 966.924 826.827L965.124 790.874C964.824 784.898 959.624 780.294 953.524 780.588L916.824 782.351Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_90'
                        opacity='0.6275'
                        d='M916.924 782.449C910.824 782.742 906.124 787.837 906.424 793.812L908.224 829.765C908.524 835.741 913.724 840.345 919.824 840.052L956.523 838.288C962.623 837.994 967.323 832.9 967.023 826.924L965.224 790.971C964.924 784.996 959.723 780.391 953.623 780.685L916.924 782.449Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_91'
                        opacity='0.6471'
                        d='M917.023 782.546C910.923 782.84 906.223 787.934 906.523 793.91L908.324 829.863C908.624 835.839 913.824 840.443 919.924 840.149L956.623 838.386C962.723 838.092 967.423 832.998 967.123 827.022L965.324 791.069C965.024 785.093 959.824 780.489 953.724 780.783L917.023 782.546Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_92'
                        opacity='0.6667'
                        d='M917.124 782.646C911.024 782.94 906.324 788.034 906.624 794.01L908.424 829.963C908.724 835.938 913.924 840.543 920.024 840.249L956.724 838.486C962.824 838.192 967.524 833.097 967.224 827.122L965.424 791.169C965.124 785.193 959.924 780.589 953.824 780.882L917.124 782.646Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_93'
                        opacity='0.6863'
                        d='M917.225 782.646C911.125 782.94 906.425 788.034 906.725 794.01L908.524 829.963C908.824 835.938 914.024 840.543 920.124 840.249L956.825 838.486C962.925 838.192 967.625 833.097 967.325 827.122L965.524 791.169C965.224 785.193 960.025 780.589 953.925 780.882L917.225 782.646Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_94'
                        opacity='0.7059'
                        d='M917.324 782.743C911.224 783.037 906.524 788.131 906.824 794.107L908.624 830.06C908.924 836.036 914.124 840.64 920.224 840.346L956.924 838.583C963.024 838.289 967.724 833.195 967.424 827.219L965.624 791.266C965.324 785.291 960.124 780.686 954.024 780.98L917.324 782.743Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_95'
                        opacity='0.7255'
                        d='M917.424 782.841C911.324 783.135 906.624 788.229 906.924 794.205L908.724 830.158C909.024 836.134 914.224 840.738 920.324 840.444L957.023 838.681C963.123 838.387 967.823 833.293 967.523 827.317L965.724 791.364C965.424 785.388 960.223 780.784 954.123 781.078L917.424 782.841Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_96'
                        opacity='0.7451'
                        d='M917.523 782.939C911.423 783.233 906.723 788.327 907.023 794.303L908.824 830.256C909.124 836.231 914.324 840.836 920.424 840.542L957.123 838.778C963.223 838.485 967.923 833.39 967.623 827.415L965.824 791.462C965.524 785.486 960.324 780.882 954.224 781.175L917.523 782.939Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_97'
                        opacity='0.7647'
                        d='M917.624 783.036C911.524 783.33 906.824 788.425 907.124 794.4L908.924 830.353C909.224 836.329 914.424 840.933 920.524 840.639L957.224 838.876C963.324 838.582 968.024 833.488 967.724 827.512L965.924 791.559C965.624 785.584 960.424 780.979 954.324 781.273L917.624 783.036Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_98'
                        opacity='0.7843'
                        d='M917.725 783.134C911.625 783.428 906.925 788.522 907.225 794.498L909.024 830.451C909.324 836.427 914.524 841.031 920.624 840.737L957.325 838.974C963.425 838.68 968.125 833.586 967.825 827.61L966.024 791.657C965.724 785.681 960.525 781.077 954.425 781.371L917.725 783.134Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_99'
                        opacity='0.8039'
                        d='M917.824 783.232C911.724 783.526 907.024 788.62 907.324 794.596L909.124 830.548C909.424 836.524 914.624 841.129 920.724 840.835L957.424 839.071C963.524 838.777 968.224 833.683 967.924 827.707L966.124 791.755C965.824 785.779 960.624 781.175 954.524 781.468L917.824 783.232Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_100'
                        opacity='0.8235'
                        d='M917.924 783.331C911.824 783.625 907.124 788.719 907.424 794.695L909.224 830.648C909.524 836.624 914.724 841.228 920.824 840.934L957.523 839.171C963.623 838.877 968.323 833.783 968.023 827.807L966.224 791.854C965.924 785.878 960.723 781.274 954.623 781.568L917.924 783.331Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_101'
                        opacity='0.8431'
                        d='M918.023 783.331C911.923 783.625 907.223 788.719 907.523 794.695L909.324 830.648C909.624 836.624 914.824 841.228 920.924 840.934L957.623 839.171C963.723 838.877 968.423 833.783 968.123 827.807L966.324 791.854C966.024 785.878 960.824 781.274 954.724 781.568L918.023 783.331Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_102'
                        opacity='0.8627'
                        d='M918.124 783.429C912.024 783.723 907.324 788.817 907.624 794.793L909.424 830.746C909.724 836.722 914.924 841.326 921.024 841.032L957.724 839.269C963.824 838.975 968.524 833.881 968.224 827.905L966.424 791.952C966.124 785.976 960.924 781.372 954.824 781.666L918.124 783.429Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_103'
                        opacity='0.8824'
                        d='M918.225 783.527C912.125 783.821 907.425 788.915 907.725 794.891L909.524 830.843C909.824 836.819 915.024 841.424 921.124 841.13L957.825 839.366C963.925 839.072 968.625 833.978 968.325 828.003L966.524 792.05C966.224 786.074 961.025 781.469 954.925 781.763L918.225 783.527Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_104'
                        opacity='0.902'
                        d='M918.324 783.624C912.224 783.918 907.524 789.012 907.824 794.988L909.624 830.941C909.924 836.917 915.124 841.521 921.224 841.227L957.924 839.464C964.024 839.17 968.724 834.076 968.424 828.1L966.624 792.147C966.324 786.171 961.124 781.567 955.024 781.861L918.324 783.624Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_105'
                        opacity='0.9216'
                        d='M918.424 783.724C912.324 784.018 907.624 789.112 907.924 795.088L909.724 831.041C910.024 837.016 915.224 841.621 921.324 841.327L958.023 839.564C964.123 839.27 968.823 834.176 968.523 828.2L966.724 792.247C966.424 786.271 961.223 781.667 955.123 781.961L918.424 783.724Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_106'
                        opacity='0.9412'
                        d='M918.523 783.822C912.423 784.115 907.723 789.21 908.023 795.185L909.824 831.138C910.124 837.114 915.324 841.718 921.424 841.425L958.123 839.661C964.223 839.367 968.923 834.273 968.623 828.297L966.824 792.344C966.524 786.369 961.324 781.764 955.224 782.058L918.523 783.822Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_107'
                        opacity='0.9608'
                        d='M918.624 783.919C912.524 784.213 907.824 789.307 908.124 795.283L909.924 831.236C910.224 837.212 915.424 841.816 921.524 841.522L958.224 839.759C964.324 839.465 969.024 834.371 968.724 828.395L966.924 792.442C966.624 786.466 961.424 781.862 955.324 782.156L918.624 783.919Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_108'
                        opacity='0.9804'
                        d='M918.725 784.017C912.625 784.311 907.925 789.405 908.225 795.381L910.024 831.334C910.324 837.309 915.524 841.914 921.624 841.62L958.325 839.857C964.425 839.563 969.125 834.469 968.825 828.493L967.024 792.54C966.724 786.564 961.525 781.96 955.425 782.254L918.725 784.017Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_109'
                        d='M918.824 784.017C912.724 784.311 908.024 789.405 908.324 795.381L910.124 831.334C910.424 837.309 915.624 841.914 921.724 841.62L958.424 839.857C964.524 839.563 969.224 834.469 968.924 828.493L967.124 792.54C966.824 786.564 961.624 781.96 955.524 782.254L918.824 784.017Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_110'
                      d='M919.724 785.093C913.724 785.387 909.124 790.384 909.424 796.261L911.224 831.529C911.524 837.406 916.624 841.913 922.624 841.619L958.624 839.855C964.624 839.562 969.224 834.565 968.924 828.688L967.124 793.42C966.824 787.543 961.724 783.036 955.724 783.33L919.724 785.093Z'
                      fill='url(#paint1_linear_413_2)'
                    />
                    <path
                      id='Vector_111'
                      d='M919.725 785.584C914.025 785.878 909.525 790.678 909.825 796.262L911.625 831.529C911.925 837.113 916.824 841.522 922.524 841.228L958.524 839.464C964.224 839.17 968.725 834.37 968.425 828.786L966.625 793.519C966.325 787.935 961.425 783.527 955.725 783.821L919.725 785.584Z'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 6'
                      transform='matrix(0.99923 -0.0378349 0.0394213 0.999169 917.717 792.07)'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[5].name && task[5].name.length > 3
                          ? truncateText(task[5].name, 4)
                          : task[5].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_112'
                      d='M927.51 827.303L953.21 826.323C956.81 826.225 959.61 823.188 959.51 819.661L959.41 817.604C959.31 814.077 956.21 811.334 952.61 811.432L926.91 812.412C923.31 812.51 920.51 815.547 920.61 819.073L920.71 821.131C920.91 824.657 923.91 827.4 927.51 827.303Z'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_2'
                      transform='matrix(0.998273 -0.0588612 0.0613238 0.998124 930.889 812.914)'
                      fill={task[5].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[5].counter + '/3'}  */}
                        {(task[5]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[5]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[4] !== undefined && task[4] !== null && (
              <g id='task-5'>
                <g id='rod-5'>
                  <g id='Rectangle_6-5_00000102538985287077199960000010907681552148791956_'>
                    <g id='Group_41'>
                      <path
                        id='Vector_113'
                        d='M788.91 590.328C789.01 593.561 791.81 596.01 795.11 595.814L1171.51 579.258C1174.81 579.16 1177.31 576.417 1177.11 573.184C1177.01 569.952 1174.21 567.503 1170.91 567.698L794.51 584.254C791.31 584.45 788.81 587.193 788.91 590.328Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_42'>
                      <path
                        id='Vector_114'
                        d='M788.91 590.328C789.01 593.561 791.81 596.01 795.11 595.814L1171.51 579.258C1174.81 579.16 1177.31 576.417 1177.11 573.184C1177.01 569.952 1174.21 567.503 1170.91 567.698L794.51 584.254C791.31 584.45 788.81 587.193 788.91 590.328Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_43'>
                    <path
                      id='Rectangle_6-6_00000159465662572304172530000010312079659724212642_'
                      d='M788.9 590.387C789 593.62 791.801 596.069 795.101 595.873L1171.5 579.317C1174.8 579.219 1177.3 576.476 1177.1 573.243C1177 570.01 1174.2 567.561 1170.9 567.757L794.5 584.313C791.3 584.411 788.8 587.154 788.9 590.387Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-5'>
                  <g id='rods_3'>
                    <g id='Group_44'>
                      <path
                        id='Rectangle_8-13_00000115481401696925613860000006765722526215881894_'
                        d='M1198.21 583.079C1196.01 585.43 1196.21 589.054 1198.61 591.21L1248.21 635.587C1250.61 637.743 1254.31 637.547 1256.51 635.196C1258.71 632.844 1258.51 629.22 1256.11 627.065L1206.51 582.687C1204.11 580.531 1200.41 580.727 1198.21 583.079Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_45'>
                        <path
                          id='Rectangle_8-14_00000121241979494961988380000008436897266693747363_'
                          d='M1198.2 583.04C1196 585.391 1196.2 589.016 1198.6 591.171L1248.2 635.549C1250.6 637.704 1254.3 637.508 1256.5 635.157C1258.7 632.806 1258.5 629.181 1256.1 627.026L1206.5 582.648C1204.1 580.591 1200.4 580.689 1198.2 583.04Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_46'>
                      <path
                        id='Rectangle_9-13_00000084491015238280515220000010855262905268361600_'
                        d='M1197.81 564.661C1200.21 566.816 1203.91 566.718 1206.11 564.465L1252.31 516.659C1254.51 514.308 1254.41 510.683 1252.11 508.528C1249.71 506.373 1246.01 506.47 1243.81 508.724L1197.61 556.53C1195.31 558.783 1195.41 562.506 1197.81 564.661Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_47'>
                        <path
                          id='Rectangle_9-14_00000172410038789494341710000012062685390196265603_'
                          d='M1197.8 564.622C1200.2 566.777 1203.9 566.679 1206.1 564.426L1252.3 516.62C1254.5 514.269 1254.4 510.644 1252.1 508.489C1249.7 506.333 1246 506.431 1243.8 508.685L1197.6 556.491C1195.3 558.842 1195.4 562.467 1197.8 564.622Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_3'>
                    <g id='Group_48'>
                      <path
                        id='Ellipse_16-13_00000151534936255167066740000000477844709144233917_'
                        d='M1189.11 591.699C1199.27 591.699 1207.51 583.629 1207.51 573.674C1207.51 563.719 1199.27 555.648 1189.11 555.648C1178.95 555.648 1170.71 563.719 1170.71 573.674C1170.71 583.629 1178.95 591.699 1189.11 591.699Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_49'>
                        <path
                          id='Ellipse_16-14_00000055674235193085374360000002608661347482338979_'
                          d='M1189.1 591.758C1199.26 591.758 1207.5 583.688 1207.5 573.732C1207.5 563.777 1199.26 555.707 1189.1 555.707C1178.94 555.707 1170.7 563.777 1170.7 573.732C1170.7 583.688 1178.94 591.758 1189.1 591.758Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000005968784024588770580000013883205794189918084_'
                      d='M1174.31 581.315L1173.91 566.228C1173.81 563.877 1171.81 562.016 1169.41 562.016C1167.01 562.114 1165.11 564.073 1165.11 566.424L1165.51 581.511C1165.61 583.862 1167.61 585.723 1170.01 585.723C1172.51 585.625 1174.41 583.666 1174.31 581.315Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_7'
                      transform='matrix(0.0143929 0.999896 -0.999904 0.013813 1173.57 566.27)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_50' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000022536599141775859070000017541124675473725057_'
                        d='M1189.1 586.174C1196.11 586.174 1201.8 580.604 1201.8 573.732C1201.8 566.861 1196.11 561.291 1189.1 561.291C1182.09 561.291 1176.4 566.861 1176.4 573.732C1176.4 580.604 1182.09 586.174 1189.1 586.174Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_3'>
                    <g id='Group_51'>
                      <path
                        id='Ellipse_18-13_00000023266494667710510890000010651614821811572374_'
                        d='M1260.51 517.834C1270.67 517.834 1278.91 509.764 1278.91 499.809C1278.91 489.853 1270.67 481.783 1260.51 481.783C1250.35 481.783 1242.11 489.853 1242.11 499.809C1242.11 509.764 1250.35 517.834 1260.51 517.834Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_52'>
                        <path
                          id='Ellipse_18-14_00000158715570191449082830000003534015300672635564_'
                          d='M1260.5 517.795C1270.66 517.795 1278.9 509.725 1278.9 499.77C1278.9 489.814 1270.66 481.744 1260.5 481.744C1250.34 481.744 1242.1 489.814 1242.1 499.77C1242.1 509.725 1250.34 517.795 1260.5 517.795Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000129923614645432120310000012347121694876019892_'
                      d='M1247.11 508.135L1246.71 493.048C1246.61 490.697 1244.61 488.836 1242.21 488.836C1239.81 488.934 1237.91 490.893 1237.91 493.244L1238.31 508.331C1238.41 510.682 1240.41 512.543 1242.81 512.543C1245.21 512.445 1247.11 510.486 1247.11 508.135Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_8'
                      transform='matrix(0.0143929 0.999896 -0.999904 0.013813 1247.2 492.906)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_53' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000009592033886211929480000007482363800187473314_'
                        d='M1260.5 512.211C1267.51 512.211 1273.2 506.641 1273.2 499.77C1273.2 492.898 1267.51 487.328 1260.5 487.328C1253.49 487.328 1247.8 492.898 1247.8 499.77C1247.8 506.641 1253.49 512.211 1260.5 512.211Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_3'>
                    <g id='Group_54'>
                      <path
                        id='Ellipse_21-13_00000084511905768028792510000010248663742336413861_'
                        d='M1263.81 661.059C1273.97 661.059 1282.21 652.988 1282.21 643.033C1282.21 633.078 1273.97 625.008 1263.81 625.008C1253.65 625.008 1245.41 633.078 1245.41 643.033C1245.41 652.988 1253.65 661.059 1263.81 661.059Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_55'>
                        <path
                          id='Ellipse_21-14_00000029753076278566976840000008672334396524414080_'
                          d='M1263.8 661.02C1273.96 661.02 1282.2 652.949 1282.2 642.994C1282.2 633.039 1273.96 624.969 1263.8 624.969C1253.64 624.969 1245.4 633.039 1245.4 642.994C1245.4 652.949 1253.64 661.02 1263.8 661.02Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000060724204433119850210000014604334652095990423_'
                      d='M1251.11 650.674L1250.71 635.587C1250.61 633.236 1248.61 631.375 1246.21 631.375C1243.81 631.473 1241.91 633.432 1241.91 635.783L1242.31 650.87C1242.41 653.221 1244.41 655.082 1246.81 655.082C1249.21 655.082 1251.11 653.123 1251.11 650.674Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_9'
                      transform='matrix(0.0143929 0.999896 -0.999904 0.013813 1250.99 635.488)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_56' opacity={task[4].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000037668709402510772880000008869625309599634106_'
                        d='M1263.8 655.438C1270.81 655.438 1276.5 649.867 1276.5 642.996C1276.5 636.125 1270.81 630.555 1263.8 630.555C1256.79 630.555 1251.1 636.125 1251.1 642.996C1251.1 649.867 1256.79 655.438 1263.8 655.438Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[4].TasksAndStudents?.id || 0)}>
                  <g id='box-task-5'>
                    <g id='Group_57'>
                      <path
                        id='Vector_115'
                        opacity='0.0196078'
                        d='M988.51 558.292C988.31 552.316 993.21 547.32 999.31 547.124L1036.01 546.144C1042.11 545.949 1047.21 550.749 1047.41 556.725L1048.41 592.677C1048.61 598.653 1043.71 603.649 1037.61 603.845L1000.91 604.825C994.81 605.021 989.71 600.221 989.51 594.245L988.51 558.292Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_116'
                        opacity='0.0392157'
                        d='M988.609 558.292C988.409 552.316 993.31 547.32 999.41 547.124L1036.11 546.144C1042.21 545.949 1047.31 550.749 1047.51 556.725L1048.51 592.677C1048.71 598.653 1043.81 603.649 1037.71 603.845L1001.01 604.825C994.91 605.021 989.809 600.221 989.609 594.245L988.609 558.292Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_117'
                        opacity='0.0588235'
                        d='M988.71 558.39C988.51 552.414 993.41 547.418 999.51 547.222L1036.21 546.242C1042.31 546.046 1047.41 550.846 1047.61 556.822L1048.61 592.775C1048.81 598.751 1043.91 603.747 1037.81 603.943L1001.11 604.923C995.01 605.119 989.91 600.318 989.71 594.343L988.71 558.39Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_118'
                        opacity='0.0784314'
                        d='M988.811 558.487C988.611 552.512 993.51 547.515 999.61 547.319L1036.31 546.34C1042.41 546.144 1047.51 550.944 1047.71 556.92L1048.71 592.873C1048.91 598.849 1044.01 603.845 1037.91 604.041L1001.21 605.02C995.111 605.216 990.011 600.416 989.811 594.44L988.811 558.487Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_119'
                        opacity='0.0980392'
                        d='M988.811 558.587C988.611 552.611 993.51 547.615 999.61 547.419L1036.31 546.439C1042.41 546.243 1047.51 551.044 1047.71 557.02L1048.71 592.972C1048.91 598.948 1044.01 603.944 1037.91 604.14L1001.21 605.12C995.111 605.316 990.011 600.516 989.811 594.54L988.811 558.587Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_120'
                        opacity='0.1176'
                        d='M988.91 558.685C988.71 552.709 993.61 547.713 999.71 547.517L1036.41 546.537C1042.51 546.341 1047.61 551.141 1047.81 557.117L1048.81 593.07C1049.01 599.046 1044.11 604.042 1038.01 604.238L1001.31 605.218C995.21 605.414 990.11 600.613 989.91 594.638L988.91 558.685Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_121'
                        opacity='0.1373'
                        d='M989.01 558.782C988.81 552.806 993.71 547.81 999.81 547.614L1036.51 546.635C1042.61 546.439 1047.71 551.239 1047.91 557.215L1048.91 593.168C1049.11 599.144 1044.21 604.14 1038.11 604.336L1001.41 605.315C995.31 605.511 990.21 600.711 990.01 594.735L989.01 558.782Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_122'
                        opacity='0.1569'
                        d='M989.109 558.88C988.909 552.904 993.81 547.908 999.91 547.712L1036.61 546.732C1042.71 546.536 1047.81 551.337 1048.01 557.312L1049.01 593.265C1049.21 599.241 1044.31 604.237 1038.21 604.433L1001.51 605.413C995.41 605.609 990.309 600.809 990.109 594.833L989.109 558.88Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_123'
                        opacity='0.1765'
                        d='M989.21 558.978C989.01 553.002 993.91 548.006 1000.01 547.81L1036.71 546.83C1042.81 546.634 1047.91 551.434 1048.11 557.41L1049.11 593.363C1049.31 599.339 1044.41 604.335 1038.31 604.531L1001.61 605.511C995.51 605.707 990.41 600.906 990.21 594.931L989.21 558.978Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_124'
                        opacity='0.1961'
                        d='M989.311 559.075C989.111 553.099 994.01 548.103 1000.11 547.907L1036.81 546.928C1042.91 546.732 1048.01 551.532 1048.21 557.508L1049.21 593.461C1049.41 599.437 1044.51 604.433 1038.41 604.629L1001.71 605.608C995.611 605.804 990.511 601.004 990.311 595.028L989.311 559.075Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_125'
                        opacity='0.2157'
                        d='M989.41 559.075C989.21 553.099 994.11 548.103 1000.21 547.907L1036.91 546.928C1043.01 546.732 1048.11 551.532 1048.31 557.508L1049.31 593.461C1049.51 599.437 1044.61 604.433 1038.51 604.629L1001.81 605.608C995.71 605.804 990.61 601.004 990.41 595.028L989.41 559.075Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_126'
                        opacity='0.2353'
                        d='M989.51 559.175C989.31 553.199 994.21 548.203 1000.31 548.007L1037.01 547.027C1043.11 546.831 1048.21 551.632 1048.41 557.607L1049.41 593.56C1049.61 599.536 1044.71 604.532 1038.61 604.728L1001.91 605.708C995.81 605.904 990.71 601.104 990.51 595.128L989.51 559.175Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_127'
                        opacity='0.2549'
                        d='M989.609 559.272C989.409 553.297 994.31 548.301 1000.41 548.105L1037.11 547.125C1043.21 546.929 1048.31 551.729 1048.51 557.705L1049.51 593.658C1049.71 599.634 1044.81 604.63 1038.71 604.826L1002.01 605.806C995.91 606.001 990.809 601.201 990.609 595.225L989.609 559.272Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_128'
                        opacity='0.2745'
                        d='M989.71 559.37C989.51 553.394 994.41 548.398 1000.51 548.202L1037.21 547.223C1043.31 547.027 1048.41 551.827 1048.61 557.803L1049.61 593.756C1049.81 599.731 1044.91 604.728 1038.81 604.924L1002.11 605.903C996.01 606.099 990.91 601.299 990.71 595.323L989.71 559.37Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_129'
                        opacity='0.2941'
                        d='M989.811 559.468C989.611 553.492 994.51 548.496 1000.61 548.3L1037.31 547.32C1043.41 547.124 1048.51 551.925 1048.71 557.9L1049.71 593.853C1049.91 599.829 1045.01 604.825 1038.91 605.021L1002.21 606.001C996.111 606.197 991.011 601.397 990.811 595.421L989.811 559.468Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_130'
                        opacity='0.3137'
                        d='M989.91 559.565C989.71 553.59 994.61 548.593 1000.71 548.398L1037.41 547.418C1043.51 547.222 1048.61 552.022 1048.81 557.998L1049.81 593.951C1050.01 599.927 1045.11 604.923 1039.01 605.119L1002.31 606.098C996.21 606.294 991.11 601.494 990.91 595.518L989.91 559.565Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_131'
                        opacity='0.3333'
                        d='M990.01 559.663C989.81 553.687 994.71 548.691 1000.81 548.495L1037.51 547.516C1043.61 547.32 1048.71 552.12 1048.91 558.096L1049.91 594.049C1050.11 600.024 1045.21 605.021 1039.11 605.217L1002.41 606.196C996.31 606.392 991.21 601.592 991.01 595.616L990.01 559.663Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_132'
                        opacity='0.3529'
                        d='M990.109 559.761C989.909 553.785 994.81 548.789 1000.91 548.593L1037.61 547.613C1043.71 547.417 1048.81 552.218 1049.01 558.193L1050.01 594.146C1050.21 600.122 1045.31 605.118 1039.21 605.314L1002.51 606.294C996.41 606.49 991.309 601.689 991.109 595.714L990.109 559.761Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_133'
                        opacity='0.3725'
                        d='M990.21 559.86C990.01 553.885 994.91 548.888 1001.01 548.692L1037.71 547.713C1043.81 547.517 1048.91 552.317 1049.11 558.293L1050.11 594.246C1050.31 600.222 1045.41 605.218 1039.31 605.414L1002.61 606.393C996.51 606.589 991.41 601.789 991.21 595.813L990.21 559.86Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_134'
                        opacity='0.3922'
                        d='M990.311 559.958C990.111 553.982 995.01 548.986 1001.11 548.79L1037.81 547.81C1043.91 547.615 1049.01 552.415 1049.21 558.391L1050.21 594.344C1050.41 600.319 1045.51 605.316 1039.41 605.511L1002.71 606.491C996.611 606.687 991.511 601.887 991.311 595.911L990.311 559.958Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_135'
                        opacity='0.4118'
                        d='M990.41 559.958C990.21 553.982 995.11 548.986 1001.21 548.79L1037.91 547.81C1044.01 547.615 1049.11 552.415 1049.31 558.391L1050.31 594.344C1050.51 600.319 1045.61 605.316 1039.51 605.511L1002.81 606.491C996.71 606.687 991.61 601.887 991.41 595.911L990.41 559.958Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_136'
                        opacity='0.4314'
                        d='M990.51 560.056C990.31 554.08 995.21 549.084 1001.31 548.888L1038.01 547.908C1044.11 547.712 1049.21 552.512 1049.41 558.488L1050.41 594.441C1050.61 600.417 1045.71 605.413 1039.61 605.609L1002.91 606.589C996.81 606.785 991.71 601.984 991.51 596.009L990.51 560.056Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_137'
                        opacity='0.451'
                        d='M990.609 560.153C990.409 554.178 995.31 549.181 1001.41 548.985L1038.11 548.006C1044.21 547.81 1049.31 552.61 1049.51 558.586L1050.51 594.539C1050.71 600.515 1045.81 605.511 1039.71 605.707L1003.01 606.686C996.91 606.882 991.809 602.082 991.609 596.106L990.609 560.153Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_138'
                        opacity='0.4706'
                        d='M990.71 560.251C990.51 554.275 995.41 549.279 1001.51 549.083L1038.21 548.103C1044.31 547.908 1049.41 552.708 1049.61 558.684L1050.61 594.636C1050.81 600.612 1045.91 605.608 1039.81 605.804L1003.11 606.784C997.01 606.98 991.91 602.18 991.71 596.204L990.71 560.251Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_139'
                        opacity='0.4902'
                        d='M990.71 560.349C990.51 554.373 995.41 549.377 1001.51 549.181L1038.21 548.201C1044.31 548.005 1049.41 552.805 1049.61 558.781L1050.61 594.734C1050.81 600.71 1045.91 605.706 1039.81 605.902L1003.11 606.882C997.01 607.078 991.91 602.277 991.71 596.302L990.71 560.349Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_140'
                        opacity='0.5098'
                        d='M990.811 560.448C990.611 554.472 995.51 549.476 1001.61 549.28L1038.31 548.301C1044.41 548.105 1049.51 552.905 1049.71 558.881L1050.71 594.834C1050.91 600.81 1046.01 605.806 1039.91 606.002L1003.21 606.981C997.111 607.177 992.011 602.377 991.811 596.401L990.811 560.448Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_141'
                        opacity='0.5294'
                        d='M990.91 560.546C990.71 554.57 995.61 549.574 1001.71 549.378L1038.41 548.398C1044.51 548.202 1049.61 553.003 1049.81 558.979L1050.81 594.931C1051.01 600.907 1046.11 605.903 1040.01 606.099L1003.31 607.079C997.21 607.275 992.11 602.475 991.91 596.499L990.91 560.546Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_142'
                        opacity='0.549'
                        d='M991.01 560.644C990.81 554.668 995.71 549.672 1001.81 549.476L1038.51 548.496C1044.61 548.3 1049.71 553.1 1049.91 559.076L1050.91 595.029C1051.11 601.005 1046.21 606.001 1040.11 606.197L1003.41 607.177C997.31 607.373 992.21 602.572 992.01 596.597L991.01 560.644Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_143'
                        opacity='0.5686'
                        d='M991.109 560.741C990.909 554.765 995.81 549.769 1001.91 549.573L1038.61 548.594C1044.71 548.398 1049.81 553.198 1050.01 559.174L1051.01 595.127C1051.21 601.103 1046.31 606.099 1040.21 606.295L1003.51 607.274C997.41 607.47 992.309 602.67 992.109 596.694L991.109 560.741Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_144'
                        opacity='0.5882'
                        d='M991.21 560.839C991.01 554.863 995.91 549.867 1002.01 549.671L1038.71 548.691C1044.81 548.495 1049.91 553.296 1050.11 559.271L1051.11 595.224C1051.31 601.2 1046.41 606.196 1040.31 606.392L1003.61 607.372C997.51 607.568 992.41 602.768 992.21 596.792L991.21 560.839Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_145'
                        opacity='0.6078'
                        d='M991.311 560.839C991.111 554.863 996.01 549.867 1002.11 549.671L1038.81 548.691C1044.91 548.495 1050.01 553.296 1050.21 559.271L1051.21 595.224C1051.41 601.2 1046.51 606.196 1040.41 606.392L1003.71 607.372C997.611 607.568 992.511 602.768 992.311 596.792L991.311 560.839Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_146'
                        opacity='0.6275'
                        d='M991.41 560.937C991.21 554.961 996.11 549.965 1002.21 549.769L1038.91 548.789C1045.01 548.593 1050.11 553.393 1050.31 559.369L1051.31 595.322C1051.51 601.298 1046.61 606.294 1040.51 606.49L1003.81 607.47C997.71 607.666 992.61 602.865 992.41 596.889L991.41 560.937Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_147'
                        opacity='0.6471'
                        d='M991.51 561.034C991.31 555.058 996.21 550.062 1002.31 549.866L1039.01 548.887C1045.11 548.691 1050.21 553.491 1050.41 559.467L1051.41 595.42C1051.61 601.396 1046.71 606.392 1040.61 606.588L1003.91 607.567C997.81 607.763 992.71 602.963 992.51 596.987L991.51 561.034Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_148'
                        opacity='0.6667'
                        d='M991.609 561.134C991.409 555.158 996.31 550.162 1002.41 549.966L1039.11 548.986C1045.21 548.79 1050.31 553.591 1050.51 559.566L1051.51 595.519C1051.71 601.495 1046.81 606.491 1040.71 606.687L1004.01 607.667C997.91 607.863 992.809 603.063 992.609 597.087L991.609 561.134Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_149'
                        opacity='0.6863'
                        d='M991.71 561.231C991.51 555.256 996.41 550.259 1002.51 550.064L1039.21 549.084C1045.31 548.888 1050.41 553.688 1050.61 559.664L1051.61 595.617C1051.81 601.593 1046.91 606.589 1040.81 606.785L1004.11 607.764C998.01 607.96 992.91 603.16 992.71 597.184L991.71 561.231Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_150'
                        opacity='0.7059'
                        d='M991.811 561.329C991.611 555.353 996.51 550.357 1002.61 550.161L1039.31 549.182C1045.41 548.986 1050.51 553.786 1050.71 559.762L1051.71 595.715C1051.91 601.69 1047.01 606.687 1040.91 606.883L1004.21 607.862C998.111 608.058 993.011 603.258 992.811 597.282L991.811 561.329Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_151'
                        opacity='0.7255'
                        d='M991.91 561.427C991.71 555.451 996.61 550.455 1002.71 550.259L1039.41 549.279C1045.51 549.083 1050.61 553.884 1050.81 559.859L1051.81 595.812C1052.01 601.788 1047.11 606.784 1041.01 606.98L1004.31 607.96C998.21 608.156 993.11 603.356 992.91 597.38L991.91 561.427Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_152'
                        opacity='0.7451'
                        d='M992.01 561.524C991.81 555.549 996.71 550.552 1002.81 550.357L1039.51 549.377C1045.61 549.181 1050.71 553.981 1050.91 559.957L1051.91 595.91C1052.11 601.886 1047.21 606.882 1041.11 607.078L1004.41 608.057C998.31 608.253 993.21 603.453 993.01 597.477L992.01 561.524Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_153'
                        opacity='0.7647'
                        d='M992.109 561.622C991.909 555.646 996.81 550.65 1002.91 550.454L1039.61 549.475C1045.71 549.279 1050.81 554.079 1051.01 560.055L1052.01 596.008C1052.21 601.983 1047.31 606.98 1041.21 607.176L1004.51 608.155C998.41 608.351 993.309 603.551 993.109 597.575L992.109 561.622Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_154'
                        opacity='0.7843'
                        d='M992.21 561.72C992.01 555.744 996.91 550.748 1003.01 550.552L1039.71 549.572C1045.81 549.376 1050.91 554.177 1051.11 560.152L1052.11 596.105C1052.31 602.081 1047.41 607.077 1041.31 607.273L1004.61 608.253C998.51 608.449 993.41 603.648 993.21 597.673L992.21 561.72Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_155'
                        opacity='0.8039'
                        d='M992.311 561.72C992.111 555.744 997.01 550.748 1003.11 550.552L1039.81 549.572C1045.91 549.376 1051.01 554.177 1051.21 560.152L1052.21 596.105C1052.41 602.081 1047.51 607.077 1041.41 607.273L1004.71 608.253C998.611 608.449 993.511 603.648 993.311 597.673L992.311 561.72Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_156'
                        opacity='0.8235'
                        d='M992.41 561.819C992.21 555.844 997.11 550.847 1003.21 550.651L1039.91 549.672C1046.01 549.476 1051.11 554.276 1051.31 560.252L1052.31 596.205C1052.51 602.181 1047.61 607.177 1041.51 607.373L1004.81 608.352C998.71 608.548 993.61 603.748 993.41 597.772L992.41 561.819Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_157'
                        opacity='0.8431'
                        d='M992.51 561.917C992.31 555.941 997.21 550.945 1003.31 550.749L1040.01 549.769C1046.11 549.574 1051.21 554.374 1051.41 560.35L1052.41 596.303C1052.61 602.278 1047.71 607.275 1041.61 607.47L1004.91 608.45C998.81 608.646 993.71 603.846 993.51 597.87L992.51 561.917Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_158'
                        opacity='0.8627'
                        d='M992.609 562.015C992.409 556.039 997.31 551.043 1003.41 550.847L1040.11 549.867C1046.21 549.671 1051.31 554.471 1051.51 560.447L1052.51 596.4C1052.71 602.376 1047.81 607.372 1041.71 607.568L1005.01 608.548C998.91 608.744 993.809 603.943 993.609 597.968L992.609 562.015Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_159'
                        opacity='0.8824'
                        d='M992.609 562.112C992.409 556.137 997.31 551.14 1003.41 550.944L1040.11 549.965C1046.21 549.769 1051.31 554.569 1051.51 560.545L1052.51 596.498C1052.71 602.474 1047.81 607.47 1041.71 607.666L1005.01 608.645C998.91 608.841 993.809 604.041 993.609 598.065L992.609 562.112Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_160'
                        opacity='0.902'
                        d='M992.71 562.21C992.51 556.234 997.41 551.238 1003.51 551.042L1040.21 550.062C1046.31 549.867 1051.41 554.667 1051.61 560.643L1052.61 596.595C1052.81 602.571 1047.91 607.567 1041.81 607.763L1005.11 608.743C999.01 608.939 993.91 604.139 993.71 598.163L992.71 562.21Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_161'
                        opacity='0.9216'
                        d='M992.811 562.308C992.611 556.332 997.51 551.336 1003.61 551.14L1040.31 550.16C1046.41 549.964 1051.51 554.764 1051.71 560.74L1052.71 596.693C1052.91 602.669 1048.01 607.665 1041.91 607.861L1005.21 608.841C999.111 609.037 994.011 604.236 993.811 598.261L992.811 562.308Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_162'
                        opacity='0.9412'
                        d='M992.91 562.407C992.71 556.431 997.61 551.435 1003.71 551.239L1040.41 550.26C1046.51 550.064 1051.61 554.864 1051.81 560.84L1052.81 596.793C1053.01 602.769 1048.11 607.765 1042.01 607.961L1005.31 608.94C999.21 609.136 994.11 604.336 993.91 598.36L992.91 562.407Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_163'
                        opacity='0.9608'
                        d='M993.01 562.505C992.81 556.529 997.71 551.533 1003.81 551.337L1040.51 550.357C1046.61 550.161 1051.71 554.962 1051.91 560.937L1052.91 596.89C1053.11 602.866 1048.21 607.862 1042.11 608.058L1005.41 609.038C999.31 609.234 994.21 604.434 994.01 598.458L993.01 562.505Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_164'
                        opacity='0.9804'
                        d='M993.109 562.603C992.909 556.627 997.81 551.631 1003.91 551.435L1040.61 550.455C1046.71 550.259 1051.81 555.059 1052.01 561.035L1053.01 596.988C1053.21 602.964 1048.31 607.96 1042.21 608.156L1005.51 609.136C999.41 609.332 994.309 604.531 994.109 598.556L993.109 562.603Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_165'
                        d='M993.21 562.603C993.01 556.627 997.91 551.631 1004.01 551.435L1040.71 550.455C1046.81 550.259 1051.91 555.059 1052.11 561.035L1053.11 596.988C1053.31 602.964 1048.41 607.96 1042.31 608.156L1005.61 609.136C999.51 609.332 994.41 604.531 994.21 598.556L993.21 562.603Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_166'
                      d='M994.41 563.485C994.21 557.607 999.01 552.709 1005.01 552.513L1041.11 551.533C1047.11 551.337 1052.11 556.04 1052.31 561.917L1053.31 597.283C1053.51 603.16 1048.71 608.059 1042.71 608.255L1006.61 609.234C1000.61 609.43 995.61 604.728 995.41 598.85L994.41 563.485Z'
                      fill='url(#paint2_linear_413_2)'
                    />
                    <path
                      id='Vector_167'
                      d='M1007.31 609.137C1001.61 609.431 996.709 605.12 996.309 599.536L994.309 564.269C994.009 558.685 998.409 553.885 1004.11 553.493L1040.11 551.534C1045.81 551.24 1050.71 555.55 1051.11 561.134L1053.11 596.401C1053.41 601.985 1049.01 606.786 1043.31 607.177L1007.31 609.137Z'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 5'
                      transform='matrix(0.997217 -0.0748927 0.0780189 0.996978 1002.06 564.816)'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[4].name && task[4].name.length > 3
                          ? truncateText(task[4].name, 4)
                          : task[4].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_168'
                      d='M1011.41 599.436L1037.11 598.065C1040.71 597.869 1043.51 594.832 1043.31 591.305L1043.21 589.248C1043.01 585.721 1039.91 582.978 1036.31 583.174L1010.61 584.545C1007.01 584.741 1004.21 587.778 1004.41 591.305L1004.51 593.362C1004.71 596.987 1007.71 599.632 1011.41 599.436Z'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_3'
                      transform='matrix(0.997217 -0.0748927 0.0780189 0.996978 1014.46 585.014)'
                      fill={task[4].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3286'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[4].counter + '/3'} */}
                        {(task[4]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[4]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[3] !== undefined && task[3] !== null && (
              <g id='task-4'>
                <g id='rod-4'>
                  <g id='Rectangle_6-5_00000097478872629736384500000008824416223659445377_'>
                    <g id='Group_58'>
                      <path
                        id='Vector_169'
                        d='M759.61 528.316C757.21 526.063 757.21 522.438 759.41 520.185L1020.21 253.82C1022.51 251.469 1026.21 251.469 1028.51 253.624C1030.91 255.877 1030.91 259.502 1028.71 261.755L767.91 528.12C765.71 530.373 761.91 530.471 759.61 528.316Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_59'>
                      <path
                        id='Vector_170'
                        d='M759.61 528.316C757.21 526.063 757.21 522.438 759.41 520.185L1020.21 253.82C1022.51 251.469 1026.21 251.469 1028.51 253.624C1030.91 255.877 1030.91 259.502 1028.71 261.755L767.91 528.12C765.71 530.373 761.91 530.471 759.61 528.316Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_60'>
                    <path
                      id='Rectangle_6-6_00000153702228543367405020000007692638332634211210_'
                      d='M759.601 528.277C757.301 526.024 757.201 522.399 759.401 520.146L1020.2 253.781C1022.5 251.43 1026.2 251.43 1028.5 253.585C1030.8 255.838 1030.9 259.463 1028.7 261.716L767.901 528.081C765.701 530.432 762.001 530.432 759.601 528.277Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-4'>
                  <g id='rods_4'>
                    <g id='Group_61'>
                      <path
                        id='Rectangle_8-13_00000169551544633375668330000017388602056467039881_'
                        d='M1034.91 231.582C1031.61 231.68 1029.01 229.133 1028.91 225.9L1027.51 160.166C1027.41 156.933 1030.01 154.288 1033.31 154.288C1036.61 154.19 1039.21 156.737 1039.31 159.97L1040.71 225.704C1040.71 228.839 1038.21 231.484 1034.91 231.582Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_62'>
                        <path
                          id='Rectangle_8-14_00000132080294701774435300000005670023854171579314_'
                          d='M1034.9 231.543C1031.6 231.641 1028.9 229.094 1028.9 225.861L1027.5 160.127C1027.4 156.894 1030 154.249 1033.3 154.249C1036.6 154.151 1039.3 156.698 1039.3 159.931L1040.7 225.665C1040.8 228.8 1038.2 231.445 1034.9 231.543Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_63'>
                      <path
                        id='Rectangle_9-13_00000015336884960983512520000016897524682299651000_'
                        d='M1048.81 243.926C1048.71 240.693 1051.21 238.048 1054.51 237.95L1121.61 235.403C1124.91 235.305 1127.61 237.754 1127.71 240.987C1127.81 244.22 1125.31 246.865 1122.01 246.963L1054.91 249.51C1051.71 249.608 1048.91 247.159 1048.81 243.926Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_64'>
                        <path
                          id='Rectangle_9-14_00000019653995129106601870000006921559301746174347_'
                          d='M1048.8 243.985C1048.7 240.752 1051.2 238.107 1054.5 238.009L1121.6 235.462C1124.9 235.364 1127.6 237.813 1127.7 241.046C1127.8 244.279 1125.3 246.924 1122 247.022L1054.9 249.569C1051.7 249.667 1048.9 247.12 1048.8 243.985Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_4'>
                    <g id='Group_65'>
                      <path
                        id='Ellipse_16-13_00000111155981381044486130000014118626597413193869_'
                        d='M1036.11 262.441C1046.27 262.441 1054.51 254.371 1054.51 244.416C1054.51 234.461 1046.27 226.391 1036.11 226.391C1025.95 226.391 1017.71 234.461 1017.71 244.416C1017.71 254.371 1025.95 262.441 1036.11 262.441Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_66'>
                        <path
                          id='Ellipse_16-14_00000124847435947963371650000009619979216461323397_'
                          d='M1036.1 262.5C1046.26 262.5 1054.5 254.43 1054.5 244.475C1054.5 234.52 1046.26 226.449 1036.1 226.449C1025.94 226.449 1017.7 234.52 1017.7 244.475C1017.7 254.43 1025.94 262.5 1036.1 262.5Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000058554505386586713530000007160507822598748346_'
                      d='M1020.51 250.295L1031.81 260.581C1033.61 262.148 1033.71 264.891 1032.01 266.655C1030.41 268.418 1027.61 268.516 1025.81 266.851L1014.51 256.564C1012.71 254.997 1012.61 252.254 1014.31 250.49C1016.01 248.825 1018.81 248.727 1020.51 250.295Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_10'
                      transform='matrix(0.740524 0.672049 -0.687092 0.726588 1020.65 251.051)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_67' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000108276483131536109060000010506699451519147905_'
                        d='M1036.1 256.918C1043.11 256.918 1048.8 251.348 1048.8 244.477C1048.8 237.605 1043.11 232.035 1036.1 232.035C1029.09 232.035 1023.4 237.605 1023.4 244.477C1023.4 251.348 1029.09 256.918 1036.1 256.918Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_4'>
                    <g id='Group_68'>
                      <path
                        id='Ellipse_21-13_00000141438932970883197760000003082797171498208686_'
                        d='M1032.21 161.734C1042.37 161.734 1050.61 153.664 1050.61 143.709C1050.61 133.754 1042.37 125.684 1032.21 125.684C1022.05 125.684 1013.81 133.754 1013.81 143.709C1013.81 153.664 1022.05 161.734 1032.21 161.734Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_69'>
                        <path
                          id='Ellipse_21-14_00000039105060675474200400000005012062259116834227_'
                          d='M1032.2 161.695C1042.36 161.695 1050.6 153.625 1050.6 143.67C1050.6 133.715 1042.36 125.645 1032.2 125.645C1022.04 125.645 1013.8 133.715 1013.8 143.67C1013.8 153.625 1022.04 161.695 1032.2 161.695Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000144313774807859713360000000932438802521970100_'
                      d='M1017.81 148.019L1029.11 158.305C1030.91 159.872 1031.01 162.615 1029.31 164.379C1027.71 166.142 1024.91 166.24 1023.11 164.575L1011.81 154.288C1010.01 152.721 1009.91 149.978 1011.61 148.215C1013.31 146.549 1016.11 146.353 1017.81 148.019Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_11'
                      transform='matrix(0.740524 0.672049 -0.687092 0.726588 1017.7 147.209)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_70' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000047772255733809006280000013432568139118061502_'
                        d='M1032.2 156.111C1039.21 156.111 1044.9 150.541 1044.9 143.67C1044.9 136.799 1039.21 131.229 1032.2 131.229C1025.19 131.229 1019.5 136.799 1019.5 143.67C1019.5 150.541 1025.19 156.111 1032.2 156.111Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_4'>
                    <g id='Group_71'>
                      <path
                        id='Ellipse_18-13_00000160154166440983052470000005451316384792333728_'
                        d='M1139.91 258.621C1150.07 258.621 1158.31 250.551 1158.31 240.596C1158.31 230.641 1150.07 222.57 1139.91 222.57C1129.75 222.57 1121.51 230.641 1121.51 240.596C1121.51 250.551 1129.75 258.621 1139.91 258.621Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_72'>
                        <path
                          id='Ellipse_18-14_00000016786069074198364790000003994684301000320923_'
                          d='M1139.9 258.582C1150.06 258.582 1158.3 250.512 1158.3 240.557C1158.3 230.602 1150.06 222.531 1139.9 222.531C1129.74 222.531 1121.5 230.602 1121.5 240.557C1121.5 250.512 1129.74 258.582 1139.9 258.582Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000128446587172305528780000005576816038588320643_'
                      d='M1124.61 244.906L1135.91 255.192C1137.71 256.76 1137.81 259.503 1136.11 261.266C1134.51 263.029 1131.71 263.127 1129.91 261.462L1118.61 251.176C1116.81 249.608 1116.71 246.865 1118.41 245.102C1120.11 243.436 1122.91 243.339 1124.61 244.906Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_12'
                      transform='matrix(0.740524 0.672049 -0.687092 0.726588 1124.5 244.125)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_73' opacity={task[3].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000058586935571983675690000016568363669314971806_'
                        d='M1139.9 252.998C1146.91 252.998 1152.6 247.428 1152.6 240.557C1152.6 233.685 1146.91 228.115 1139.9 228.115C1132.89 228.115 1127.2 233.685 1127.2 240.557C1127.2 247.428 1132.89 252.998 1139.9 252.998Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[3].TasksAndStudents?.id || 0)}>
                  <g id='box-task-4'>
                    <g id='Group_74'>
                      <path
                        id='Vector_171'
                        opacity='0.0196078'
                        d='M942.81 396.947C948.91 396.947 953.91 392.049 953.91 386.073V350.12C953.91 344.144 948.91 339.246 942.81 339.246H906.109C900.009 339.246 895.01 344.144 895.01 350.12V386.073C895.01 392.049 900.009 396.947 906.109 396.947H942.81Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_172'
                        opacity='0.0392157'
                        d='M942.71 396.847C948.81 396.847 953.81 391.949 953.81 385.973V350.021C953.81 344.045 948.81 339.146 942.71 339.146H906.01C899.91 339.146 894.91 344.045 894.91 350.021V385.973C894.91 391.949 899.91 396.847 906.01 396.847H942.71Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_173'
                        opacity='0.0588235'
                        d='M942.61 396.75C948.71 396.75 953.71 391.852 953.71 385.876V349.923C953.71 343.947 948.71 339.049 942.61 339.049H905.911C899.811 339.049 894.811 343.947 894.811 349.923V385.876C894.811 391.852 899.811 396.75 905.911 396.75H942.61Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_174'
                        opacity='0.0784314'
                        d='M942.51 396.652C948.61 396.652 953.61 391.754 953.61 385.778V349.825C953.61 343.849 948.61 338.951 942.51 338.951H905.81C899.71 338.951 894.71 343.849 894.71 349.825V385.778C894.71 391.754 899.71 396.652 905.81 396.652H942.51Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_175'
                        opacity='0.0980392'
                        d='M942.41 396.555C948.51 396.555 953.51 391.656 953.51 385.68V349.728C953.51 343.752 948.51 338.854 942.41 338.854H905.709C899.609 338.854 894.609 343.752 894.609 349.728V385.68C894.609 391.656 899.609 396.555 905.709 396.555H942.41Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_176'
                        opacity='0.1176'
                        d='M942.41 396.455C948.51 396.455 953.51 391.557 953.51 385.581V349.628C953.51 343.652 948.51 338.754 942.41 338.754H905.709C899.609 338.754 894.609 343.652 894.609 349.628V385.581C894.609 391.557 899.609 396.455 905.709 396.455H942.41Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_177'
                        opacity='0.1373'
                        d='M942.31 396.357C948.41 396.357 953.41 391.459 953.41 385.483V349.53C953.41 343.555 948.41 338.656 942.31 338.656H905.609C899.509 338.656 894.51 343.555 894.51 349.53V385.483C894.51 391.459 899.509 396.357 905.609 396.357H942.31Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_178'
                        opacity='0.1569'
                        d='M942.21 396.357C948.31 396.357 953.31 391.459 953.31 385.483V349.53C953.31 343.555 948.31 338.656 942.21 338.656H905.51C899.41 338.656 894.41 343.555 894.41 349.53V385.483C894.41 391.459 899.41 396.357 905.51 396.357H942.21Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_179'
                        opacity='0.1765'
                        d='M942.11 396.26C948.21 396.26 953.21 391.361 953.21 385.386V349.433C953.21 343.457 948.21 338.559 942.11 338.559H905.411C899.311 338.559 894.311 343.457 894.311 349.433V385.386C894.311 391.361 899.311 396.26 905.411 396.26H942.11Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_180'
                        opacity='0.1961'
                        d='M942.01 396.162C948.11 396.162 953.11 391.264 953.11 385.288V349.335C953.11 343.359 948.11 338.461 942.01 338.461H905.31C899.21 338.461 894.21 343.359 894.21 349.335V385.288C894.21 391.264 899.21 396.162 905.31 396.162H942.01Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_181'
                        opacity='0.2157'
                        d='M941.91 396.064C948.01 396.064 953.01 391.166 953.01 385.19V349.237C953.01 343.262 948.01 338.363 941.91 338.363H905.209C899.109 338.363 894.109 343.262 894.109 349.237V385.19C894.109 391.166 899.109 396.064 905.209 396.064H941.91Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_182'
                        opacity='0.2353'
                        d='M941.81 395.967C947.91 395.967 952.91 391.068 952.91 385.093V349.14C952.91 343.164 947.91 338.266 941.81 338.266H905.109C899.009 338.266 894.01 343.164 894.01 349.14V385.093C894.01 391.068 899.009 395.967 905.109 395.967H941.81Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_183'
                        opacity='0.2549'
                        d='M941.71 395.867C947.81 395.867 952.81 390.969 952.81 384.993V349.04C952.81 343.064 947.81 338.166 941.71 338.166H905.01C898.91 338.166 893.91 343.064 893.91 349.04V384.993C893.91 390.969 898.91 395.867 905.01 395.867H941.71Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_184'
                        opacity='0.2745'
                        d='M941.61 395.769C947.71 395.769 952.71 390.871 952.71 384.895V348.942C952.71 342.967 947.71 338.068 941.61 338.068H904.911C898.811 338.068 893.811 342.967 893.811 348.942V384.895C893.811 390.871 898.811 395.769 904.911 395.769H941.61Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_185'
                        opacity='0.2941'
                        d='M941.51 395.672C947.61 395.672 952.61 390.773 952.61 384.798V348.845C952.61 342.869 947.61 337.971 941.51 337.971H904.81C898.71 337.971 893.71 342.869 893.71 348.845V384.798C893.71 390.773 898.71 395.672 904.81 395.672H941.51Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_186'
                        opacity='0.3137'
                        d='M941.41 395.574C947.51 395.574 952.51 390.676 952.51 384.7V348.747C952.51 342.771 947.51 337.873 941.41 337.873H904.709C898.609 337.873 893.609 342.771 893.609 348.747V384.7C893.609 390.676 898.609 395.574 904.709 395.574H941.41Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_187'
                        opacity='0.3333'
                        d='M941.31 395.476C947.41 395.476 952.41 390.578 952.41 384.602V348.649C952.41 342.674 947.41 337.775 941.31 337.775H904.609C898.509 337.775 893.51 342.674 893.51 348.649V384.602C893.51 390.578 898.509 395.476 904.609 395.476H941.31Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_188'
                        opacity='0.3529'
                        d='M941.21 395.379C947.31 395.379 952.31 390.481 952.31 384.505V348.552C952.31 342.576 947.31 337.678 941.21 337.678H904.51C898.41 337.678 893.41 342.576 893.41 348.552V384.505C893.41 390.481 898.41 395.379 904.51 395.379H941.21Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_189'
                        opacity='0.3725'
                        d='M941.11 395.281C947.21 395.281 952.21 390.383 952.21 384.407V348.454C952.21 342.478 947.21 337.58 941.11 337.58H904.411C898.311 337.58 893.311 342.478 893.311 348.454V384.407C893.311 390.383 898.311 395.281 904.411 395.281H941.11Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_190'
                        opacity='0.3922'
                        d='M941.11 395.181C947.21 395.181 952.21 390.283 952.21 384.307V348.354C952.21 342.379 947.21 337.48 941.11 337.48H904.31C898.21 337.48 893.21 342.379 893.21 348.354V384.307C893.21 390.283 898.21 395.181 904.31 395.181H941.11Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_191'
                        opacity='0.4118'
                        d='M941.01 395.181C947.11 395.181 952.11 390.283 952.11 384.307V348.354C952.11 342.379 947.11 337.48 941.01 337.48H904.31C898.21 337.48 893.21 342.379 893.21 348.354V384.307C893.21 390.283 898.21 395.181 904.31 395.181H941.01Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_192'
                        opacity='0.4314'
                        d='M940.91 395.084C947.01 395.084 952.01 390.186 952.01 384.21V348.257C952.01 342.281 947.01 337.383 940.91 337.383H904.209C898.109 337.383 893.109 342.281 893.109 348.257V384.21C893.109 390.186 898.109 395.084 904.209 395.084H940.91Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_193'
                        opacity='0.451'
                        d='M940.81 394.986C946.91 394.986 951.91 390.088 951.91 384.112V348.159C951.91 342.183 946.91 337.285 940.81 337.285H904.109C898.009 337.285 893.01 342.183 893.01 348.159V384.112C893.01 390.088 898.009 394.986 904.109 394.986H940.81Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_194'
                        opacity='0.4706'
                        d='M940.71 394.888C946.81 394.888 951.81 389.99 951.81 384.014V348.062C951.81 342.086 946.81 337.188 940.71 337.188H904.01C897.91 337.188 892.91 342.086 892.91 348.062V384.014C892.91 389.99 897.91 394.888 904.01 394.888H940.71Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_195'
                        opacity='0.4902'
                        d='M940.61 394.791C946.71 394.791 951.71 389.893 951.71 383.917V347.964C951.71 341.988 946.71 337.09 940.61 337.09H903.911C897.811 337.09 892.811 341.988 892.811 347.964V383.917C892.811 389.893 897.811 394.791 903.911 394.791H940.61Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_196'
                        opacity='0.5098'
                        d='M940.51 394.693C946.61 394.693 951.61 389.795 951.61 383.819V347.866C951.61 341.89 946.61 336.992 940.51 336.992H903.81C897.71 336.992 892.71 341.89 892.71 347.866V383.819C892.71 389.795 897.71 394.693 903.81 394.693H940.51Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_197'
                        opacity='0.5294'
                        d='M940.41 394.594C946.51 394.594 951.51 389.695 951.51 383.719V347.767C951.51 341.791 946.51 336.893 940.41 336.893H903.709C897.609 336.893 892.609 341.791 892.609 347.767V383.719C892.609 389.695 897.609 394.594 903.709 394.594H940.41Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_198'
                        opacity='0.549'
                        d='M940.31 394.496C946.41 394.496 951.41 389.598 951.41 383.622V347.669C951.41 341.693 946.41 336.795 940.31 336.795H903.609C897.509 336.795 892.51 341.693 892.51 347.669V383.622C892.51 389.598 897.509 394.496 903.609 394.496H940.31Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_199'
                        opacity='0.5686'
                        d='M940.21 394.398C946.31 394.398 951.31 389.5 951.31 383.524V347.571C951.31 341.596 946.31 336.697 940.21 336.697H903.51C897.41 336.697 892.41 341.596 892.41 347.571V383.524C892.41 389.5 897.41 394.398 903.51 394.398H940.21Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_200'
                        opacity='0.5882'
                        d='M940.11 394.301C946.21 394.301 951.21 389.402 951.21 383.427V347.474C951.21 341.498 946.21 336.6 940.11 336.6H903.411C897.311 336.6 892.311 341.498 892.311 347.474V383.427C892.311 389.402 897.311 394.301 903.411 394.301H940.11Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_201'
                        opacity='0.6078'
                        d='M940.01 394.203C946.11 394.203 951.11 389.305 951.11 383.329V347.376C951.11 341.4 946.11 336.502 940.01 336.502H903.31C897.21 336.502 892.21 341.4 892.21 347.376V383.329C892.21 389.305 897.21 394.203 903.31 394.203H940.01Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_202'
                        opacity='0.6275'
                        d='M939.91 394.105C946.01 394.105 951.01 389.207 951.01 383.231V347.278C951.01 341.303 946.01 336.404 939.91 336.404H903.209C897.109 336.404 892.109 341.303 892.109 347.278V383.231C892.109 389.207 897.109 394.105 903.209 394.105H939.91Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_203'
                        opacity='0.6471'
                        d='M939.91 394.008C946.01 394.008 951.01 389.109 951.01 383.134V347.181C951.01 341.205 946.01 336.307 939.91 336.307H903.209C897.109 336.307 892.109 341.205 892.109 347.181V383.134C892.109 389.109 897.109 394.008 903.209 394.008H939.91Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_204'
                        opacity='0.6667'
                        d='M939.81 394.008C945.91 394.008 950.91 389.109 950.91 383.134V347.181C950.91 341.205 945.91 336.307 939.81 336.307H903.109C897.009 336.307 892.01 341.205 892.01 347.181V383.134C892.01 389.109 897.009 394.008 903.109 394.008H939.81Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_205'
                        opacity='0.6863'
                        d='M939.71 393.908C945.81 393.908 950.81 389.01 950.81 383.034V347.081C950.81 341.105 945.81 336.207 939.71 336.207H903.01C896.91 336.207 891.91 341.105 891.91 347.081V383.034C891.91 389.01 896.91 393.908 903.01 393.908H939.71Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_206'
                        opacity='0.7059'
                        d='M939.61 393.81C945.71 393.81 950.71 388.912 950.71 382.936V346.983C950.71 341.008 945.71 336.109 939.61 336.109H902.911C896.811 336.109 891.811 341.008 891.811 346.983V382.936C891.811 388.912 896.811 393.81 902.911 393.81H939.61Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_207'
                        opacity='0.7255'
                        d='M939.51 393.713C945.61 393.713 950.61 388.814 950.61 382.839V346.886C950.61 340.91 945.61 336.012 939.51 336.012H902.81C896.71 336.012 891.71 340.91 891.71 346.886V382.839C891.71 388.814 896.71 393.713 902.81 393.713H939.51Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_208'
                        opacity='0.7451'
                        d='M939.41 393.615C945.51 393.615 950.51 388.717 950.51 382.741V346.788C950.51 340.812 945.51 335.914 939.41 335.914H902.709C896.609 335.914 891.609 340.812 891.609 346.788V382.741C891.609 388.717 896.609 393.615 902.709 393.615H939.41Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_209'
                        opacity='0.7647'
                        d='M939.31 393.517C945.41 393.517 950.41 388.619 950.41 382.643V346.69C950.41 340.715 945.41 335.816 939.31 335.816H902.609C896.509 335.816 891.51 340.715 891.51 346.69V382.643C891.51 388.619 896.509 393.517 902.609 393.517H939.31Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_210'
                        opacity='0.7843'
                        d='M939.21 393.42C945.31 393.42 950.31 388.522 950.31 382.546V346.593C950.31 340.617 945.31 335.719 939.21 335.719H902.51C896.41 335.719 891.41 340.617 891.41 346.593V382.546C891.41 388.522 896.41 393.42 902.51 393.42H939.21Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_211'
                        opacity='0.8039'
                        d='M939.11 393.32C945.21 393.32 950.21 388.422 950.21 382.446V346.493C950.21 340.517 945.21 335.619 939.11 335.619H902.411C896.311 335.619 891.311 340.517 891.311 346.493V382.446C891.311 388.422 896.311 393.32 902.411 393.32H939.11Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_212'
                        opacity='0.8235'
                        d='M939.01 393.222C945.11 393.222 950.11 388.324 950.11 382.348V346.395C950.11 340.42 945.11 335.521 939.01 335.521H902.31C896.21 335.521 891.21 340.42 891.21 346.395V382.348C891.21 388.324 896.21 393.222 902.31 393.222H939.01Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_213'
                        opacity='0.8431'
                        d='M938.91 393.125C945.01 393.125 950.01 388.227 950.01 382.251V346.298C950.01 340.322 945.01 335.424 938.91 335.424H902.209C896.109 335.424 891.109 340.322 891.109 346.298V382.251C891.109 388.227 896.109 393.125 902.209 393.125H938.91Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_214'
                        opacity='0.8627'
                        d='M938.81 393.027C944.91 393.027 949.91 388.129 949.91 382.153V346.2C949.91 340.224 944.91 335.326 938.81 335.326H902.109C896.009 335.326 891.01 340.224 891.01 346.2V382.153C891.01 388.129 896.009 393.027 902.109 393.027H938.81Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_215'
                        opacity='0.8824'
                        d='M938.71 392.93C944.81 392.93 949.81 388.031 949.81 382.055V346.103C949.81 340.127 944.81 335.229 938.71 335.229H902.01C895.91 335.229 890.91 340.127 890.91 346.103V382.153C890.91 388.129 895.91 393.027 902.01 393.027L938.71 392.93Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_216'
                        opacity='0.902'
                        d='M938.71 392.832C944.81 392.832 949.81 387.934 949.81 381.958V346.005C949.81 340.029 944.81 335.131 938.71 335.131H902.01C895.91 335.131 890.91 340.029 890.91 346.005V381.958C890.91 387.934 895.91 392.832 902.01 392.832H938.71Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_217'
                        opacity='0.9216'
                        d='M938.61 392.734C944.71 392.734 949.71 387.836 949.71 381.86V345.907C949.71 339.931 944.71 335.033 938.61 335.033H901.911C895.811 335.033 890.811 339.931 890.811 345.907V381.86C890.811 387.836 895.811 392.734 901.911 392.734H938.61Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_218'
                        opacity='0.9412'
                        d='M938.51 392.734C944.61 392.734 949.61 387.836 949.61 381.86V345.907C949.61 339.931 944.61 335.033 938.51 335.033H901.81C895.71 335.033 890.71 339.931 890.71 345.907V381.86C890.71 387.836 895.71 392.734 901.81 392.734H938.51Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_219'
                        opacity='0.9608'
                        d='M938.41 392.635C944.51 392.635 949.51 387.736 949.51 381.761V345.808C949.51 339.832 944.51 334.934 938.41 334.934H901.709C895.609 334.934 890.609 339.832 890.609 345.808V381.761C890.609 387.736 895.609 392.635 901.709 392.635H938.41Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_220'
                        opacity='0.9804'
                        d='M938.31 392.537C944.41 392.537 949.41 387.639 949.41 381.663V345.71C949.41 339.734 944.41 334.836 938.31 334.836H901.609C895.509 334.836 890.51 339.734 890.51 345.71V381.663C890.51 387.639 895.509 392.537 901.609 392.537H938.31Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_221'
                        d='M938.21 392.439C944.31 392.439 949.31 387.541 949.31 381.565V345.612C949.31 339.637 944.31 334.738 938.21 334.738H901.51C895.41 334.738 890.41 339.637 890.41 345.612V381.565C890.41 387.541 895.41 392.439 901.51 392.439H938.21Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_222'
                      d='M937.311 391.362C943.311 391.362 948.21 386.562 948.21 380.684V345.319C948.21 339.441 943.311 334.641 937.311 334.641H901.21C895.21 334.641 890.311 339.441 890.311 345.319V380.684C890.311 386.562 895.21 391.362 901.21 391.362H937.311Z'
                      fill='url(#paint3_linear_413_2)'
                    />
                    <path
                      id='Vector_223'
                      d='M937.311 390.873C943.011 390.873 947.71 386.268 947.71 380.684V345.319C947.71 339.735 943.011 335.131 937.311 335.131H901.21C895.51 335.131 890.811 339.735 890.811 345.319V380.684C890.811 386.268 895.51 390.873 901.21 390.873H937.311Z'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 4'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3614'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='897.592' y='358.712'>
                        {task[3].name && task[3].name.length > 3
                          ? truncateText(task[3].name, 4)
                          : task[3].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_224'
                      d='M932.709 381.369H907.01C903.41 381.369 900.51 378.528 900.51 375.001V372.944C900.51 369.417 903.41 366.576 907.01 366.576H932.709C936.309 366.576 939.209 369.417 939.209 372.944V375.001C939.209 378.528 936.309 381.369 932.709 381.369Z'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_4'
                      fill={task[3].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3287'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='910.406' y='379.471'>
                        {/* {task[3].counter + '/3'} */}
                        {(task[3]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[3]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[2] !== undefined && task[2] !== null && (
              <g id='task-3'>
                <g id='rod-3'>
                  <g id='Rectangle_6-5_00000060030977780295919880000014866018408325835415_'>
                    <g id='Group_75'>
                      <path
                        id='Vector_225'
                        d='M694.91 504.512C691.61 504.61 688.91 502.062 688.81 498.928L676.91 129.994C676.81 126.761 679.41 124.116 682.61 124.018C685.91 123.92 688.61 126.467 688.71 129.602L700.61 498.536C700.71 501.769 698.11 504.414 694.91 504.512Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_76'>
                      <path
                        id='Vector_226'
                        d='M694.91 504.512C691.61 504.61 688.91 502.062 688.81 498.928L676.91 129.994C676.81 126.761 679.41 124.116 682.61 124.018C685.91 123.92 688.61 126.467 688.71 129.602L700.61 498.536C700.71 501.769 698.11 504.414 694.91 504.512Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_77'>
                    <path
                      id='Rectangle_6-6_00000006679321025390005320000001705265803602120068_'
                      d='M694.9 504.57C691.6 504.668 688.9 502.121 688.8 498.986L676.9 130.053C676.8 126.82 679.4 124.175 682.6 124.077C685.9 123.979 688.6 126.526 688.7 129.661L700.6 498.594C700.7 501.729 698.1 504.472 694.9 504.57Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-3'>
                  <g id='rods_5'>
                    <g id='Group_78'>
                      <path
                        id='Rectangle_8-13_00000057855270260520427540000013606950899286229413_'
                        d='M671.01 104.229C668.81 106.58 665.01 106.678 662.71 104.523L613.71 59.4594C611.31 57.3041 611.21 53.5815 613.41 51.3283C615.61 48.9772 619.41 48.8792 621.71 51.0344L670.71 96.098C673.11 98.2532 673.21 101.878 671.01 104.229Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_79'>
                        <path
                          id='Rectangle_8-14_00000159457924151845238360000007120163770822621573_'
                          d='M671 104.288C668.8 106.639 665 106.737 662.7 104.582L613.7 59.5179C611.3 57.3627 611.201 53.6401 613.401 51.3869C615.601 49.0358 619.4 48.9378 621.7 51.093L670.7 96.1566C673.1 98.3118 673.2 101.936 671 104.288Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_80'>
                      <path
                        id='Rectangle_9-13_00000101813857829041363980000008177488510529116296_'
                        d='M689.81 103.152C687.41 100.997 687.21 97.3718 689.41 95.0207L734.51 46.3325C736.71 43.9813 740.41 43.7854 742.81 45.9406C745.21 48.0958 745.41 51.7205 743.21 54.0716L698.11 102.76C695.91 105.209 692.21 105.307 689.81 103.152Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_81'>
                        <path
                          id='Rectangle_9-14_00000017497894450163935510000012190075936686124466_'
                          d='M689.8 103.21C687.4 101.055 687.201 97.4304 689.401 95.0793L734.5 46.3911C736.7 44.0399 740.4 43.844 742.8 45.9992C745.2 48.1544 745.401 51.7791 743.201 54.1302L698.101 102.818C695.901 105.17 692.2 105.366 689.8 103.21Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_5'>
                    <g id='Group_82'>
                      <path
                        id='Ellipse_21-13_00000057861771918092248410000016459272106066709925_'
                        d='M605.01 62.7891C615.172 62.7891 623.41 54.7189 623.41 44.7637C623.41 34.8085 615.172 26.7383 605.01 26.7383C594.848 26.7383 586.609 34.8085 586.609 44.7637C586.609 54.7189 594.848 62.7891 605.01 62.7891Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_83'>
                        <path
                          id='Ellipse_21-14_00000023261173173128242050000006560967706208503978_'
                          d='M605 62.7501C615.162 62.7501 623.4 54.6798 623.4 44.7246C623.4 34.7695 615.162 26.6992 605 26.6992C594.838 26.6992 586.601 34.7695 586.601 44.7246C586.601 54.6798 594.838 62.7501 605 62.7501Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13_00000152262050912858540640000012311373196565658001_'
                      d='M598.11 57.7915L613.51 57.0078C615.91 56.9098 618.01 58.6732 618.11 61.1223C618.21 63.4734 616.41 65.5307 613.91 65.6286L598.51 66.4124C596.11 66.5103 594.01 64.747 593.91 62.2979C593.81 59.8488 595.71 57.8895 598.11 57.7915Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_13'
                      transform='matrix(0.998848 -0.047995 0.0500055 0.99875 597.42 57.2852)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_84' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13_00000124872484692447591710000015471190927983754142_'
                        d='M605 57.1662C612.014 57.1662 617.7 51.5959 617.7 44.7247C617.7 37.8534 612.014 32.2832 605 32.2832C597.986 32.2832 592.3 37.8534 592.3 44.7247C592.3 51.5959 597.986 57.1662 605 57.1662Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_5'>
                    <g id='Group_85'>
                      <path
                        id='Ellipse_18-13_00000132088260503076729510000015448257774863493533_'
                        d='M751.01 55.0509C761.172 55.0509 769.41 46.9806 769.41 37.0255C769.41 27.0703 761.172 19 751.01 19C740.848 19 732.609 27.0703 732.609 37.0255C732.609 46.9806 740.848 55.0509 751.01 55.0509Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_86'>
                        <path
                          id='Ellipse_18-14_00000170272750750057194240000011750681093706802622_'
                          d='M751 55.1095C761.162 55.1095 769.4 47.0392 769.4 37.084C769.4 27.1289 761.162 19.0586 751 19.0586C740.838 19.0586 732.601 27.1289 732.601 37.084C732.601 47.0392 740.838 55.1095 751 55.1095Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14_00000182491150865204692430000004725532193462426272_'
                      d='M743.511 50.7407L758.911 49.957C761.311 49.859 763.411 51.6224 763.511 54.0715C763.611 56.4227 761.81 58.4799 759.31 58.5779L743.911 59.3616C741.511 59.4596 739.41 57.6962 739.31 55.2471C739.21 52.8959 741.111 50.8387 743.511 50.7407Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_14'
                      transform='matrix(0.998848 -0.047995 0.0500055 0.99875 742.827 50.3008)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_87' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13_00000006670171349865594260000010218498617972452230_'
                        d='M751 49.4278C758.014 49.4278 763.7 43.8576 763.7 36.9864C763.7 30.1152 758.014 24.5449 751 24.5449C743.986 24.5449 738.3 30.1152 738.3 36.9864C738.3 43.8576 743.986 49.4278 751 49.4278Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_5'>
                    <g id='Group_88'>
                      <path
                        id='Ellipse_16-13_00000023276895485778373370000006083644766410433666_'
                        d='M681.21 130.484C691.372 130.484 699.61 122.414 699.61 112.459C699.61 102.504 691.372 94.4336 681.21 94.4336C671.048 94.4336 662.811 102.504 662.811 112.459C662.811 122.414 671.048 130.484 681.21 130.484Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_89'>
                        <path
                          id='Ellipse_16-14_00000173139559965437269940000013598561673875840944_'
                          d='M681.2 130.445C691.362 130.445 699.601 122.375 699.601 112.42C699.601 102.465 691.362 94.3945 681.2 94.3945C671.038 94.3945 662.8 102.465 662.8 112.42C662.8 122.375 671.038 130.445 681.2 130.445Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15_00000149361666956732597380000013634318138154949007_'
                      d='M674.61 127.448L690.01 126.664C692.41 126.566 694.51 128.329 694.61 130.779C694.71 133.13 692.91 135.187 690.41 135.285L675.01 136.069C672.61 136.167 670.51 134.403 670.41 131.954C670.31 129.603 672.21 127.546 674.61 127.448Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_15'
                      transform='matrix(0.998848 -0.047995 0.0500055 0.99875 675.243 127.852)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_90' opacity={task[2].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13_00000046320239497582283260000017783526869035373979_'
                        d='M681.2 124.861C688.214 124.861 693.9 119.291 693.9 112.42C693.9 105.549 688.214 99.9785 681.2 99.9785C674.186 99.9785 668.5 105.549 668.5 112.42C668.5 119.291 674.186 124.861 681.2 124.861Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[2].TasksAndStudents?.id || 0)}>
                  <g id='box-task-3'>
                    <g id='Group_91'>
                      <path
                        id='Vector_227'
                        opacity='0.0196078'
                        d='M712.311 307.212C718.411 306.918 723.111 301.824 722.811 295.848L721.011 259.895C720.711 253.919 715.511 249.315 709.411 249.609L672.711 251.372C666.611 251.666 661.911 256.76 662.211 262.736L664.011 298.689C664.311 304.665 669.511 309.269 675.611 308.975L712.311 307.212Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_228'
                        opacity='0.0392157'
                        d='M712.21 307.114C718.31 306.821 723.01 301.726 722.71 295.751L720.911 259.798C720.611 253.822 715.41 249.217 709.31 249.511L672.61 251.275C666.51 251.569 661.81 256.663 662.11 262.639L663.911 298.592C664.211 304.567 669.411 309.172 675.511 308.878L712.21 307.114Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_229'
                        opacity='0.0588235'
                        d='M712.11 307.015C718.21 306.721 722.91 301.627 722.61 295.651L720.81 259.698C720.51 253.722 715.31 249.118 709.21 249.412L672.511 251.175C666.411 251.469 661.711 256.563 662.011 262.539L663.81 298.492C664.11 304.468 669.311 309.072 675.411 308.778L712.11 307.015Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_230'
                        opacity='0.0784314'
                        d='M712.01 306.917C718.11 306.623 722.81 301.529 722.51 295.553L720.71 259.6C720.41 253.625 715.21 249.02 709.11 249.314L672.41 251.077C666.31 251.371 661.61 256.465 661.91 262.441L663.71 298.394C664.01 304.37 669.21 308.974 675.31 308.68L712.01 306.917Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_231'
                        opacity='0.0980392'
                        d='M711.911 306.819C718.011 306.526 722.711 301.431 722.411 295.456L720.61 259.503C720.31 253.527 715.111 248.923 709.011 249.216L672.31 250.98C666.21 251.274 661.51 256.368 661.81 262.344L663.61 298.297C663.91 304.272 669.11 308.877 675.21 308.583L711.911 306.819Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_232'
                        opacity='0.1176'
                        d='M711.811 306.722C717.911 306.428 722.611 301.334 722.311 295.358L720.511 259.405C720.211 253.429 715.011 248.825 708.911 249.119L672.211 250.882C666.111 251.176 661.411 256.27 661.711 262.246L663.511 298.199C663.811 304.175 669.011 308.779 675.111 308.485L711.811 306.722Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_233'
                        opacity='0.1373'
                        d='M711.71 306.624C717.81 306.33 722.51 301.236 722.21 295.26L720.411 259.307C720.111 253.332 714.91 248.727 708.81 249.021L672.11 250.785C666.01 251.078 661.31 256.173 661.61 262.148L663.411 298.101C663.711 304.077 668.911 308.681 675.011 308.388L711.71 306.624Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_234'
                        opacity='0.1569'
                        d='M711.61 306.624C717.71 306.33 722.41 301.236 722.11 295.26L720.31 259.307C720.01 253.332 714.81 248.727 708.71 249.021L672.011 250.785C665.911 251.078 661.211 256.173 661.511 262.148L663.31 298.101C663.61 304.077 668.811 308.681 674.911 308.388L711.61 306.624Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_235'
                        opacity='0.1765'
                        d='M711.51 306.526C717.61 306.233 722.31 301.138 722.01 295.163L720.21 259.21C719.91 253.234 714.71 248.63 708.61 248.923L671.91 250.687C665.81 250.981 661.11 256.075 661.41 262.051L663.21 298.004C663.51 303.979 668.71 308.584 674.81 308.29L711.51 306.526Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_236'
                        opacity='0.1961'
                        d='M711.411 306.427C717.511 306.133 722.211 301.039 721.911 295.063L720.11 259.11C719.81 253.134 714.611 248.53 708.511 248.824L671.81 250.587C665.71 250.881 661.01 255.975 661.31 261.951L663.11 297.904C663.41 303.88 668.61 308.484 674.71 308.19L711.411 306.427Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_237'
                        opacity='0.2157'
                        d='M711.311 306.329C717.411 306.035 722.111 300.941 721.811 294.965L720.011 259.012C719.711 253.037 714.511 248.432 708.411 248.726L671.711 250.49C665.611 250.783 660.911 255.878 661.211 261.853L663.011 297.806C663.311 303.782 668.511 308.387 674.611 308.093L711.311 306.329Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_238'
                        opacity='0.2353'
                        d='M711.21 306.232C717.31 305.938 722.01 300.844 721.71 294.868L719.911 258.915C719.611 252.939 714.41 248.335 708.31 248.629L671.61 250.392C665.51 250.686 660.81 255.78 661.11 261.756L662.911 297.709C663.211 303.684 668.411 308.289 674.511 307.995L711.21 306.232Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_239'
                        opacity='0.2549'
                        d='M711.11 306.134C717.21 305.84 721.91 300.746 721.61 294.77L719.81 258.817C719.51 252.841 714.31 248.237 708.21 248.531L671.511 250.294C665.411 250.588 660.711 255.682 661.011 261.658L662.81 297.611C663.11 303.587 668.311 308.191 674.411 307.897L711.11 306.134Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_240'
                        opacity='0.2745'
                        d='M711.01 306.036C717.11 305.742 721.81 300.648 721.51 294.672L719.71 258.719C719.41 252.744 714.21 248.139 708.11 248.433L671.41 250.197C665.31 250.49 660.61 255.585 660.91 261.56L662.71 297.513C663.01 303.489 668.21 308.093 674.31 307.8L711.01 306.036Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_241'
                        opacity='0.2941'
                        d='M710.911 305.939C717.011 305.645 721.711 300.551 721.411 294.575L719.61 258.622C719.31 252.646 714.111 248.042 708.011 248.336L671.31 250.099C665.21 250.393 660.51 255.487 660.81 261.463L662.61 297.416C662.91 303.391 668.11 307.996 674.21 307.702L710.911 305.939Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_242'
                        opacity='0.3137'
                        d='M710.811 305.841C716.911 305.547 721.611 300.453 721.311 294.477L719.511 258.524C719.211 252.548 714.011 247.944 707.911 248.238L671.211 250.001C665.111 250.295 660.411 255.389 660.711 261.365L662.511 297.318C662.811 303.294 668.011 307.898 674.111 307.604L710.811 305.841Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_243'
                        opacity='0.3333'
                        d='M710.71 305.841C716.81 305.547 721.51 300.453 721.21 294.477L719.411 258.524C719.111 252.548 713.91 247.944 707.81 248.238L671.11 250.001C665.01 250.295 660.31 255.389 660.61 261.365L662.411 297.318C662.711 303.294 667.911 307.898 674.011 307.604L710.71 305.841Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_244'
                        opacity='0.3529'
                        d='M710.61 305.741C716.71 305.447 721.41 300.353 721.11 294.377L719.31 258.425C719.01 252.449 713.81 247.844 707.71 248.138L671.011 249.902C664.911 250.196 660.211 255.29 660.511 261.265L662.31 297.218C662.61 303.194 667.811 307.799 673.911 307.505L710.61 305.741Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_245'
                        opacity='0.3725'
                        d='M710.51 305.644C716.61 305.35 721.31 300.256 721.01 294.28L719.21 258.327C718.91 252.351 713.71 247.747 707.61 248.041L670.91 249.804C664.81 250.098 660.11 255.192 660.41 261.168L662.21 297.121C662.51 303.097 667.71 307.701 673.81 307.407L710.51 305.644Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_246'
                        opacity='0.3922'
                        d='M710.411 305.546C716.511 305.252 721.211 300.158 720.911 294.182L719.11 258.229C718.81 252.253 713.611 247.649 707.511 247.943L670.81 249.706C664.71 250 660.01 255.094 660.31 261.07L662.11 297.023C662.41 302.999 667.61 307.603 673.71 307.309L710.411 305.546Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_247'
                        opacity='0.4118'
                        d='M710.311 305.448C716.411 305.154 721.111 300.06 720.811 294.084L719.011 258.132C718.711 252.156 713.511 247.551 707.411 247.845L670.711 249.609C664.611 249.903 659.911 254.997 660.211 260.973L662.011 296.925C662.311 302.901 667.511 307.506 673.611 307.212L710.311 305.448Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_248'
                        opacity='0.4314'
                        d='M710.21 305.351C716.31 305.057 721.01 299.963 720.71 293.987L718.911 258.034C718.611 252.058 713.41 247.454 707.31 247.748L670.61 249.511C664.51 249.805 659.81 254.899 660.11 260.875L661.911 296.828C662.211 302.804 667.411 307.408 673.511 307.114L710.21 305.351Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_249'
                        opacity='0.451'
                        d='M710.11 305.253C716.21 304.959 720.91 299.865 720.61 293.889L718.81 257.936C718.51 251.96 713.31 247.356 707.21 247.65L670.511 249.413C664.411 249.707 659.711 254.801 660.011 260.777L661.81 296.73C662.11 302.706 667.311 307.31 673.411 307.016L710.11 305.253Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_250'
                        opacity='0.4706'
                        d='M710.11 305.155C716.21 304.862 720.91 299.767 720.61 293.792L718.81 257.839C718.51 251.863 713.31 247.258 707.21 247.552L670.511 249.316C664.411 249.61 659.711 254.704 660.011 260.68L661.81 296.633C662.11 302.608 667.311 307.213 673.411 306.919L710.11 305.155Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_251'
                        opacity='0.4902'
                        d='M710.01 305.155C716.11 304.862 720.81 299.767 720.51 293.792L718.71 257.839C718.41 251.863 713.21 247.258 707.11 247.552L670.41 249.316C664.31 249.61 659.61 254.704 659.91 260.68L661.71 296.633C662.01 302.608 667.21 307.213 673.31 306.919L710.01 305.155Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_252'
                        opacity='0.5098'
                        d='M709.911 305.056C716.011 304.762 720.711 299.668 720.411 293.692L718.61 257.739C718.31 251.763 713.111 247.159 707.011 247.453L670.31 249.216C664.21 249.51 659.51 254.604 659.81 260.58L661.61 296.533C661.91 302.509 667.11 307.113 673.21 306.819L709.911 305.056Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_253'
                        opacity='0.5294'
                        d='M709.811 304.958C715.911 304.664 720.611 299.57 720.311 293.594L718.511 257.641C718.211 251.666 713.011 247.061 706.911 247.355L670.211 249.118C664.111 249.412 659.411 254.506 659.711 260.482L661.511 296.435C661.811 302.411 667.011 307.015 673.111 306.721L709.811 304.958Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_254'
                        opacity='0.549'
                        d='M709.71 304.86C715.81 304.567 720.51 299.472 720.21 293.497L718.411 257.544C718.111 251.568 712.91 246.964 706.81 247.257L670.11 249.021C664.01 249.315 659.31 254.409 659.61 260.385L661.411 296.338C661.711 302.313 666.911 306.918 673.011 306.624L709.71 304.86Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_255'
                        opacity='0.5686'
                        d='M709.61 304.763C715.71 304.469 720.41 299.375 720.11 293.399L718.31 257.446C718.01 251.47 712.81 246.866 706.71 247.16L670.011 248.923C663.911 249.217 659.211 254.311 659.511 260.287L661.31 296.24C661.61 302.216 666.811 306.82 672.911 306.526L709.61 304.763Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_256'
                        opacity='0.5882'
                        d='M709.51 304.665C715.61 304.371 720.31 299.277 720.01 293.301L718.21 257.348C717.91 251.373 712.71 246.768 706.61 247.062L669.91 248.826C663.81 249.119 659.11 254.214 659.41 260.189L661.21 296.142C661.51 302.118 666.71 306.722 672.81 306.429L709.51 304.665Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_257'
                        opacity='0.6078'
                        d='M709.411 304.567C715.511 304.274 720.211 299.179 719.911 293.204L718.11 257.251C717.81 251.275 712.611 246.671 706.511 246.964L669.81 248.728C663.71 249.022 659.01 254.116 659.31 260.092L661.11 296.045C661.41 302.02 666.61 306.625 672.71 306.331L709.411 304.567Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_258'
                        opacity='0.6275'
                        d='M709.311 304.468C715.411 304.174 720.111 299.08 719.811 293.104L718.011 257.151C717.711 251.175 712.511 246.571 706.411 246.865L669.711 248.628C663.611 248.922 658.911 254.016 659.211 259.992L661.011 295.945C661.311 301.921 666.511 306.525 672.611 306.231L709.311 304.468Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_259'
                        opacity='0.6471'
                        d='M709.21 304.47C715.31 304.176 720.01 299.082 719.71 293.106L717.911 257.153C717.611 251.177 712.41 246.573 706.31 246.867L669.61 248.63C663.51 248.924 658.81 254.018 659.11 259.994L660.911 295.947C661.211 301.923 666.411 306.527 672.511 306.233L709.21 304.47Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_260'
                        opacity='0.6667'
                        d='M709.11 304.37C715.21 304.076 719.91 298.982 719.61 293.006L717.81 257.053C717.51 251.078 712.31 246.473 706.21 246.767L669.511 248.531C663.411 248.824 658.711 253.919 659.011 259.894L660.81 295.847C661.11 301.823 666.311 306.428 672.411 306.134L709.11 304.37Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_261'
                        opacity='0.6863'
                        d='M709.01 304.273C715.11 303.979 719.81 298.885 719.51 292.909L717.71 256.956C717.41 250.98 712.21 246.376 706.11 246.67L669.41 248.433C663.31 248.727 658.61 253.821 658.91 259.797L660.71 295.75C661.01 301.725 666.21 306.33 672.31 306.036L709.01 304.273Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_262'
                        opacity='0.7059'
                        d='M708.911 304.175C715.011 303.881 719.711 298.787 719.411 292.811L717.61 256.858C717.31 250.882 712.111 246.278 706.011 246.572L669.31 248.335C663.21 248.629 658.51 253.723 658.81 259.699L660.61 295.652C660.91 301.628 666.11 306.232 672.21 305.938L708.911 304.175Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_263'
                        opacity='0.7255'
                        d='M708.811 304.077C714.911 303.783 719.611 298.689 719.311 292.713L717.511 256.761C717.211 250.785 712.011 246.18 705.911 246.474L669.211 248.238C663.111 248.531 658.411 253.626 658.711 259.601L660.511 295.554C660.811 301.53 666.011 306.134 672.111 305.841L708.811 304.077Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_264'
                        opacity='0.7451'
                        d='M708.71 303.98C714.81 303.686 719.51 298.592 719.21 292.616L717.411 256.663C717.111 250.687 711.91 246.083 705.81 246.377L669.11 248.14C663.01 248.434 658.31 253.528 658.61 259.504L660.411 295.457C660.711 301.433 665.911 306.037 672.011 305.743L708.71 303.98Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_265'
                        opacity='0.7647'
                        d='M708.61 303.882C714.71 303.588 719.41 298.494 719.11 292.518L717.31 256.565C717.01 250.589 711.81 245.985 705.71 246.279L669.011 248.042C662.911 248.336 658.211 253.43 658.511 259.406L660.31 295.359C660.61 301.335 665.811 305.939 671.911 305.645L708.61 303.882Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_266'
                        opacity='0.7843'
                        d='M708.51 303.782C714.61 303.488 719.31 298.394 719.01 292.418L717.21 256.466C716.91 250.49 711.71 245.885 705.61 246.179L668.91 247.943C662.81 248.237 658.11 253.331 658.41 259.307L660.21 295.259C660.51 301.235 665.71 305.84 671.81 305.546L708.51 303.782Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_267'
                        opacity='0.8039'
                        d='M708.411 303.782C714.511 303.488 719.211 298.394 718.911 292.418L717.11 256.466C716.81 250.49 711.611 245.885 705.511 246.179L668.81 247.943C662.71 248.237 658.01 253.331 658.31 259.307L660.11 295.259C660.41 301.235 665.61 305.84 671.71 305.546L708.411 303.782Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_268'
                        opacity='0.8235'
                        d='M708.311 303.685C714.411 303.391 719.111 298.297 718.811 292.321L717.011 256.368C716.711 250.392 711.511 245.788 705.411 246.082L668.711 247.845C662.611 248.139 657.911 253.233 658.211 259.209L660.011 295.162C660.311 301.138 665.511 305.742 671.611 305.448L708.311 303.685Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_269'
                        opacity='0.8431'
                        d='M708.21 303.587C714.31 303.293 719.01 298.199 718.71 292.223L716.911 256.27C716.611 250.294 711.41 245.69 705.31 245.984L668.61 247.747C662.51 248.041 657.81 253.135 658.11 259.111L659.911 295.064C660.211 301.04 665.411 305.644 671.511 305.35L708.21 303.587Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_270'
                        opacity='0.8627'
                        d='M708.11 303.489C714.21 303.195 718.91 298.101 718.61 292.125L716.81 256.173C716.51 250.197 711.31 245.592 705.21 245.886L668.511 247.65C662.411 247.944 657.711 253.038 658.011 259.014L659.81 294.966C660.11 300.942 665.311 305.547 671.411 305.253L708.11 303.489Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_271'
                        opacity='0.8824'
                        d='M708.01 303.392C714.11 303.098 718.81 298.004 718.51 292.028L716.71 256.075C716.41 250.099 711.21 245.495 705.11 245.789L668.41 247.552C662.31 247.846 657.61 252.94 657.91 258.916L659.71 294.869C660.01 300.845 665.21 305.449 671.31 305.155L708.01 303.392Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_272'
                        opacity='0.902'
                        d='M707.911 303.294C714.011 303 718.711 297.906 718.411 291.93L716.61 255.977C716.31 250.001 711.111 245.397 705.011 245.691L668.31 247.454C662.21 247.748 657.51 252.842 657.81 258.818L659.61 294.771C659.91 300.747 665.11 305.351 671.21 305.057L707.911 303.294Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_273'
                        opacity='0.9216'
                        d='M707.811 303.194C713.911 302.901 718.611 297.806 718.311 291.831L716.511 255.878C716.211 249.902 711.011 245.298 704.911 245.591L668.211 247.355C662.111 247.649 657.411 252.743 657.711 258.719L659.511 294.672C659.811 300.647 665.011 305.252 671.111 304.958L707.811 303.194Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_274'
                        opacity='0.9412'
                        d='M707.71 303.097C713.81 302.803 718.51 297.709 718.21 291.733L716.411 255.78C716.111 249.804 710.91 245.2 704.81 245.494L668.11 247.257C662.01 247.551 657.31 252.645 657.61 258.621L659.411 294.574C659.711 300.55 664.911 305.154 671.011 304.86L707.71 303.097Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_275'
                        opacity='0.9608'
                        d='M707.61 303.097C713.71 302.803 718.41 297.709 718.11 291.733L716.31 255.78C716.01 249.804 710.81 245.2 704.71 245.494L668.011 247.257C661.911 247.551 657.211 252.645 657.511 258.621L659.31 294.574C659.61 300.55 664.811 305.154 670.911 304.86L707.61 303.097Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_276'
                        opacity='0.9804'
                        d='M707.51 302.999C713.61 302.705 718.31 297.611 718.01 291.635L716.21 255.682C715.91 249.707 710.71 245.102 704.61 245.396L667.91 247.159C661.81 247.453 657.11 252.547 657.41 258.523L659.21 294.476C659.51 300.452 664.71 305.056 670.81 304.762L707.51 302.999Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_277'
                        d='M707.411 302.901C713.511 302.608 718.211 297.513 717.911 291.538L716.11 255.585C715.81 249.609 710.611 245.005 704.511 245.298L667.81 247.062C661.71 247.356 657.01 252.45 657.31 258.426L659.11 294.379C659.41 300.354 664.61 304.959 670.71 304.665L707.411 302.901Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_278'
                      d='M706.511 301.824C712.511 301.53 717.111 296.534 716.811 290.656L715.011 255.389C714.711 249.511 709.611 245.005 703.611 245.299L667.611 247.062C661.611 247.356 657.011 252.352 657.311 258.23L659.111 293.497C659.411 299.375 664.511 303.881 670.511 303.588L706.511 301.824Z'
                      fill='url(#paint4_linear_413_2)'
                    />
                    <path
                      id='Vector_279'
                      d='M706.511 301.333C712.211 301.039 716.711 296.239 716.411 290.655L714.611 255.388C714.311 249.804 709.411 245.396 703.711 245.69L667.711 247.453C662.011 247.747 657.511 252.547 657.811 258.131L659.611 293.398C659.911 298.982 664.811 303.391 670.511 303.097L706.511 301.333Z'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <path
                      id='Vector_280'
                      d='M701.409 292.125L675.709 293.399C672.109 293.595 669.009 290.852 668.809 287.325L668.709 285.268C668.509 281.741 671.309 278.704 674.909 278.508L700.609 277.234C704.209 277.039 707.31 279.782 707.51 283.308L707.609 285.366C707.809 288.99 705.009 291.929 701.409 292.125Z'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_5'
                      transform='matrix(0.998848 -0.047995 0.0500055 0.99875 678.434 278.824)'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3287'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='12.465'>
                        {/* {task[2].counter + '/3'} */}
                        {(task[2]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[2]?.total_counter || 0)}{' '}
                      </tspan>
                    </text>
                    <text
                      id='Task 3'
                      transform='matrix(0.998848 -0.047995 0.0500055 0.99875 665.552 256.523)'
                      fill={task[2].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3614'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[2].name && task[2].name.length > 3
                          ? truncateText(task[2].name, 4)
                          : task[2].name}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[1] !== undefined && task[1] !== null && (
              <g id='task-2'>
                <g id='rod-2'>
                  <g id='Rectangle_6-5'>
                    <g id='Group_92'>
                      <path
                        id='Vector_281'
                        d='M632.01 533.412C629.71 535.763 626.01 535.763 623.71 533.608L351.81 278.117C349.41 275.864 349.41 272.239 351.61 269.986C353.91 267.635 357.61 267.635 359.91 269.79L631.81 525.281C634.11 527.436 634.21 531.061 632.01 533.412Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_93'>
                      <path
                        id='Vector_282'
                        d='M632.01 533.412C629.71 535.763 626.01 535.763 623.71 533.608L351.81 278.117C349.41 275.864 349.41 272.239 351.61 269.986C353.91 267.635 357.61 267.635 359.91 269.79L631.81 525.281C634.11 527.436 634.21 531.061 632.01 533.412Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_94'>
                    <path
                      id='Rectangle_6-6_00000119804789280231098770000001711808239750979485_'
                      d='M632 533.373C629.7 535.724 626 535.724 623.7 533.569L351.8 278.078C349.5 275.825 349.4 272.2 351.6 269.947C353.9 267.596 357.6 267.596 359.9 269.751L631.8 525.242C634.2 527.495 634.2 531.12 632 533.373Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-2'>
                  <g id='rods_6'>
                    <g id='Group_95'>
                      <path
                        id='Rectangle_8-13'
                        d='M329.11 263.716C329.21 266.949 326.61 269.594 323.31 269.594L256.21 270.965C252.91 271.063 250.31 268.516 250.21 265.283C250.11 262.051 252.71 259.406 256.01 259.406L323.11 258.034C326.31 257.936 329.01 260.483 329.11 263.716Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_96'>
                        <path
                          id='Rectangle_8-14'
                          d='M329.1 263.676C329.2 266.909 326.6 269.554 323.3 269.554L256.2 270.925C252.9 271.023 250.2 268.476 250.2 265.243C250.1 262.011 252.7 259.366 256 259.366L323.1 257.994C326.3 257.994 329 260.541 329.1 263.676Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <g id='Group_97'>
                      <path
                        id='Rectangle_9-13'
                        d='M341.81 250.097C338.51 250.195 335.81 247.746 335.71 244.513L333.11 178.779C333.01 175.547 335.51 172.902 338.81 172.804C342.11 172.706 344.81 175.155 344.91 178.388L347.51 244.122C347.51 247.256 345.01 249.999 341.81 250.097Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_98'>
                        <path
                          id='Rectangle_9-14'
                          d='M341.8 250.058C338.5 250.156 335.8 247.707 335.7 244.474L333.101 178.74C333.001 175.508 335.5 172.863 338.8 172.765C342.1 172.667 344.8 175.116 344.9 178.349L347.5 244.083C347.6 247.315 345 249.96 341.8 250.058Z'
                          fill='white'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-1_6'>
                    <g id='Group_99'>
                      <path
                        id='Ellipse_16-13'
                        d='M342.21 280.566C352.372 280.566 360.611 272.496 360.611 262.541C360.611 252.586 352.372 244.516 342.21 244.516C332.048 244.516 323.811 252.586 323.811 262.541C323.811 272.496 332.048 280.566 342.21 280.566Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_100'>
                        <path
                          id='Ellipse_16-14'
                          d='M342.2 280.527C352.362 280.527 360.601 272.457 360.601 262.502C360.601 252.547 352.362 244.477 342.2 244.477C332.038 244.477 323.8 252.547 323.8 262.502C323.8 272.457 332.038 280.527 342.2 280.527Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_15'
                      d='M348.21 277.823L358.71 266.753C360.31 264.99 363.11 264.892 364.91 266.557C366.71 268.125 366.81 270.868 365.11 272.631L354.61 283.701C353.01 285.464 350.21 285.562 348.41 283.897C346.71 282.232 346.61 279.489 348.21 277.823Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_16'
                      transform='matrix(0.687092 -0.726588 0.740524 0.672049 348.992 277.684)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='6.2224'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='6.67784'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_101' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_17-13'
                        d='M342.2 274.943C349.214 274.943 354.9 269.373 354.9 262.502C354.9 255.631 349.214 250.061 342.2 250.061C335.186 250.061 329.5 255.631 329.5 262.502C329.5 269.373 335.186 274.943 342.2 274.943Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-2_6'>
                    <g id='Group_102'>
                      <path
                        id='Ellipse_21-13'
                        d='M239.41 284.387C249.572 284.387 257.81 276.316 257.81 266.361C257.81 256.406 249.572 248.336 239.41 248.336C229.248 248.336 221.01 256.406 221.01 266.361C221.01 276.316 229.248 284.387 239.41 284.387Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_103'>
                        <path
                          id='Ellipse_21-14'
                          d='M239.4 284.445C249.562 284.445 257.8 276.375 257.8 266.42C257.8 256.465 249.562 248.395 239.4 248.395C229.238 248.395 221 256.465 221 266.42C221 276.375 229.238 284.445 239.4 284.445Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_13'
                      d='M243.81 280.37L254.31 269.3C255.91 267.537 258.71 267.439 260.51 269.104C262.31 270.672 262.41 273.415 260.71 275.178L250.21 286.248C248.61 288.011 245.81 288.109 244.01 286.444C242.31 284.876 242.21 282.133 243.81 280.37Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_17'
                      transform='matrix(0.687092 -0.726588 0.740524 0.672049 242.992 280.578)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_104' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_20-13'
                        d='M239.4 278.764C246.414 278.764 252.101 273.194 252.101 266.322C252.101 259.451 246.414 253.881 239.4 253.881C232.386 253.881 226.7 259.451 226.7 266.322C226.7 273.194 232.386 278.764 239.4 278.764Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                  <g id='subtask-3_6'>
                    <g id='Group_105'>
                      <path
                        id='Ellipse_18-13'
                        d='M338.31 178.879C348.472 178.879 356.71 170.809 356.71 160.854C356.71 150.898 348.472 142.828 338.31 142.828C328.148 142.828 319.91 150.898 319.91 160.854C319.91 170.809 328.148 178.879 338.31 178.879Z'
                        fill='#F6F9FD'
                      />
                      <g id='Group_106'>
                        <path
                          id='Ellipse_18-14'
                          d='M338.3 178.84C348.462 178.84 356.7 170.77 356.7 160.814C356.7 150.859 348.462 142.789 338.3 142.789C328.138 142.789 319.9 150.859 319.9 160.814C319.9 170.77 328.138 178.84 338.3 178.84Z'
                          fill='white'
                        />
                      </g>
                    </g>
                    <path
                      id='Rectangle_14'
                      d='M342.71 175.745L353.21 164.675C354.81 162.912 357.61 162.814 359.41 164.479C361.21 166.047 361.31 168.79 359.61 170.553L349.11 181.623C347.51 183.386 344.71 183.484 342.91 181.819C341.21 180.251 341.11 177.508 342.71 175.745Z'
                      fill='#E1E1E1'
                    />
                    <text
                      id='Task_18'
                      transform='matrix(0.687092 -0.726588 0.740524 0.672049 341.923 175.949)'
                      fill='#272727'
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='7.3488'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='8.07208'>
                        Task
                      </tspan>
                    </text>
                    <g id='Group_107' opacity={task[1].TasksAndStudents?.isCompleted ? '1' : '0.4'}>
                      <path
                        id='Ellipse_19-13'
                        d='M338.3 173.256C345.314 173.256 351 167.686 351 160.815C351 153.943 345.314 148.373 338.3 148.373C331.286 148.373 325.601 153.943 325.601 160.815C325.601 167.686 331.286 173.256 338.3 173.256Z'
                        fill='#00AAFF'
                      />
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[1].TasksAndStudents?.id || 0)}>
                  <g id='box-task-2'>
                    <g id='Group_108'>
                      <path
                        id='Vector_283'
                        opacity='0.0196078'
                        d='M486.81 405.176C492.91 405.176 497.91 400.277 497.91 394.302V358.349C497.91 352.373 492.91 347.475 486.81 347.475H450.11C444.01 347.475 439.01 352.373 439.01 358.349V394.302C439.01 400.277 444.01 405.176 450.11 405.176H486.81Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_284'
                        opacity='0.0392157'
                        d='M486.71 405.176C492.81 405.176 497.81 400.277 497.81 394.302V358.349C497.81 352.373 492.81 347.475 486.71 347.475H450.01C443.91 347.475 438.91 352.373 438.91 358.349V394.302C438.91 400.277 443.91 405.176 450.01 405.176H486.71Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_285'
                        opacity='0.0588235'
                        d='M486.71 405.078C492.81 405.078 497.81 400.18 497.81 394.204V358.251C497.81 352.275 492.81 347.377 486.71 347.377H450.01C443.91 347.377 438.91 352.275 438.91 358.251V394.204C438.91 400.18 443.91 405.078 450.01 405.078H486.71Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_286'
                        opacity='0.0784314'
                        d='M486.61 404.98C492.71 404.98 497.71 400.082 497.71 394.106V358.153C497.71 352.177 492.71 347.279 486.61 347.279H449.911C443.811 347.279 438.811 352.177 438.811 358.153V394.106C438.811 400.082 443.811 404.98 449.911 404.98H486.61Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_287'
                        opacity='0.0980392'
                        d='M486.51 404.883C492.61 404.883 497.61 399.984 497.61 394.009V358.056C497.61 352.08 492.61 347.182 486.51 347.182H449.81C443.71 347.182 438.71 352.08 438.71 358.056V394.009C438.71 399.984 443.71 404.883 449.81 404.883H486.51Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_288'
                        opacity='0.1176'
                        d='M486.41 404.783C492.51 404.783 497.51 399.885 497.51 393.909V357.956C497.51 351.98 492.51 347.082 486.41 347.082H449.71C443.61 347.082 438.61 351.98 438.61 357.956V393.909C438.61 399.885 443.61 404.783 449.71 404.783H486.41Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_289'
                        opacity='0.1373'
                        d='M486.31 404.685C492.41 404.685 497.41 399.787 497.41 393.811V357.858C497.41 351.883 492.41 346.984 486.31 346.984H449.61C443.51 346.984 438.51 351.883 438.51 357.858V393.811C438.51 399.787 443.51 404.685 449.61 404.685H486.31Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_290'
                        opacity='0.1569'
                        d='M486.21 404.588C492.31 404.588 497.31 399.689 497.31 393.714V357.761C497.31 351.785 492.31 346.887 486.21 346.887H449.51C443.41 346.887 438.41 351.785 438.41 357.761V393.714C438.41 399.689 443.41 404.588 449.51 404.588H486.21Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_291'
                        opacity='0.1765'
                        d='M486.11 404.49C492.21 404.49 497.21 399.592 497.21 393.616V357.663C497.21 351.687 492.21 346.789 486.11 346.789H449.411C443.311 346.789 438.311 351.687 438.311 357.663V393.616C438.311 399.592 443.311 404.49 449.411 404.49H486.11Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_292'
                        opacity='0.1961'
                        d='M486.01 404.392C492.11 404.392 497.11 399.494 497.11 393.518V357.565C497.11 351.59 492.11 346.691 486.01 346.691H449.31C443.21 346.691 438.21 351.59 438.21 357.565V393.518C438.21 399.494 443.21 404.392 449.31 404.392H486.01Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_293'
                        opacity='0.2157'
                        d='M485.91 404.295C492.01 404.295 497.01 399.396 497.01 393.421V357.468C497.01 351.492 492.01 346.594 485.91 346.594H449.21C443.11 346.594 438.11 351.492 438.11 357.468V393.421C438.11 399.396 443.11 404.295 449.21 404.295H485.91Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_294'
                        opacity='0.2353'
                        d='M485.81 404.197C491.91 404.197 496.91 399.299 496.91 393.323V357.37C496.91 351.394 491.91 346.496 485.81 346.496H449.11C443.01 346.496 438.01 351.394 438.01 357.37V393.323C438.01 399.299 443.01 404.197 449.11 404.197H485.81Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_295'
                        opacity='0.2549'
                        d='M485.71 404.097C491.81 404.097 496.81 399.199 496.81 393.223V357.27C496.81 351.295 491.81 346.396 485.71 346.396H449.01C442.91 346.396 437.91 351.295 437.91 357.27V393.223C437.91 399.199 442.91 404.097 449.01 404.097H485.71Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_296'
                        opacity='0.2745'
                        d='M485.61 404C491.71 404 496.71 399.102 496.71 393.126V357.173C496.71 351.197 491.71 346.299 485.61 346.299H448.911C442.811 346.299 437.811 351.197 437.811 357.173V393.126C437.811 399.102 442.811 404 448.911 404H485.61Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_297'
                        opacity='0.2941'
                        d='M485.51 404C491.61 404 496.61 399.102 496.61 393.126V357.173C496.61 351.197 491.61 346.299 485.51 346.299H448.81C442.71 346.299 437.71 351.197 437.71 357.173V393.126C437.71 399.102 442.71 404 448.81 404H485.51Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_298'
                        opacity='0.3137'
                        d='M485.51 403.902C491.61 403.902 496.61 399.004 496.61 393.028V357.075C496.61 351.099 491.61 346.201 485.51 346.201H448.81C442.71 346.201 437.71 351.099 437.71 357.075V393.028C437.71 399.004 442.71 403.902 448.81 403.902H485.51Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_299'
                        opacity='0.3333'
                        d='M485.41 403.804C491.51 403.804 496.51 398.906 496.51 392.93V356.978C496.51 351.002 491.51 346.104 485.41 346.104H448.71C442.61 346.104 437.61 351.002 437.61 356.978V392.93C437.61 398.906 442.61 403.804 448.71 403.804H485.41Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_300'
                        opacity='0.3529'
                        d='M485.31 403.707C491.41 403.707 496.41 398.809 496.41 392.833V356.88C496.41 350.904 491.41 346.006 485.31 346.006H448.61C442.51 346.006 437.51 350.904 437.51 356.88V392.833C437.51 398.809 442.51 403.707 448.61 403.707H485.31Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_301'
                        opacity='0.3725'
                        d='M485.21 403.609C491.31 403.609 496.31 398.711 496.31 392.735V356.782C496.31 350.806 491.31 345.908 485.21 345.908H448.51C442.41 345.908 437.41 350.806 437.41 356.782V392.735C437.41 398.711 442.41 403.609 448.51 403.609H485.21Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_302'
                        opacity='0.3922'
                        d='M485.11 403.511C491.21 403.511 496.21 398.613 496.21 392.637V356.685C496.21 350.709 491.21 345.811 485.11 345.811H448.31C442.21 345.811 437.21 350.709 437.21 356.685V392.637C437.21 398.613 442.21 403.511 448.31 403.511H485.11Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_303'
                        opacity='0.4118'
                        d='M485.01 403.412C491.11 403.412 496.11 398.514 496.11 392.538V356.585C496.11 350.609 491.11 345.711 485.01 345.711H448.31C442.21 345.711 437.21 350.609 437.21 356.585V392.538C437.21 398.514 442.21 403.412 448.31 403.412H485.01Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_304'
                        opacity='0.4314'
                        d='M484.91 403.314C491.01 403.314 496.01 398.416 496.01 392.44V356.487C496.01 350.511 491.01 345.613 484.91 345.613H448.21C442.11 345.613 437.11 350.511 437.11 356.487V392.44C437.11 398.416 442.11 403.314 448.21 403.314H484.91Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_305'
                        opacity='0.451'
                        d='M484.81 403.217C490.91 403.217 495.91 398.318 495.91 392.343V356.39C495.91 350.414 490.91 345.516 484.81 345.516H448.11C442.01 345.516 437.01 350.414 437.01 356.39V392.343C437.01 398.318 442.01 403.217 448.11 403.217H484.81Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_306'
                        opacity='0.4706'
                        d='M484.71 403.119C490.81 403.119 495.81 398.221 495.81 392.245V356.292C495.81 350.316 490.81 345.418 484.71 345.418H448.01C441.91 345.418 436.91 350.316 436.91 356.292V392.245C436.91 398.221 441.91 403.119 448.01 403.119H484.71Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_307'
                        opacity='0.4902'
                        d='M484.61 403.021C490.71 403.021 495.71 398.123 495.71 392.147V356.194C495.71 350.219 490.71 345.32 484.61 345.32H447.911C441.811 345.32 436.811 350.219 436.811 356.194V392.147C436.811 398.123 441.811 403.021 447.911 403.021H484.61Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_308'
                        opacity='0.5098'
                        d='M484.51 402.924C490.61 402.924 495.61 398.025 495.61 392.05V356.097C495.61 350.121 490.61 345.223 484.51 345.223H447.81C441.71 345.223 436.71 350.121 436.71 356.097V392.05C436.71 398.025 441.71 402.924 447.81 402.924H484.51Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_309'
                        opacity='0.5294'
                        d='M484.41 402.824C490.51 402.824 495.51 397.926 495.51 391.95V355.997C495.51 350.021 490.51 345.123 484.41 345.123H447.71C441.61 345.123 436.61 350.021 436.61 355.997V391.95C436.61 397.926 441.61 402.824 447.71 402.824H484.41Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_310'
                        opacity='0.549'
                        d='M484.31 402.824C490.41 402.824 495.41 397.926 495.41 391.95V355.997C495.41 350.021 490.41 345.123 484.31 345.123H447.61C441.51 345.123 436.51 350.021 436.51 355.997V391.95C436.51 397.926 441.51 402.824 447.61 402.824H484.31Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_311'
                        opacity='0.5686'
                        d='M484.31 402.726C490.41 402.726 495.41 397.828 495.41 391.852V355.899C495.41 349.924 490.41 345.025 484.31 345.025H447.61C441.51 345.025 436.51 349.924 436.51 355.899V391.852C436.51 397.828 441.51 402.726 447.61 402.726H484.31Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_312'
                        opacity='0.5882'
                        d='M484.21 402.629C490.31 402.629 495.31 397.73 495.31 391.755V355.802C495.31 349.826 490.31 344.928 484.21 344.928H447.51C441.41 344.928 436.41 349.826 436.41 355.802V391.755C436.41 397.73 441.41 402.629 447.51 402.629H484.21Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_313'
                        opacity='0.6078'
                        d='M484.11 402.531C490.21 402.531 495.21 397.633 495.21 391.657V355.704C495.21 349.728 490.21 344.83 484.11 344.83H447.31C441.21 344.83 436.21 349.728 436.21 355.704V391.657C436.21 397.633 441.21 402.531 447.31 402.531H484.11Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_314'
                        opacity='0.6275'
                        d='M484.01 402.433C490.11 402.433 495.11 397.535 495.11 391.559V355.606C495.11 349.631 490.11 344.732 484.01 344.732H447.31C441.21 344.732 436.21 349.631 436.21 355.606V391.559C436.21 397.535 441.21 402.433 447.31 402.433H484.01Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_315'
                        opacity='0.6471'
                        d='M483.91 402.336C490.01 402.336 495.01 397.438 495.01 391.462V355.509C495.01 349.533 490.01 344.635 483.91 344.635H447.21C441.11 344.635 436.11 349.533 436.11 355.509V391.462C436.11 397.438 441.11 402.336 447.21 402.336H483.91Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_316'
                        opacity='0.6667'
                        d='M483.81 402.238C489.91 402.238 494.91 397.34 494.91 391.364V355.411C494.91 349.435 489.91 344.537 483.81 344.537H447.11C441.01 344.537 436.01 349.435 436.01 355.411V391.364C436.01 397.34 441.01 402.238 447.11 402.238H483.81Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_317'
                        opacity='0.6863'
                        d='M483.71 402.138C489.81 402.138 494.81 397.24 494.81 391.264V355.312C494.81 349.336 489.81 344.438 483.71 344.438H447.01C440.91 344.438 435.91 349.336 435.91 355.312V391.264C435.91 397.24 440.91 402.138 447.01 402.138H483.71Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_318'
                        opacity='0.7059'
                        d='M483.61 402.041C489.71 402.041 494.71 397.143 494.71 391.167V355.214C494.71 349.238 489.71 344.34 483.61 344.34H446.911C440.811 344.34 435.811 349.238 435.811 355.214V391.167C435.811 397.143 440.811 402.041 446.911 402.041H483.61Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_319'
                        opacity='0.7255'
                        d='M483.51 401.943C489.61 401.943 494.61 397.045 494.61 391.069V355.116C494.61 349.14 489.61 344.242 483.51 344.242H446.81C440.71 344.242 435.71 349.14 435.71 355.116V391.069C435.71 397.045 440.71 401.943 446.81 401.943H483.51Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_320'
                        opacity='0.7451'
                        d='M483.41 401.845C489.51 401.845 494.51 396.947 494.51 390.971V355.019C494.51 349.043 489.51 344.145 483.41 344.145H446.71C440.61 344.145 435.61 349.043 435.61 355.019V390.971C435.61 396.947 440.61 401.845 446.71 401.845H483.41Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_321'
                        opacity='0.7647'
                        d='M483.31 401.748C489.41 401.748 494.41 396.85 494.41 390.874V354.921C494.41 348.945 489.41 344.047 483.31 344.047H446.61C440.51 344.047 435.51 348.945 435.51 354.921V390.972C435.51 396.948 440.51 401.846 446.61 401.846H483.31V401.748Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_322'
                        opacity='0.7843'
                        d='M483.21 401.65C489.31 401.65 494.31 396.752 494.31 390.776V354.823C494.31 348.847 489.31 343.949 483.21 343.949H446.51C440.41 343.949 435.41 348.847 435.41 354.823V390.776C435.41 396.752 440.41 401.65 446.51 401.65H483.21Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_323'
                        opacity='0.8039'
                        d='M483.11 401.551C489.21 401.551 494.21 396.652 494.21 390.677V354.724C494.21 348.748 489.21 343.85 483.11 343.85H446.411C440.311 343.85 435.311 348.748 435.311 354.724V390.677C435.311 396.652 440.311 401.551 446.411 401.551H483.11Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_324'
                        opacity='0.8235'
                        d='M483.01 401.552C489.11 401.552 494.11 396.654 494.11 390.678V354.726C494.11 348.75 489.11 343.852 483.01 343.852H446.31C440.21 343.852 435.21 348.75 435.21 354.726V390.678C435.21 396.654 440.21 401.552 446.31 401.552H483.01Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_325'
                        opacity='0.8431'
                        d='M483.01 401.453C489.11 401.453 494.11 396.555 494.11 390.579V354.626C494.11 348.65 489.11 343.752 483.01 343.752H446.31C440.21 343.752 435.21 348.65 435.21 354.626V390.579C435.21 396.555 440.21 401.453 446.31 401.453H483.01Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_326'
                        opacity='0.8627'
                        d='M482.91 401.355C489.01 401.355 494.01 396.457 494.01 390.481V354.528C494.01 348.552 489.01 343.654 482.91 343.654H446.21C440.11 343.654 435.11 348.552 435.11 354.528V390.481C435.11 396.457 440.11 401.355 446.21 401.355H482.91Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_327'
                        opacity='0.8824'
                        d='M482.81 401.258C488.91 401.258 493.91 396.359 493.91 390.384V354.431C493.91 348.455 488.91 343.557 482.81 343.557H446.11C440.01 343.557 435.01 348.455 435.01 354.431V390.384C435.01 396.359 440.01 401.258 446.11 401.258H482.81Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_328'
                        opacity='0.902'
                        d='M482.71 401.16C488.81 401.16 493.81 396.262 493.81 390.286V354.333C493.81 348.357 488.81 343.459 482.71 343.459H446.01C439.91 343.459 434.91 348.357 434.91 354.333V390.286C434.91 396.262 439.91 401.16 446.01 401.16H482.71Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_329'
                        opacity='0.9216'
                        d='M482.61 401.062C488.71 401.062 493.71 396.164 493.71 390.188V354.235C493.71 348.26 488.71 343.361 482.61 343.361H445.911C439.811 343.361 434.811 348.26 434.811 354.235V390.188C434.811 396.164 439.811 401.062 445.911 401.062H482.61Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_330'
                        opacity='0.9412'
                        d='M482.51 400.965C488.61 400.965 493.61 396.066 493.61 390.091V354.138C493.61 348.162 488.61 343.264 482.51 343.264H445.81C439.71 343.264 434.71 348.162 434.71 354.138V390.091C434.71 396.066 439.71 400.965 445.81 400.965H482.51Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_331'
                        opacity='0.9608'
                        d='M482.41 400.865C488.51 400.865 493.51 395.967 493.51 389.991V354.038C493.51 348.062 488.51 343.164 482.41 343.164H445.71C439.61 343.164 434.61 348.062 434.61 354.038V389.991C434.61 395.967 439.61 400.865 445.71 400.865H482.41Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_332'
                        opacity='0.9804'
                        d='M482.31 400.767C488.41 400.767 493.41 395.869 493.41 389.893V353.94C493.41 347.965 488.41 343.066 482.31 343.066H445.61C439.51 343.066 434.51 347.965 434.51 353.94V389.991C434.51 395.967 439.51 400.865 445.61 400.865H482.31V400.767Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_333'
                        d='M482.21 400.67C488.31 400.67 493.31 395.771 493.31 389.796V353.843C493.31 347.867 488.31 342.969 482.21 342.969H445.51C439.41 342.969 434.41 347.867 434.41 353.843V389.796C434.41 395.771 439.41 400.67 445.51 400.67H482.21Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_334'
                      d='M481.311 399.592C487.311 399.592 492.21 394.792 492.21 388.914V353.549C492.21 347.671 487.311 342.871 481.311 342.871H445.21C439.21 342.871 434.311 347.671 434.311 353.549V388.914C434.311 394.792 439.21 399.592 445.21 399.592H481.311Z'
                      fill='url(#paint5_linear_413_2)'
                    />
                    <path
                      id='Vector_335'
                      d='M481.311 399.201C487.011 399.201 491.71 394.596 491.71 389.012V353.647C491.71 348.063 487.011 343.459 481.311 343.459H445.21C439.51 343.459 434.811 348.063 434.811 353.647V389.012C434.811 394.596 439.51 399.201 445.21 399.201H481.311Z'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 2'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3614'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='441.283' y='366.985'>
                        {task[1].name && task[1].name.length > 3
                          ? truncateText(task[1].name, 4)
                          : task[1].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_336'
                      d='M476.71 389.699H451.01C447.41 389.699 444.51 386.858 444.51 383.331V381.176C444.51 377.65 447.41 374.809 451.01 374.809H476.71C480.31 374.809 483.21 377.65 483.21 381.176V383.233C483.31 386.76 480.31 389.699 476.71 389.699Z'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <text
                      id='3/7_6'
                      fill={task[1].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='11.3287'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='454.434' y='387.746'>
                        {/* {task[1].counter + '/3'} */}
                        {(task[1]?.TasksAndStudents?.counter || 0) +
                          '/' +
                          (task[1]?.total_counter || 0)}
                      </tspan>
                    </text>
                  </g>
                </a>
              </g>
            )}
            {task[0] !== undefined && task[0] !== null && (
              <g id='task-1'>
                <g id='rod-1'>
                  <g id='Rectangle_6-5_00000145759103452151000500000000269615509856535467_'>
                    <g id='Group_109'>
                      <path
                        id='Vector_337'
                        d='M607.11 597.674C607.21 600.907 604.61 603.552 601.31 603.552L224.61 612.271C221.31 612.369 218.61 609.822 218.61 606.589C218.51 603.356 221.11 600.711 224.41 600.711L601.11 591.992C604.41 591.992 607.11 594.539 607.11 597.674Z'
                        fill='#F6F9FD'
                      />
                    </g>
                    <g id='Group_110'>
                      <path
                        id='Vector_338'
                        d='M607.11 597.674C607.21 600.907 604.61 603.552 601.31 603.552L224.61 612.271C221.31 612.369 218.61 609.822 218.61 606.589C218.51 603.356 221.11 600.711 224.41 600.711L601.11 591.992C604.41 591.992 607.11 594.539 607.11 597.674Z'
                        fill='#0686EF'
                      />
                    </g>
                  </g>
                  <g id='Group_111'>
                    <path
                      id='Rectangle_6-6_00000122702061188901871170000016209014118263725963_'
                      d='M607.1 597.732C607.2 600.964 604.6 603.609 601.3 603.609L224.6 612.328C221.3 612.426 218.6 609.879 218.6 606.646C218.5 603.413 221.1 600.768 224.4 600.768L601.1 592.05C604.4 591.952 607.1 594.499 607.1 597.732Z'
                      stroke='#00AAFF'
                      stroke-width='4'
                      stroke-miterlimit='10'
                    />
                  </g>
                </g>
                <g id='subtasks-1'>
                  <g id='rod_2'>
                    <path
                      id='Rectangle_8-13_00000017477539763163474660000008375822081233534902_'
                      d='M198.31 617.953C200.71 620.109 200.71 623.831 198.51 626.085L152.21 673.695C150.01 676.046 146.21 676.046 143.91 673.891C141.51 671.736 141.51 668.013 143.71 665.76L190.01 618.149C192.31 615.896 196.01 615.798 198.31 617.953Z'
                      fill='#F6F9FD'
                    />
                    <g id='Group_112'>
                      <path
                        id='Rectangle_8-14_00000107573842997352705850000016655411523418562221_'
                        d='M198.4 617.914C200.8 620.07 200.8 623.792 198.6 626.046L152.3 673.656C150.1 676.007 146.3 676.007 144 673.852C141.6 671.697 141.6 667.974 143.8 665.721L190.1 618.11C192.3 615.857 196 615.759 198.4 617.914Z'
                        fill='white'
                      />
                    </g>
                  </g>
                  <g id='rod_3'>
                    <path
                      id='Rectangle_9-13_00000057836103008097699150000008158297567868927127_'
                      d='M197.41 599.536C195.21 601.887 191.51 601.985 189.11 599.83L139.71 555.256C137.31 553.101 137.21 549.476 139.41 547.125C141.61 544.774 145.31 544.676 147.71 546.831L197.11 591.405C199.51 593.56 199.61 597.185 197.41 599.536Z'
                      fill='#F6F9FD'
                    />
                    <g id='Group_113'>
                      <path
                        id='Rectangle_9-14_00000073692392925105826790000016259703588019468710_'
                        d='M197.401 599.595C195.201 601.946 191.501 602.044 189.101 599.889L139.7 555.315C137.3 553.16 137.201 549.535 139.401 547.184C141.601 544.833 145.3 544.735 147.7 546.89L197.101 591.464C199.501 593.521 199.601 597.244 197.401 599.595Z'
                        fill='white'
                      />
                    </g>
                  </g>
                  <g id='subtask-1_7'>
                    <path
                      id='Ellipse_16-13_00000000191925728650066450000002360254037784847531_'
                      d='M206.81 625.986C216.972 625.986 225.21 617.916 225.21 607.961C225.21 598.006 216.972 589.936 206.81 589.936C196.648 589.936 188.41 598.006 188.41 607.961C188.41 617.916 196.648 625.986 206.81 625.986Z'
                      fill='#F6F9FD'
                    />
                    <g id='Group_114'>
                      <path
                        id='Ellipse_16-14_00000104682096063476369520000008132962339486196131_'
                        d='M206.8 626.045C216.962 626.045 225.2 617.975 225.2 608.02C225.2 598.064 216.962 589.994 206.8 589.994C196.638 589.994 188.4 598.064 188.4 608.02C188.4 617.975 196.638 626.045 206.8 626.045Z'
                        fill='white'
                      />
                    </g>
                    <g id='Group 1'>
                      <path
                        id='Rectangle_15_00000026155055735866555700000000366933038875812255_'
                        d='M222.01 614.624L221.41 599.537C221.31 597.186 223.21 595.129 225.61 595.031C228.01 594.933 230.11 596.794 230.21 599.146L230.81 614.232C230.91 616.583 229.01 618.64 226.61 618.738C224.21 618.836 222.11 616.975 222.01 614.624Z'
                        fill='#E1E1E1'
                      />
                      <text
                        id='Task_19'
                        transform='matrix(-0.0420036 -0.999164 0.999234 -0.0403137 222.458 613.977)'
                        fill='#272727'
                        xmlSpace='preserve'
                        style={{whiteSpace: 'pre'}}
                        font-family='Poppins'
                        font-size='6.2224'
                        letter-spacing='0em'
                      >
                        <tspan x='0' y='6.67784'>
                          Task
                        </tspan>
                      </text>
                      <g
                        id='Group_115'
                        opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}
                      >
                        <path
                          id='Ellipse_17-13_00000120523295500067239010000009621626000458908828_'
                          d='M206.8 620.461C213.814 620.461 219.5 614.891 219.5 608.02C219.5 601.148 213.814 595.578 206.8 595.578C199.786 595.578 194.101 601.148 194.101 608.02C194.101 614.891 199.786 620.461 206.8 620.461Z'
                          fill='#00AAFF'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-2_7'>
                    <path
                      id='Ellipse_21-13_00000132085316189298725090000004414885937960966021_'
                      d='M137.11 700.145C147.272 700.145 155.51 692.074 155.51 682.119C155.51 672.164 147.272 664.094 137.11 664.094C126.948 664.094 118.71 672.164 118.71 682.119C118.71 692.074 126.948 700.145 137.11 700.145Z'
                      fill='#F6F9FD'
                    />
                    <g id='Group_116'>
                      <path
                        id='Ellipse_21-14_00000063623915099847922490000018161156630482512003_'
                        d='M137.101 700.203C147.263 700.203 155.5 692.133 155.5 682.178C155.5 672.223 147.263 664.152 137.101 664.152C126.939 664.152 118.7 672.223 118.7 682.178C118.7 692.133 126.939 700.203 137.101 700.203Z'
                        fill='white'
                      />
                    </g>
                    <g id='Group 2'>
                      <path
                        id='Rectangle_13_00000106106031188673607150000005021056630765920648_'
                        d='M150.31 688.977L149.71 673.891C149.61 671.54 151.51 669.483 153.91 669.385C156.31 669.287 158.41 671.148 158.51 673.499L159.11 688.586C159.21 690.937 157.31 692.994 154.91 693.092C152.51 693.19 150.41 691.329 150.31 688.977Z'
                        fill='#E1E1E1'
                      />
                      <text
                        id='Task_20'
                        transform='matrix(-0.0420036 -0.999164 0.999234 -0.0403137 149.822 689.658)'
                        fill='#272727'
                        xmlSpace='preserve'
                        style={{whiteSpace: 'pre'}}
                        font-family='Poppins'
                        font-size='7.3487'
                        letter-spacing='0em'
                      >
                        <tspan x='0' y='8.07205'>
                          Task
                        </tspan>
                      </text>
                      <g
                        id='Group_117'
                        opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}
                      >
                        <path
                          id='Ellipse_20-13_00000036226307972869425020000004591100330578241721_'
                          d='M137.101 694.619C144.115 694.619 149.8 689.049 149.8 682.178C149.8 675.307 144.115 669.736 137.101 669.736C130.087 669.736 124.4 675.307 124.4 682.178C124.4 689.049 130.087 694.619 137.101 694.619Z'
                          fill='#00AAFF'
                        />
                      </g>
                    </g>
                  </g>
                  <g id='subtask-3_7'>
                    <path
                      id='Ellipse_18-13_00000102537843831941346080000015402361361162291351_'
                      d='M130.41 557.117C140.572 557.117 148.81 549.047 148.81 539.092C148.81 529.137 140.572 521.066 130.41 521.066C120.248 521.066 112.01 529.137 112.01 539.092C112.01 549.047 120.248 557.117 130.41 557.117Z'
                      fill='#F6F9FD'
                    />
                    <g id='Group_118'>
                      <path
                        id='Ellipse_18-14_00000170966251781072434990000001638301006816439713_'
                        d='M130.4 557.078C140.562 557.078 148.8 549.008 148.8 539.053C148.8 529.098 140.562 521.027 130.4 521.027C120.238 521.027 112 529.098 112 539.053C112 549.008 120.238 557.078 130.4 557.078Z'
                        fill='white'
                      />
                    </g>
                    <g id='Group 3'>
                      <path
                        id='Rectangle_14_00000035499600817642261350000009994562494879863743_'
                        d='M144.31 546.538L143.71 531.451C143.61 529.1 145.51 527.043 147.91 526.945C150.31 526.847 152.41 528.708 152.51 531.06L153.11 546.146C153.21 548.497 151.31 550.555 148.91 550.652C146.51 550.75 144.41 548.889 144.31 546.538Z'
                        fill='#E1E1E1'
                      />
                      <text
                        id='Task_21'
                        transform='matrix(-0.0420036 -0.999164 0.999234 -0.0403137 143.831 547.16)'
                        fill='#272727'
                        xmlSpace='preserve'
                        style={{whiteSpace: 'pre'}}
                        font-family='Poppins'
                        font-size='7.3487'
                        letter-spacing='0em'
                      >
                        <tspan x='0' y='8.07205'>
                          Task
                        </tspan>
                      </text>
                      <g
                        id='Group_119'
                        opacity={task[0].TasksAndStudents?.isCompleted ? '1' : '0.4'}
                      >
                        <path
                          id='Ellipse_19-13_00000145054324816819754320000016265126788751008395_'
                          d='M130.4 551.494C137.414 551.494 143.101 545.924 143.101 539.053C143.101 532.182 137.414 526.611 130.4 526.611C123.386 526.611 117.7 532.182 117.7 539.053C117.7 545.924 123.386 551.494 130.4 551.494Z'
                          fill='#00AAFF'
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <a href='#' onClick={() => handleUserAction(task[0].TasksAndStudents?.id || 0)}>
                  <g id='box-task-1'>
                    <g id='Group_120'>
                      <path
                        id='Vector_339'
                        opacity='0.0196078'
                        d='M405.809 579.061C405.509 573.085 400.409 568.383 394.309 568.677L357.61 570.147C351.51 570.44 346.709 575.437 347.009 581.412L348.509 617.365C348.809 623.341 353.909 628.043 360.009 627.75L396.709 626.28C402.809 625.986 407.609 620.99 407.309 615.014L405.809 579.061Z'
                        fill='#FBFCFD'
                      />
                      <path
                        id='Vector_340'
                        opacity='0.0392157'
                        d='M405.71 579.159C405.41 573.183 400.31 568.481 394.21 568.775L357.51 570.244C351.41 570.538 346.61 575.534 346.91 581.51L348.41 617.463C348.71 623.439 353.81 628.141 359.91 627.847L396.61 626.378C402.71 626.084 407.51 621.088 407.21 615.112L405.71 579.159Z'
                        fill='#F8F9FB'
                      />
                      <path
                        id='Vector_341'
                        opacity='0.0588235'
                        d='M405.61 579.257C405.31 573.281 400.21 568.579 394.11 568.872L357.41 570.342C351.31 570.636 346.51 575.632 346.81 581.608L348.31 617.561C348.61 623.537 353.71 628.239 359.81 627.945L396.51 626.475C402.61 626.182 407.41 621.185 407.11 615.21L405.61 579.257Z'
                        fill='#F4F6F9'
                      />
                      <path
                        id='Vector_342'
                        opacity='0.0784314'
                        d='M405.51 579.356C405.21 573.38 400.11 568.678 394.01 568.972L357.31 570.442C351.21 570.735 346.41 575.732 346.71 581.707L348.21 617.66C348.51 623.636 353.61 628.338 359.71 628.044L396.41 626.575C402.51 626.281 407.31 621.285 407.01 615.309L405.51 579.356Z'
                        fill='#F1F3F7'
                      />
                      <path
                        id='Vector_343'
                        opacity='0.0980392'
                        d='M405.51 579.454C405.21 573.478 400.11 568.776 394.01 569.07L357.31 570.539C351.21 570.833 346.41 575.829 346.71 581.805L348.21 617.758C348.51 623.734 353.61 628.436 359.71 628.142L396.41 626.673C402.51 626.379 407.31 621.383 407.01 615.407L405.51 579.454Z'
                        fill='#EDF0F4'
                      />
                      <path
                        id='Vector_344'
                        opacity='0.1176'
                        d='M405.41 579.552C405.11 573.576 400.01 568.873 393.91 569.167L357.21 570.637C351.11 570.931 346.31 575.927 346.61 581.903L348.11 617.856C348.41 623.831 353.51 628.534 359.61 628.24L396.31 626.77C402.41 626.476 407.21 621.48 406.91 615.504L405.41 579.552Z'
                        fill='#EAEDF2'
                      />
                      <path
                        id='Vector_345'
                        opacity='0.1373'
                        d='M405.309 579.649C405.009 573.673 399.909 568.971 393.809 569.265L357.11 570.734C351.01 571.028 346.209 576.025 346.509 582L348.009 617.953C348.309 623.929 353.409 628.631 359.509 628.337L396.209 626.868C402.309 626.574 407.109 621.578 406.809 615.602L405.309 579.649Z'
                        fill='#E6EAF0'
                      />
                      <path
                        id='Vector_346'
                        opacity='0.1569'
                        d='M405.21 579.747C404.91 573.771 399.81 569.069 393.71 569.363L357.01 570.832C350.91 571.126 346.11 576.122 346.41 582.098L347.91 618.051C348.21 624.027 353.31 628.729 359.41 628.435L396.11 626.966C402.21 626.672 407.01 621.676 406.71 615.7L405.21 579.747Z'
                        fill='#E3E7EE'
                      />
                      <path
                        id='Vector_347'
                        opacity='0.1765'
                        d='M405.11 579.845C404.81 573.869 399.71 569.166 393.61 569.46L356.91 570.93C350.81 571.224 346.01 576.22 346.31 582.196L347.81 618.149C348.11 624.124 353.21 628.827 359.31 628.533L396.01 627.063C402.11 626.769 406.91 621.773 406.61 615.797L405.11 579.845Z'
                        fill='#DFE3EC'
                      />
                      <path
                        id='Vector_348'
                        opacity='0.1961'
                        d='M405.01 579.944C404.71 573.968 399.61 569.266 393.51 569.56L356.81 571.029C350.71 571.323 345.91 576.319 346.21 582.295L347.71 618.248C348.01 624.224 353.11 628.926 359.21 628.632L395.91 627.163C402.01 626.869 406.81 621.873 406.51 615.897L405.01 579.944Z'
                        fill='#DCE0EA'
                      />
                      <path
                        id='Vector_349'
                        opacity='0.2157'
                        d='M404.91 580.042C404.61 574.066 399.51 569.364 393.41 569.658L356.71 571.127C350.61 571.421 345.81 576.417 346.11 582.393L347.61 618.346C347.91 624.322 353.01 629.024 359.11 628.73L395.81 627.261C401.91 626.967 406.71 621.971 406.41 615.995L404.91 580.042Z'
                        fill='#D8DDE8'
                      />
                      <path
                        id='Vector_350'
                        opacity='0.2353'
                        d='M404.809 580.139C404.509 574.164 399.409 569.461 393.309 569.755L356.61 571.225C350.51 571.519 345.709 576.515 346.009 582.491L347.509 618.444C347.809 624.419 352.909 629.122 359.009 628.828L395.709 627.358C401.809 627.064 406.609 622.068 406.309 616.092L404.809 580.139Z'
                        fill='#D4DAE6'
                      />
                      <path
                        id='Vector_351'
                        opacity='0.2549'
                        d='M404.71 580.237C404.41 574.261 399.31 569.559 393.21 569.853L356.51 571.322C350.41 571.616 345.61 576.612 345.91 582.588L347.41 618.541C347.71 624.517 352.81 629.219 358.91 628.925L395.61 627.456C401.71 627.162 406.51 622.166 406.21 616.19L404.71 580.237Z'
                        fill='#D1D7E3'
                      />
                      <path
                        id='Vector_352'
                        opacity='0.2745'
                        d='M404.71 580.335C404.41 574.359 399.31 569.657 393.21 569.951L356.51 571.42C350.41 571.714 345.61 576.71 345.91 582.686L347.41 618.639C347.71 624.615 352.81 629.317 358.91 629.023L395.61 627.554C401.71 627.26 406.51 622.263 406.21 616.288L404.71 580.335Z'
                        fill='#CDD4E1'
                      />
                      <path
                        id='Vector_353'
                        opacity='0.2941'
                        d='M404.61 580.432C404.31 574.457 399.21 569.754 393.11 570.048L356.41 571.518C350.31 571.812 345.51 576.808 345.81 582.784L347.31 618.736C347.61 624.712 352.71 629.415 358.81 629.121L395.51 627.651C401.61 627.357 406.41 622.361 406.11 616.385L404.61 580.432Z'
                        fill='#CAD1DF'
                      />
                      <path
                        id='Vector_354'
                        opacity='0.3137'
                        d='M404.51 580.53C404.21 574.554 399.11 569.852 393.01 570.146L356.31 571.615C350.21 571.909 345.41 576.905 345.71 582.881L347.21 618.834C347.51 624.81 352.61 629.512 358.71 629.218L395.41 627.749C401.51 627.455 406.31 622.459 406.01 616.483L404.51 580.53Z'
                        fill='#C6CEDD'
                      />
                      <path
                        id='Vector_355'
                        opacity='0.3333'
                        d='M404.41 580.63C404.11 574.654 399.01 569.952 392.91 570.245L356.21 571.715C350.11 572.009 345.31 577.005 345.61 582.981L347.11 618.934C347.41 624.91 352.51 629.612 358.61 629.318L395.31 627.848C401.41 627.555 406.21 622.558 405.91 616.583L404.41 580.63Z'
                        fill='#C3CBDB'
                      />
                      <path
                        id='Vector_356'
                        opacity='0.3529'
                        d='M404.309 580.727C404.009 574.752 398.909 570.049 392.809 570.343L356.11 571.813C350.01 572.106 345.209 577.103 345.509 583.078L347.009 619.031C347.309 625.007 352.409 629.71 358.509 629.416L395.209 627.946C401.309 627.652 406.109 622.656 405.809 616.68L404.309 580.727Z'
                        fill='#BFC8D9'
                      />
                      <path
                        id='Vector_357'
                        opacity='0.3725'
                        d='M404.21 580.825C403.91 574.849 398.81 570.147 392.71 570.441L356.01 571.91C349.91 572.204 345.11 577.2 345.41 583.176L346.91 619.129C347.21 625.105 352.31 629.807 358.41 629.513L395.11 628.044C401.21 627.75 406.01 622.754 405.71 616.778L404.21 580.825Z'
                        fill='#BCC5D7'
                      />
                      <path
                        id='Vector_358'
                        opacity='0.3922'
                        d='M404.11 580.923C403.81 574.947 398.71 570.245 392.61 570.538L355.91 572.008C349.81 572.302 345.01 577.298 345.31 583.274L346.81 619.227C347.11 625.203 352.21 629.905 358.31 629.611L395.01 628.141C401.11 627.848 405.91 622.851 405.61 616.876L404.11 580.923Z'
                        fill='#B8C2D5'
                      />
                      <path
                        id='Vector_359'
                        opacity='0.4118'
                        d='M404.01 580.923C403.71 574.947 398.61 570.245 392.51 570.538L355.81 572.008C349.71 572.302 344.91 577.298 345.21 583.274L346.71 619.227C347.01 625.203 352.11 629.905 358.21 629.611L394.91 628.141C401.01 627.848 405.81 622.851 405.51 616.876L404.01 580.923Z'
                        fill='#B4BFD3'
                      />
                      <path
                        id='Vector_360'
                        opacity='0.4314'
                        d='M403.91 581.02C403.61 575.044 398.51 570.342 392.41 570.636L355.71 572.106C349.61 572.399 344.81 577.396 345.11 583.371L346.61 619.324C346.91 625.3 352.01 630.002 358.11 629.709L394.81 628.239C400.91 627.945 405.71 622.949 405.41 616.973L403.91 581.02Z'
                        fill='#B1BCD0'
                      />
                      <path
                        id='Vector_361'
                        opacity='0.451'
                        d='M403.91 581.118C403.61 575.142 398.51 570.44 392.41 570.734L355.71 572.203C349.61 572.497 344.81 577.493 345.11 583.469L346.61 619.422C346.91 625.398 352.01 630.1 358.11 629.806L394.81 628.337C400.91 628.043 405.71 623.047 405.41 617.071L403.91 581.118Z'
                        fill='#ADB9CE'
                      />
                      <path
                        id='Vector_362'
                        opacity='0.4706'
                        d='M403.809 581.218C403.509 575.242 398.409 570.539 392.309 570.833L355.61 572.303C349.51 572.597 344.709 577.593 345.009 583.569L346.509 619.522C346.809 625.497 351.909 630.2 358.009 629.906L394.709 628.436C400.809 628.143 405.609 623.146 405.309 617.171L403.809 581.218Z'
                        fill='#AAB6CC'
                      />
                      <path
                        id='Vector_363'
                        opacity='0.4902'
                        d='M403.71 581.315C403.41 575.339 398.31 570.637 392.21 570.931L355.51 572.4C349.41 572.694 344.61 577.691 344.91 583.666L346.41 619.619C346.71 625.595 351.81 630.297 357.91 630.003L394.61 628.534C400.71 628.24 405.51 623.244 405.21 617.268L403.71 581.315Z'
                        fill='#A6B3CA'
                      />
                      <path
                        id='Vector_364'
                        opacity='0.5098'
                        d='M403.61 581.413C403.31 575.437 398.21 570.735 392.11 571.029L355.41 572.498C349.31 572.792 344.51 577.788 344.81 583.764L346.31 619.717C346.61 625.693 351.71 630.395 357.81 630.101L394.51 628.632C400.61 628.338 405.41 623.342 405.11 617.366L403.61 581.413Z'
                        fill='#A3AFC8'
                      />
                      <path
                        id='Vector_365'
                        opacity='0.5294'
                        d='M403.51 581.511C403.21 575.535 398.11 570.832 392.01 571.126L355.31 572.596C349.21 572.89 344.41 577.886 344.71 583.862L346.21 619.815C346.51 625.79 351.61 630.493 357.71 630.199L394.41 628.729C400.51 628.435 405.31 623.439 405.01 617.463L403.51 581.511Z'
                        fill='#9FACC6'
                      />
                      <path
                        id='Vector_366'
                        opacity='0.549'
                        d='M403.41 581.608C403.11 575.632 398.01 570.93 391.91 571.224L355.21 572.693C349.11 572.987 344.31 577.984 344.61 583.959L346.11 619.912C346.41 625.888 351.51 630.59 357.61 630.296L394.31 628.827C400.41 628.533 405.21 623.537 404.91 617.561L403.41 581.608Z'
                        fill='#9CA9C4'
                      />
                      <path
                        id='Vector_367'
                        opacity='0.5686'
                        d='M403.309 581.706C403.009 575.73 397.909 571.028 391.809 571.322L355.11 572.791C349.01 573.085 344.209 578.081 344.509 584.057L346.009 620.01C346.309 625.986 351.409 630.688 357.509 630.394L394.209 628.925C400.309 628.631 405.109 623.635 404.809 617.659L403.309 581.706Z'
                        fill='#98A6C2'
                      />
                      <path
                        id='Vector_368'
                        opacity='0.5882'
                        d='M403.21 581.804C402.91 575.828 397.81 571.125 391.71 571.419L355.01 572.889C348.91 573.183 344.11 578.179 344.41 584.155L345.91 620.108C346.21 626.083 351.31 630.786 357.41 630.492L394.11 629.022C400.21 628.728 405.01 623.732 404.71 617.756L403.21 581.804Z'
                        fill='#95A3BF'
                      />
                      <path
                        id='Vector_369'
                        opacity='0.6078'
                        d='M403.21 581.903C402.91 575.927 397.81 571.225 391.71 571.519L355.01 572.988C348.91 573.282 344.11 578.278 344.41 584.254L345.91 620.207C346.21 626.183 351.31 630.885 357.41 630.591L394.11 629.122C400.21 628.828 405.01 623.832 404.71 617.856L403.21 581.903Z'
                        fill='#91A0BD'
                      />
                      <path
                        id='Vector_370'
                        opacity='0.6275'
                        d='M403.11 582.001C402.81 576.025 397.71 571.323 391.61 571.617L354.91 573.086C348.81 573.38 344.01 578.376 344.31 584.352L345.81 620.305C346.11 626.281 351.21 630.983 357.31 630.689L394.01 629.22C400.11 628.926 404.91 623.929 404.61 617.954L403.11 582.001Z'
                        fill='#8D9DBB'
                      />
                      <path
                        id='Vector_371'
                        opacity='0.6471'
                        d='M403.01 582.098C402.71 576.123 397.61 571.42 391.51 571.714L354.81 573.184C348.71 573.478 343.91 578.474 344.21 584.45L345.71 620.402C346.01 626.378 351.11 631.081 357.21 630.787L393.91 629.317C400.01 629.023 404.81 624.027 404.51 618.051L403.01 582.098Z'
                        fill='#8A9AB9'
                      />
                      <path
                        id='Vector_372'
                        opacity='0.6667'
                        d='M402.91 582.196C402.61 576.22 397.51 571.518 391.41 571.812L354.71 573.281C348.61 573.575 343.81 578.571 344.11 584.547L345.61 620.5C345.91 626.476 351.01 631.178 357.11 630.884L393.81 629.415C399.91 629.121 404.71 624.125 404.41 618.149L402.91 582.196Z'
                        fill='#8697B7'
                      />
                      <path
                        id='Vector_373'
                        opacity='0.6863'
                        d='M402.809 582.294C402.509 576.318 397.409 571.616 391.309 571.91L354.61 573.379C348.51 573.673 343.709 578.669 344.009 584.645L345.509 620.598C345.809 626.574 350.909 631.276 357.009 630.982L393.709 629.513C399.809 629.219 404.609 624.222 404.309 618.247L402.809 582.294Z'
                        fill='#8394B5'
                      />
                      <path
                        id='Vector_374'
                        opacity='0.7059'
                        d='M402.71 582.391C402.41 576.416 397.31 571.713 391.21 572.007L354.51 573.477C348.41 573.771 343.61 578.767 343.91 584.743L345.41 620.695C345.71 626.671 350.81 631.374 356.91 631.08L393.61 629.61C399.71 629.316 404.51 624.32 404.21 618.344L402.71 582.391Z'
                        fill='#7F91B3'
                      />
                      <path
                        id='Vector_375'
                        opacity='0.7255'
                        d='M402.61 582.489C402.31 576.513 397.21 571.811 391.11 572.105L354.41 573.574C348.31 573.868 343.51 578.864 343.81 584.84L345.31 620.793C345.61 626.769 350.71 631.471 356.81 631.177L393.51 629.708C399.61 629.414 404.41 624.418 404.11 618.442L402.61 582.489Z'
                        fill='#7C8EB1'
                      />
                      <path
                        id='Vector_376'
                        opacity='0.7451'
                        d='M402.51 582.589C402.21 576.613 397.11 571.911 391.01 572.204L354.31 573.674C348.21 573.968 343.41 578.964 343.71 584.94L345.21 620.893C345.51 626.869 350.61 631.571 356.71 631.277L393.41 629.807C399.51 629.514 404.31 624.517 404.01 618.542L402.51 582.589Z'
                        fill='#788BAF'
                      />
                      <path
                        id='Vector_377'
                        opacity='0.7647'
                        d='M402.41 582.686C402.11 576.711 397.01 572.008 390.91 572.302L354.21 573.772C348.11 574.065 343.31 579.062 343.61 585.037L345.11 620.99C345.41 626.966 350.51 631.669 356.61 631.375L393.31 629.905C399.41 629.611 404.21 624.615 403.91 618.639L402.41 582.686Z'
                        fill='#7588AC'
                      />
                      <path
                        id='Vector_378'
                        opacity='0.7843'
                        d='M402.41 582.784C402.11 576.808 397.01 572.106 390.91 572.4L354.21 573.869C348.11 574.163 343.31 579.159 343.61 585.135L345.11 621.088C345.41 627.064 350.51 631.766 356.61 631.472L393.31 630.003C399.41 629.709 404.21 624.713 403.91 618.737L402.41 582.784Z'
                        fill='#7185AA'
                      />
                      <path
                        id='Vector_379'
                        opacity='0.8039'
                        d='M402.309 582.882C402.009 576.906 396.909 572.204 390.809 572.497L354.11 573.967C348.01 574.261 343.209 579.257 343.509 585.233L345.009 621.186C345.309 627.162 350.409 631.864 356.509 631.57L393.209 630.1C399.309 629.807 404.109 624.81 403.809 618.835L402.309 582.882Z'
                        fill='#6D82A8'
                      />
                      <path
                        id='Vector_380'
                        opacity='0.8235'
                        d='M402.21 582.979C401.91 577.003 396.81 572.301 390.71 572.595L354.01 574.065C347.91 574.358 343.11 579.355 343.41 585.33L344.91 621.283C345.21 627.259 350.31 631.961 356.41 631.668L393.11 630.198C399.21 629.904 404.01 624.908 403.71 618.932L402.21 582.979Z'
                        fill='#6A7FA6'
                      />
                      <path
                        id='Vector_381'
                        opacity='0.8431'
                        d='M402.11 583.077C401.81 577.101 396.71 572.399 390.61 572.693L353.91 574.162C347.81 574.456 343.01 579.452 343.31 585.428L344.81 621.381C345.11 627.357 350.21 632.059 356.31 631.765L393.01 630.296C399.11 630.002 403.91 625.006 403.61 619.03L402.11 583.077Z'
                        fill='#667BA4'
                      />
                      <path
                        id='Vector_382'
                        opacity='0.8627'
                        d='M402.01 583.177C401.71 577.201 396.61 572.498 390.51 572.792L353.81 574.262C347.71 574.556 342.91 579.552 343.21 585.528L344.71 621.481C345.01 627.456 350.11 632.159 356.21 631.865L392.91 630.395C399.01 630.102 403.81 625.105 403.51 619.13L402.01 583.177Z'
                        fill='#6378A2'
                      />
                      <path
                        id='Vector_383'
                        opacity='0.8824'
                        d='M401.91 583.274C401.61 577.298 396.51 572.596 390.41 572.89L353.71 574.359C347.61 574.653 342.81 579.65 343.11 585.625L344.61 621.578C344.91 627.554 350.01 632.256 356.11 631.962L392.81 630.493C398.91 630.199 403.71 625.203 403.41 619.227L401.91 583.274Z'
                        fill='#5F75A0'
                      />
                      <path
                        id='Vector_384'
                        opacity='0.902'
                        d='M401.809 583.372C401.509 577.396 396.409 572.694 390.309 572.988L353.61 574.457C347.51 574.751 342.709 579.747 343.009 585.723L344.509 621.676C344.809 627.652 349.909 632.354 356.009 632.06L392.709 630.591C398.809 630.297 403.609 625.301 403.309 619.325L401.809 583.372Z'
                        fill='#5C729E'
                      />
                      <path
                        id='Vector_385'
                        opacity='0.9216'
                        d='M401.71 583.372C401.41 577.396 396.31 572.694 390.21 572.988L353.51 574.457C347.41 574.751 342.61 579.747 342.91 585.723L344.41 621.676C344.71 627.652 349.81 632.354 355.91 632.06L392.61 630.591C398.71 630.297 403.51 625.301 403.21 619.325L401.71 583.372Z'
                        fill='#586F9B'
                      />
                      <path
                        id='Vector_386'
                        opacity='0.9412'
                        d='M401.61 583.471C401.31 577.496 396.21 572.793 390.11 573.087L353.41 574.557C347.31 574.851 342.51 579.847 342.81 585.823L344.31 621.775C344.61 627.751 349.71 632.454 355.81 632.16L392.51 630.69C398.61 630.396 403.41 625.4 403.11 619.424L401.61 583.471Z'
                        fill='#556C99'
                      />
                      <path
                        id='Vector_387'
                        opacity='0.9608'
                        d='M401.61 583.567C401.31 577.591 396.21 572.889 390.11 573.183L353.41 574.652C347.31 574.946 342.51 579.943 342.81 585.918L344.31 621.871C344.61 627.847 349.71 632.549 355.81 632.255L392.51 630.786C398.61 630.492 403.41 625.496 403.11 619.52L401.61 583.567Z'
                        fill='#516997'
                      />
                      <path
                        id='Vector_388'
                        opacity='0.9804'
                        d='M401.51 583.665C401.21 577.689 396.11 572.987 390.01 573.281L353.31 574.75C347.21 575.044 342.41 580.04 342.71 586.016L344.21 621.969C344.51 627.945 349.61 632.647 355.71 632.353L392.41 630.884C398.51 630.59 403.31 625.594 403.01 619.618L401.51 583.665Z'
                        fill='#4E6695'
                      />
                      <path
                        id='Vector_389'
                        d='M401.41 583.764C401.11 577.789 396.01 573.086 389.91 573.38L353.21 574.85C347.11 575.144 342.31 580.14 342.61 586.116L344.11 622.069C344.41 628.044 349.51 632.747 355.61 632.453L392.31 630.983C398.41 630.689 403.21 625.693 402.91 619.717L401.41 583.764Z'
                        fill='#4A6393'
                      />
                    </g>
                    <path
                      id='Vector_390'
                      d='M400.31 584.743C400.11 578.865 395.01 574.26 389.01 574.554L353.01 576.024C347.01 576.22 342.31 581.216 342.61 587.094L344.11 622.361C344.31 628.239 349.41 632.843 355.41 632.549L391.41 631.08C397.41 630.884 402.11 625.888 401.81 620.01L400.31 584.743Z'
                      fill='url(#paint6_linear_413_2)'
                    />
                    <path
                      id='Vector_391'
                      d='M391.611 630.494C397.311 630.2 401.811 625.399 401.511 619.815L399.71 584.548C399.41 578.964 394.511 574.556 388.811 574.85L352.811 576.613C347.111 576.907 342.611 581.707 342.911 587.291L344.71 622.558C345.01 628.142 349.911 632.551 355.611 632.257L391.611 630.494Z'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                    />
                    <text
                      id='Task 1'
                      transform='matrix(0.998849 -0.0483282 0.0503525 0.998749 349.461 585.596)'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                      xmlSpace='preserve'
                      style={{whiteSpace: 'pre'}}
                      font-family='Poppins'
                      font-size='13.3613'
                      font-weight='bold'
                      letter-spacing='0em'
                    >
                      <tspan x='0' y='14.6765'>
                        {task[0].name && task[0].name.length > 3
                          ? truncateText(task[0].name, 4)
                          : task[0].name}
                      </tspan>
                    </text>
                    <path
                      id='Vector_392'
                      d='M386.51 621.283L360.81 622.557C357.21 622.753 354.11 620.01 353.91 616.483L353.81 614.426C353.61 610.899 356.41 607.862 360.01 607.666L385.71 606.393C389.31 606.197 392.41 608.94 392.61 612.466L392.71 614.524C392.91 618.05 390.11 621.087 386.51 621.283Z'
                      fill={task[0].TasksAndStudents?.isCompleted ? '#fff' : '#000'}
                    />
                    <g id='Group_121'>
                      <text
                        id='3/7_7'
                        transform='matrix(0.998849 -0.0483282 0.0503525 0.998749 363.486 607.945)'
                        fill={task[0].TasksAndStudents?.isCompleted ? '#1D87CE' : '#fff'}
                        xmlSpace='preserve'
                        style={{whiteSpace: 'pre'}}
                        font-family='Poppins'
                        font-size='11.3287'
                        font-weight='bold'
                        letter-spacing='0em'
                      >
                        <tspan x='0' y='12.465'>
                          {(task[0]?.TasksAndStudents?.counter || 0) +
                            '/' +
                            (task[0]?.total_counter || 0)}
                        </tspan>
                      </text>
                    </g>
                  </g>
                </a>
              </g>
            )}
            <g id='center'>
              <path
                id='Vector_393'
                d='M694.109 495.498L624.209 527.043L597.51 597.773L629.709 666.348L702.01 692.504L771.91 660.96L798.609 590.132L766.41 521.655L694.109 495.498Z'
                fill='url(#paint7_radial_413_2)'
              />
              <path
                id='Vector_394'
                d='M694.51 504.805L631.209 533.41L607.01 597.479L636.209 659.49L701.609 683.1L764.91 654.592L789.109 590.523L759.91 528.512L694.51 504.805Z'
                fill='url(#paint8_radial_413_2)'
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_413_2'
          x='-1956.54'
          y='0'
          width='5203.54'
          height='2187.52'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_413_2' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_413_2' result='shape' />
        </filter>
        <filter
          id='filter1_d_413_2'
          x='1041.58'
          y='997.646'
          width='23.3506'
          height='23.418'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_413_2' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_413_2' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_413_2'
          x1='737.809'
          y1='912.351'
          x2='680.083'
          y2='915.262'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_413_2'
          x1='968.223'
          y1='811.265'
          x2='910.497'
          y2='814.176'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_413_2'
          x1='1024.41'
          y1='608.503'
          x2='1022.89'
          y2='551.9'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_413_2'
          x1='890.363'
          y1='363.017'
          x2='948.164'
          y2='363.017'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_413_2'
          x1='658.192'
          y1='275.806'
          x2='715.918'
          y2='272.916'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_413_2'
          x1='434.389'
          y1='371.292'
          x2='492.19'
          y2='371.292'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_413_2'
          x1='373.334'
          y1='632.17'
          x2='371.051'
          y2='575.587'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#27A9D4' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_413_2'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(698.136 593.972) rotate(-24.288) scale(92.6391 91.3981)'
        >
          <stop stop-color='#A0FFFF' />
          <stop offset='1' stop-color='#3070A8' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_413_2'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(698.136 593.972) rotate(-24.288) scale(83.8618 82.7384)'
        >
          <stop stop-color='#A0FFFF' />
          <stop offset='1' stop-color='#3070A8' />
        </radialGradient>
        <clipPath id='clip0_413_2'>
          <rect width='1324' height='1192' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RightTask
